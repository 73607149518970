import { DeprecatedIcon as Icon, ICON_SIZES, DEPRECATED_ICONS_NAMES as ICONS_NAMES, PALETTES } from "@velitech/ui";

import placeholder from '@res/images/placeholder.png';

import { PreviewButton } from "@components/ui/PreviewTemplate/components/PreviewButton";

import { ButtonContainer, Container, ImageContainer, Image, Message, TextContainer, Title } from './styled';

export const Notification = ({
  title,
  message,
  buttons,
  imageUrl,
}) => {
  const hasButtonsName = buttons?.some((button) => button.name);
  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={'large'} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });

  return (
    <Container>
      <Icon className={'close-icon'} name={ ICONS_NAMES.CloseOutline } color={PALETTES.NEUTRAL_500} size={ICON_SIZES.S16}/>
      <ImageContainer>
        <Image src={imageUrl || placeholder} alt='' />
      </ImageContainer>
      <TextContainer>
        <Title>
          {title}
        </Title>
        <Message>
          {message}
        </Message>
        {hasButtonsName && (
          <ButtonContainer>
            {renderButtons}
          </ButtonContainer>
        )}
      </TextContainer>
    </Container>
  );
};
