import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { DateRangePicker } from 'react-date-range'

import { Button, Theme } from '@velitech/ui';
import { Dropdown } from 'antd';
import { enGB } from "date-fns/locale";

import { useModalState, useTranslation } from '@hooks';

import { dashboardmomentlessRanges, momentlessRanges, lastThreeMonthRanges } from '@constants';

import { moment } from '@utils';

import { Icon } from '@components';

import {
  Container,
  ActionButton,
  StyledRangePicker,
  PickerContainer,
  Overlay,
  ActionsContainer, StyledIcon, StyledDatePicker,
} from './styled.js';

import { adjustDateForTimeZone } from "@/pages/HomeScene/pages/SettingsScene/pages/OTPAnalyticPage/utils";

function isDateMidnight(date) {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
}

const RangePicker = React.forwardRef(({
  value,
  onChange,
  containerStyle,
  smaller,
  medium,
  dashboard,
  type,
  theme,
  testId,
  timeZone,
  ...props
}, ref) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const pickerModal = useModalState();

  useImperativeHandle(ref, () => ({
    open: pickerModal.open,
  }), [pickerModal.open])

  const handleMoveRangeLeft = () => {
    if (props.picker) {
      return onChange(value.map(d => moment(d).add(-1, props.picker)));
    }

    const [fromDate, toDate] = value;
    const diff = Math.abs(fromDate.diff(toDate, 'days'));

    onChange(value.map(d => moment(d).add(-diff || -1, 'days')));
  };

  const handleMoveRangeRight = () => {
    if (props.picker) {
      return onChange(value.map(d => moment(d).add(1, props.picker)));
    }

    const [fromDate, toDate] = value;
    const diff = Math.abs(fromDate.diff(toDate, 'days'));

    onChange(value.map(d => moment(d).add(diff || 1, 'days')));
  };

  const handleChange = (date) => {
    if(date === null) {
      return;
    }
    if (!Array.isArray(date)) {
      onChange([moment(date).startOf(props.picker), moment(date).endOf(props.picker)]);
    } else {
      onChange(date);
    }
  };

  const [selectionRange, setSelectionRange] = useState({
    startDate: adjustDateForTimeZone(moment(), true, timeZone),
    endDate: adjustDateForTimeZone(moment(), false, timeZone),
    key: 'selection',
  });

  useEffect(() => {
    if (!value) {
      return;
    }
    const adjustedStartDate = adjustDateForTimeZone(value[0], true, timeZone);
    const adjustedEndDate = adjustDateForTimeZone(value[1], false, timeZone);

    setSelectionRange({
      startDate: adjustedStartDate.toDate(),
      endDate: adjustedEndDate.toDate(),
      key: 'selection',
    })

  }, [value]);

  const handleSelect = (ranges) => {
    const isMidnight = isDateMidnight(ranges.selection.endDate);

    if (!isMidnight) {
      const adjustedStartDate = adjustDateForTimeZone(ranges.selection.startDate, true, timeZone).toDate();
      const adjustedEndDate = moment(ranges.selection.endDate).toDate();

      setSelectionRange({
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: 'selection',
      })

    } else {
      const adjustedStartDate = adjustDateForTimeZone(ranges.selection.startDate, true, timeZone).toDate();
      const adjustedEndDate = adjustDateForTimeZone(ranges.selection.endDate, false, timeZone).add(1, 'day').subtract(1, 'minutes').toDate();

      setSelectionRange({
        ...ranges.selection,
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
      });
    }
  }

  const handleSave = () => {
    onChange([moment(selectionRange.startDate), moment(selectionRange.endDate)]);
    pickerModal.close();
  }

  const handleSelectPicker = (date) => {
    if (date.selection) {
      onChange([moment(date.selection.endDate).startOf(props.picker), moment(date.selection.endDate).endOf(props.picker)]);
      pickerModal.close();
      return;
    }
    onChange([moment(date).startOf(props.picker), moment(date).endOf(props.picker)]);
    pickerModal.close();
  }

  const absoluteRangeState = useMemo(() => {
    return [selectionRange];
  }, [selectionRange])

  const staticRangeConfig = {
    'otp-analytic': lastThreeMonthRanges(t),
  };

  const staticRange = dashboard ? dashboardmomentlessRanges(t) : momentlessRanges(t);
  const staticType = staticRangeConfig[type];

  return (
    <Container data-testid={testId} className="custom-range-picker" style={containerStyle}>
      <ActionButton data-testid={`${testId}-back`} theme={theme} medium={medium} smaller={smaller} onClick={handleMoveRangeLeft}>
        <Icon style={{ lineHeight: '10px' }} name="Back-arrow1" size={10} />
      </ActionButton>
      <PickerContainer theme={theme} className={`range-picker-${props.picker}`} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {props.picker ? (
          <>
            <Dropdown
              placement="bottomCenter"
              trigger={['click']}
              onOpenChange={pickerModal.toggle}
              open={pickerModal.opened}
              overlay={props.picker === 'week' || props.picker === 'day' ? (
                <Overlay>
                  {props.picker === 'week' && (
                    <DateRangePicker
                      months={1}
                      locale={enGB}
                      ranges={[{ startDate: value?.[0].toDate(), endDate: value?.[1].toDate(), key: 'selection' }]}
                      onChange={handleSelectPicker}
                    />
                  )}
                </Overlay>
              ) : <></>}
            >
              <StyledDatePicker
                value={value?.[0]}
                style={{ backgroundColor: '#E5E8ED !important' }}
                onChange={handleChange}
                bordered={false}
                className="srp"
                onClick={e => e.stopPropagation()}
                data-testid={`range-picker`}
                smaller={smaller}
                locale={enGB}
                medium={medium}
                suffixIcon={(
                  <StyledIcon className="clr" name="Calendar1" size={12} color="#909399" />
                )}
                {...props}
              />
            </Dropdown>
          </>
        ) : (
          <Dropdown
            placement="bottomCenter"
            trigger={['click']}
            onOpenChange={pickerModal.toggle}
            open={pickerModal.opened}
            overlay={(
              <Theme>
                <Overlay>
                  <DateRangePicker
                    locale={enGB}
                    ranges={absoluteRangeState}
                    months={2}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    onChange={handleSelect}
                    staticRanges={staticType || staticRange}
                  />
                  <ActionsContainer>
                    <div style={{ display: 'flex' }}>
                      <Button onClick={pickerModal.close} theme={theme} width={100} variant="secondary">{t('actions.cancel')}</Button>
                      <Button onClick={handleSave} style={{ marginLeft: 10 }} width={100}>{t('actions.apply')}</Button>
                    </div>
                  </ActionsContainer>
                </Overlay>
              </Theme>
            )}
          >
            <StyledRangePicker
              value={props.picker ? value?.[0] : value}
              style={{ backgroundColor: '#E5E8ED !important' }}
              smaller={smaller}
              medium={medium}
              theme={theme}
              locale={enGB}
              disabled
              data-testid={`range-picker`}
              onChange={r => handleChange(r)}
              suffixIcon={(
                <Icon name="Calendar1" size={12} color={hover ? 'transparent' : '#909399'} />
              )}
              bordered={false}
              {...props}
            />
          </Dropdown>
        )}
      </PickerContainer>
      <ActionButton data-testid={`${testId}-forward`} theme={theme} medium={medium} smaller={smaller} inverted onClick={handleMoveRangeRight}>
        <Icon style={{ lineHeight: '10px' }} name="Back-arrow1" size={10} />
      </ActionButton>
    </Container>
  );
});

export default RangePicker;

