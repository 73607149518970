import styled from 'styled-components';

import { switchStyle } from '@utils';

export const Container = styled.div`

`;

export const Button = styled.button`
  font-family: Manrope-SemiBold;
  font-style: normal;
  font-weight: 600;
  white-space: pre-line;
  word-wrap: break-word;
  cursor: pointer;

  ${switchStyle('$type',{
    2: `
        border: 1px solid var(--Colors-Additional-System-400, #6d85ff);
        background: var(--Colors-Additional-White, #fff);
        color: var(--Colors-Additional-System-400, #6d85ff);
    `,
    1: `
        background: var(--Colors-Additional-System-400, #6D85FF);
        color: #fff;
        border: 1px solid transparent;
    `,
    3: `
        background: var(--Colors-Additional-Negative-500p, #F04438);
        color: #fff;
        border: 1px solid transparent;
    `
  })}

${switchStyle('$size',{
    'small': `
        font-size: 5px;  
        line-height: 6px;
        padding: 2px 6px;
        min-width: 42px;
        border-radius: 2px;
    `,
    'light': `
        font-size: 8px;  
        padding: 2px 10px;
        border-radius: 3px;
    `,
    
    'medium': `
        font-size: 10px;  
        min-width: 89px;
        line-height: 12px;
        padding: 5px 14px;
        border-radius: 4px;
    `,
    'large': `
        font-size: 16px;  
        min-width: 135px;
        line-height: 18px;
        padding: 8px 20px;
        border-radius: 6px;
    `,
    'fs-small': `
        font-size: 5px;  
        line-height: 6px;
        padding: 3px 6px;
        border-radius: 2px;
        max-width: 100px;
        width: 100%;
    `,
    'fs-medium': `
        font-size: 11px;  
        line-height: 13px;
        padding: 8px 15px;
        max-width: 248px;
        width: 100%;
        border-radius: 5px;
    `,
  })}

`;
