import { Position } from "@xyflow/react";
import { Moment } from "moment";

import { FilterBy } from './nodeTypes/filterEnums';
import { FilterDto, FilterSubFilter } from './nodeTypes/filterTypes';

export enum FilterLogicalOperator {
    OR = 'or',
    AND = 'and'
}

export enum QueryNodeType {
    Rule = "query-builder-rule",
    Group = "query-builder-group",
  }
import {
  EmailStatuses,
  MobilePushStatuses,
  SmsStatuses,
  ViberStatuses, WebhookStatuses,
  WebPopupStatuses,
  WebpushStatuses
} from "@/constants";
import { ContentCardStatuses } from "@/constants/contentCardsStatuses";

export enum NodeType {
    DEFAULT = 'default_node',
    SPLITTER = 'splitter_node',
    FINISH = 'finish_node',
}

export enum EdgeTypes {
    DEFAULT = 'default_edge',
}

export enum NodeActionType {
    ENTRY_POINT = 'entry_point',
    CONNECTION_CHANNEL = 'connection_channel',
    ACTIONS = 'actions',
    CONTROL_GROUP = 'control_group',
}

export enum GroupSplitType {
    INCLUDE = 'include',
    EXCLUDE = 'exclude'
}

export enum NodeName {
    RESOURCE = 'resource',
    ENTRY_ANOTHER_WF = 'worker',
    MOBILE_PUSH = 'mobpush',
    API_REQUEST = 'apirequest',
    QUICK_FILTER = 'quick',
    API_FILTER = 'apifilter',
    EXCLUDE_FILTER = 'filterexclude',
    CONTACT_CREATED = 'contactcreated',
    GROUP_SPLIT = 'filter',
    WAIT = 'wait',
    CUSTOMER_CHANGE = 'changeAttribute',
    SEND_EMAIL = 'email',
    SEND_SMS = 'sms',
    MESSAGE_FEED = 'card',
    EVENT = 'event',
    EMAIL = 'emailtriggered',
    PAGE_VIEW = 'page',
    END_PATH = 'end',
    INCLUDE = 'include',
    EXCLUDE = 'exclude',
    AB_SPLITTER = 'ab',
    // RETARGETING = 'facebook',
    AB_TEST = 'splitter',
    WEBPUSH = 'webpush',
    VIBER = 'viber',
    WEB_POPUP = 'webpopup',
    GIVE_REWARD = 'reward',
    INCLUDE_CAMPAIGN = 'include-campaign',
    BEST_CHANNEL_TO_SEND = 'bestchannel',
    CONTROL_GROUP = 'control_group',
    TEAM_MESSAGE = 'notification',
}

export const EntryNodes = [
  NodeName.EVENT,
  NodeName.RESOURCE,
  NodeName.CONTACT_CREATED,
  NodeName.ENTRY_ANOTHER_WF
];

export type FilterBuilder = {
    logicalOperator: FilterLogicalOperator,
    filters: FilterSubFilter[]
}
export type NodeEvent = {
    event_id: string;
    event_name: string;
    simplified?: boolean;
    hidden?: boolean;
    filter_builder?: null | FilterBuilder;
};

export type NodeWorker = {
    trigger_workflow_id: string;
    trigger_workflow_node_ids: string[];
}

export type ABSegment = {
  id: string;
  value: number;
  label: string;
  isControlGroup?: boolean;
};

export type GroupSplitSegment = {
    filter_by?: 'segment' | 'rule';
    actionType: GroupSplitType;
    aggregates: never[];
    funnels: never[];
    label: string;
    id: string;
    query: QueryNodeData;
    type: GroupSplitType;
} & QueryNodeWithSegment;

export type BaseNodeData<N extends NodeName | undefined, A extends NodeActionType | undefined> = {
    name?: N;
    actionType?: A;
    label?: string;
    description?: string;
    order?: number;
    state: number;
};

export type EventNodeData = {
  events: NodeEvent[];
};

export type ResourceNodeData = {
    segment_ids: string[];
    segments_metadata: {
        [key: string]: {
            parentId: string;
            type: 'default' | 'rfm';
        };
    }
};

export type AiBestTimeToSendData = {
    best_time_fallback: null | Moment;
    best_time_enabled: boolean;
}

export type EntryFromWorkflowNodeData = {
  workers: NodeWorker[];
};

export type CustomerAdditionNodeData = {
    todo: never;
};

export type MobilePushNodeData = AiBestTimeToSendData & {
    mobile_push_id: number;
};

export type ApiRequestNodeData = AiBestTimeToSendData & {
    api_request_id: number;
};

export type SendEmailNodeData = AiBestTimeToSendData & {
    email_id: number;
};

export type SendSmsNodeData = AiBestTimeToSendData & {
    sms_id: number;
};

export type ViberNodeData = AiBestTimeToSendData & {
    viber_id: number;
};

export type WebPopupNodeData = AiBestTimeToSendData & {
    web_popup_id: string;
};

export enum TemplateExpirationType {
    DURATION = 'duration',
    DATE = 'date'
}

export type TemplateExpiration = {
    type: TemplateExpirationType;
    duration?: number;
    date?: Moment;
}

export type MessageFeedNodeData = AiBestTimeToSendData & {
    content_card_id: string;
    expiration: TemplateExpiration;
};

export type ControlGroupNodeData = {
    value: number;
};

export type WebPushNodeData = AiBestTimeToSendData & {
    webpush_id: number;
};

export type GroupSplitNodeData = {
    segments: GroupSplitSegment[];
};

export enum DaysOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export enum ExpirationAaction {
    proceed_workflow = 1,
    exit_workflow = 0,
}

export type WaitNodeData = {
    type: 0 | 1 | 2;
    days: DaysOfWeek;
    day: number;
    hours: number;
    minutes: number;
    duration: number;
    event_name: string | null;
    send_at: string | null;
    expiration_action: ExpirationAaction;
    event_id: string | null;
    filter_builder: FilterBuilder | null
};

export type QueryRule = {
    type: QueryNodeType.Rule;
    query: FilterDto;
};

export type QueryType = {
    logicalOperator: FilterLogicalOperator;
    children: Array<QueryNode>;
};

export type QueryGroup = {
    type: QueryNodeType.Group;
    query: QueryType;
};

export type QueryNode = QueryGroup | QueryRule;
export type QueryNodeWithSegment = {
    segmentType?: 'rfm';
    segment_id?: string;
    rule_segment_id?: string;
    parentId?: number;
}
export type QueryNodeData = {
    logicalOperator: FilterLogicalOperator,
    name: string;
    root: true;
    children: QueryNode[];
}
export type QuickFilterNodeData = {
    label: string;

    query: QueryNodeData
    filter_by: FilterBy,
} & QueryNodeWithSegment;

export type ResourseTriggerNodeData = {
    segment_ids: string[];
    segments_metadata: Record<string, any>
}

export type ExcludeFilterNodeData = {
    label: string;

    query: QueryNodeData
    filter_by: FilterBy,
} & QueryNodeWithSegment;

export type ABTestingNodeData = {
    segments: ABSegment[];
};

export type EndPathNodeData = {
    todo?: string;
} & {
    label?: string;
};

export type BestChannelChannelType = NodeName.SEND_EMAIL | NodeName.SEND_SMS | NodeName.WEBPUSH |
  NodeName.WEB_POPUP | NodeName.VIBER | NodeName.MOBILE_PUSH;

type BaseChannel = {
    isDefault?: boolean;
};

type BestChannelEmail = {
    type: NodeName.SEND_EMAIL;
    email_id: number;
}
type BestChannelSMS = {
    type: NodeName.SEND_SMS;
    sms_id: number;
}
type BestChannelViber = {
    type: NodeName.VIBER;
    viber_id: number;
}

type BestChannelWebPush = {
    type: NodeName.WEBPUSH;
    webpush_id: number;
}

type BestChannelMobilePush = {
    type: NodeName.MOBILE_PUSH;
    mobile_push_id: number;
}

type BestChannelCard = {
    type: NodeName.MESSAGE_FEED;
    content_card_id: number;
}

export type BestChannelChannel = {
    default: boolean;
    id: string;
    type: NodeName.EMAIL | NodeName.SEND_SMS | NodeName.VIBER | NodeName.WEBPUSH | NodeName.MOBILE_PUSH;
    data: BestChannelEmail | BestChannelSMS | BestChannelViber | BestChannelWebPush | BestChannelMobilePush | BestChannelCard;
}

export type BestChannelNodeData = AiBestTimeToSendData & {
    channels: Array<BestChannelChannel>;
};

export type IncludeNodeData = GroupSplitSegment;

export type ExcludeNodeData = Pick<GroupSplitSegment, "type" | "actionType" | "label">;

export type ABSplitterNodeData = ABSegment;

export type FinishNodeData = {
  todo: never;
};

export enum IncludeCampaignAction {
    INCLUDE = 'include',
    EXCLUDE = 'exclude',
}

export type IncludeCampaignNodeData = {
    campaign_id: string;
    action: IncludeCampaignAction;
};

export type TeamMessageNodeData = {
  email_id: string;
  message_type: NodeName.SEND_EMAIL;
  recipients: string[];
};

export enum RewardType {
    BONUS = 'bonus',
    FREE_SPIN = 'free-spin',
    FREE_BET = 'free-bet',
    CASHBACK = 'cashback',
    MONEY_REWARD = 'money',
    LOYALTY_POINTS = 'loyalty-point',
    TAG = 'tag-reward',
}

export type GiveRewardRewardMoneyMapping = Array<{ currency: string; amount: string; }>;

export enum GiveRewardTagsAction {
    TAG = 'TAG',
    UNTAG = 'UNTAG',
}

export type GiveRewardNodeData = {
    reward_type: RewardType;
    reward_claimable?: boolean;
    reward_id?: string;
    reward_data?: {
        [key: string]: string | number | string[];
    };
    reward_amount?: string;
    reward_action?: string;
    reward_money_mapping?: GiveRewardRewardMoneyMapping;
    reward_tags?: string[];
    tags_rank?: number;
    tags_action?: GiveRewardTagsAction;
}

export type FlowNodeData = (
  EventNodeData | ResourceNodeData | EntryFromWorkflowNodeData | CustomerAdditionNodeData |
  MobilePushNodeData | SendEmailNodeData | SendSmsNodeData | ViberNodeData | WebPopupNodeData | MessageFeedNodeData |
  ControlGroupNodeData | WebPushNodeData | ABSplitterNodeData | FinishNodeData | ExcludeNodeData | IncludeNodeData | WaitNodeData |
  EndPathNodeData | ABTestingNodeData | ExcludeFilterNodeData | QuickFilterNodeData | GroupSplitNodeData | BestChannelNodeData | TeamMessageNodeData | IncludeCampaignNodeData
  ) & {
    name?: NodeName;
    actionType?: NodeActionType;
    label?: string;
    description?: string;
    order?: number;
    state: number;
};

export type GenericFlowNode<T extends NodeType, N extends NodeName | undefined, D, A extends NodeActionType | undefined> = {
    type: T;
    id: string;
    position: { x: number; y: number };
    sourcePosition: Position;
    targetPosition: Position;
    data: D & BaseNodeData<N, A>;
};

export type NodeTypeDataMap = {
    [NodeType.DEFAULT]: FlowNodeData;
    [NodeType.SPLITTER]: ABSplitterNodeData | IncludeNodeData | ExcludeNodeData;
    [NodeType.FINISH]: FinishNodeData;
};

export type EmptyTriggerNode = GenericFlowNode<NodeType.DEFAULT, undefined, null, NodeActionType.ENTRY_POINT>;

export type EventNode = GenericFlowNode<NodeType.DEFAULT, NodeName.EVENT, EventNodeData, NodeActionType.ENTRY_POINT>;
export type ResourceNode = GenericFlowNode<NodeType.DEFAULT, NodeName.RESOURCE, ResourceNodeData, NodeActionType.ENTRY_POINT>;
export type EntryFromWorkflowNode = GenericFlowNode<NodeType.DEFAULT, NodeName.ENTRY_ANOTHER_WF, EntryFromWorkflowNodeData, NodeActionType.ENTRY_POINT>;
export type CustomerAdditionNode = GenericFlowNode<NodeType.DEFAULT, NodeName.CONTACT_CREATED, CustomerAdditionNodeData, NodeActionType.ENTRY_POINT>;

export type ControlGroupNode = GenericFlowNode<NodeType.DEFAULT, NodeName.CONTROL_GROUP, ControlGroupNodeData, NodeActionType.CONTROL_GROUP>;
export type MobilePushNode = GenericFlowNode<NodeType.DEFAULT, NodeName.MOBILE_PUSH, MobilePushNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type ApiRequestNode = GenericFlowNode<NodeType.DEFAULT, NodeName.API_REQUEST, ApiRequestNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type SendEmailNode = GenericFlowNode<NodeType.DEFAULT, NodeName.SEND_EMAIL, SendEmailNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type SendSmsNode = GenericFlowNode<NodeType.DEFAULT, NodeName.SEND_SMS, SendSmsNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type ViberNode = GenericFlowNode<NodeType.DEFAULT, NodeName.VIBER, ViberNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type WebPopupNode = GenericFlowNode<NodeType.DEFAULT, NodeName.WEB_POPUP, WebPopupNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type MessageFeedNode = GenericFlowNode<NodeType.DEFAULT, NodeName.MESSAGE_FEED, MessageFeedNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type WebPushNode = GenericFlowNode<NodeType.DEFAULT, NodeName.WEBPUSH, WebPushNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type BestChannelNode = GenericFlowNode<NodeType.DEFAULT, NodeName.BEST_CHANNEL_TO_SEND, BestChannelNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type TeamMessageNode = GenericFlowNode<NodeType.DEFAULT, NodeName.TEAM_MESSAGE, TeamMessageNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type IncludeCampaignNode = GenericFlowNode<NodeType.DEFAULT, NodeName.INCLUDE_CAMPAIGN, IncludeCampaignNodeData, NodeActionType.CONNECTION_CHANNEL>;
export type GiveRewardNode = GenericFlowNode<NodeType.DEFAULT, NodeName.GIVE_REWARD, GiveRewardNodeData, NodeActionType.CONNECTION_CHANNEL>;

export type WaitNode = GenericFlowNode<NodeType.DEFAULT, NodeName.WAIT, WaitNodeData, NodeActionType.ACTIONS>;
export type GroupSplitNode = GenericFlowNode<NodeType.DEFAULT, NodeName.GROUP_SPLIT, GroupSplitNodeData, NodeActionType.ACTIONS>;
export type QuickFilterNode = GenericFlowNode<NodeType.DEFAULT, NodeName.QUICK_FILTER, QuickFilterNodeData, NodeActionType.ACTIONS>;
export type ExcludeFilterNode = GenericFlowNode<NodeType.DEFAULT, NodeName.EXCLUDE_FILTER, ExcludeFilterNodeData, NodeActionType.ACTIONS>;
export type ABTestingNode = GenericFlowNode<NodeType.DEFAULT, NodeName.AB_TEST, ABTestingNodeData, NodeActionType.ACTIONS>;

export type ABSplitterNode = GenericFlowNode<NodeType.SPLITTER, NodeName.AB_SPLITTER, ABSplitterNodeData, undefined>;
export type ExcludeNode = GenericFlowNode<NodeType.SPLITTER, NodeName.EXCLUDE, ExcludeNodeData, undefined>;
export type IncludeNode = GenericFlowNode<NodeType.SPLITTER, NodeName.INCLUDE, IncludeNodeData, undefined>;
export type FinishNode = GenericFlowNode<NodeType.FINISH, NodeName.END_PATH, FinishNodeData, undefined>;

export type FlowNode =
  EmptyTriggerNode | EventNode | ResourceNode | EntryFromWorkflowNode |
  CustomerAdditionNode | ControlGroupNode | MobilePushNode |
  ApiRequestNode | SendEmailNode | SendSmsNode |
  ViberNode | WebPushNode | MessageFeedNode | WebPopupNode | ABSplitterNode | FinishNode |
  BestChannelNode | WaitNode | GroupSplitNode | QuickFilterNode | ExcludeFilterNode | ABTestingNode |
  ExcludeNode | IncludeNode | TeamMessageNode | IncludeCampaignNode | GiveRewardNode;

export type SendingNode = SendEmailNode | SendSmsNode | ViberNode | WebPushNode | MobilePushNode | WebPopupNode | ApiRequestNode | MessageFeedNode;

export type SendingNodeData = SendEmailNodeData | SendSmsNodeData | ViberNodeData | WebPushNodeData | MobilePushNodeData | WebPopupNodeData | ApiRequestNodeData | MessageFeedNodeData;

export type FlowEdge = {
    id: string;
    source: string;
    target: string;
};

export type Flow = {
    nodes: FlowNode[];
    edges: FlowEdge[];
};

export type NodeInOutAnalytics = {
    exit: number;
    in: number;
    out: number;
    unsubscribed: number;
    insufficient: number;
    participation: number;
    blocked: number;
    node: string;
}

export type NodeDelayedAnalytics = {
    wait_count: number;
    bts_count: number;
}

export const NodeTemplateStatusesMap = {
  [NodeName.SEND_SMS]: SmsStatuses,
  [NodeName.SEND_EMAIL]: EmailStatuses,
  [NodeName.MOBILE_PUSH]: MobilePushStatuses,
  [NodeName.WEBPUSH]: WebpushStatuses,
  [NodeName.MESSAGE_FEED]: ContentCardStatuses,
  [NodeName.WEB_POPUP]: WebPopupStatuses,
  [NodeName.VIBER]: ViberStatuses,
  [NodeName.API_REQUEST]: WebhookStatuses,
  [NodeName.TEAM_MESSAGE]: EmailStatuses,
};

export enum NodeAnalyticDeliveryChartType {
    PIE,
    TREND,
}

export const SendingNodeMap = [NodeName.EMAIL, NodeName.SEND_SMS, NodeName.MOBILE_PUSH, NodeName.VIBER, NodeName.API_REQUEST];

export const RequiredKeys: Partial<Record<NodeName, string>> = {
  [NodeName.MOBILE_PUSH]: "mobile_push_id",
  [NodeName.API_REQUEST]: "api_request_id",
  [NodeName.SEND_EMAIL]: "email_id",
  [NodeName.SEND_SMS]: "sms_id",
  [NodeName.VIBER]: "viber_id",
  [NodeName.WEB_POPUP]: "web_popup_id",
  [NodeName.WEBPUSH]: "webpush_id",
  [NodeName.MESSAGE_FEED]: "content_card_id"
};
