import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  optionsAggregatesSelector,
  optionsSelector
} from '@store/selectors';
import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { Dropdown, Tooltip } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { useTabElement, useTranslation } from '@hooks';

import { SegmentsRuleTypes, UserPermissions } from '@constants';
import { SEGMENT_FILTER_TYPE_MAP, GET_SEGMENT_FILTER_LABEL_MAP } from "@constants/segments";

import { extract } from '@utils';
import { clickhouseFields2Customer, clickhouseFields2Events } from '@utils/fields';

import { Icon, Input, Modal, NoData, WithPermissions } from '@components';
import {
  CustomFilterPanel
} from '@components/lib/SegmentEditor/components/FiltersDropdown/components/CustomFilterPanel';
import {
  RFMSegmentsFilterPanel
} from '@components/lib/SegmentEditor/components/FiltersDropdown/components/RFMSegmentsFilterPanel';
import {
  UnsubscribeGroupFilterPanel
} from '@components/lib/SegmentEditor/components/FiltersDropdown/components/UnsubscribeGroupFilterPanel';

import { AggregatesFilterPanel } from './components/AggregatesFilterPanel';
import { ArrayFilterPanel } from './components/ArrayFilterPanel';
import { EventsFilterPanel } from './components/EventsFilterPanel';
import { LinearFilterPanel } from './components/LinearFilterPanel';
import {
  Container,
  Overlay,
  Header,
  Tab,
  TabIndicator,
  Tabs,
  Content,
  DescriptionContainer,
  Description,
  CreateAggregateButton,
  SearchContainer,
  NoDataContainer,
  FilteredContainer,
  Group,
  GroupTitle,
  GroupItem,
} from './styled.js';

const FiltersDropdown = ({
  onSelect,
  hidePreview,
  children,
  defaultTab = 'linear',
  hiddenTypes = [],
  customTabs = [],
  isModal = false,
  disabled,
  aggregateLimitReached,
  maxWidth,
  disableAggregateCreation = false,
  style = {},
  nested = false,
}) => {
  const { t, p } = useTranslation('segments_page');
  const clickhouseFields = useSelector(clickhouseFieldsSelector);
  const customerFields = clickhouseFields2Customer(clickhouseFields.data);
  const events = clickhouseFields2Events(clickhouseFields.data);
  const aggregatesList = useSelector(optionsAggregatesSelector);
  const [opened, setOpened] = useState(false);
  const [tab, setTab] = useState(defaultTab);
  const [searchInputValue, setSearchInputValue] = useState('');
  const options = useSelector(optionsSelector);
  const te = useTabElement(tab, SEGMENT_FILTER_TYPE_MAP.concat(customTabs.map(extract('name'))).filter(t => !~hiddenTypes.indexOf(t)).map(name => ({ name })), opened)

  const rfmAnalyticsOptions = (options?.fields?.rfmResources || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const aggregates = hiddenTypes.indexOf('aggregate') === -1 ? aggregatesList : [];

  const linearFields = hiddenTypes.indexOf('linear') === -1 ? customerFields?.filter(f => f.type !== 'array') : [];
  const arrayFields = customerFields?.filter(f => f.type === 'array');

  const SEGMENT_FILTER_LABEL_MAP = GET_SEGMENT_FILTER_LABEL_MAP(p);

  const handleSelect = (...args) => {
    onSelect(...args);
    setSearchInputValue('');
    setOpened(false);
  };

  useEffect(() => {
    if (tab === 'aggregate' && aggregateLimitReached) {
      setTab('linear');
    }
  }, [aggregateLimitReached, tab]);

  const id = useMemo(() => uuidv4(), []);

  const hideRight = hidePreview || tab === 'rfm-segment';

  const renderContent = () => {
    if (!searchInputValue.trim().length) {
      return (
        <Content hidePreview={hideRight} $isDefault={!isModal} $mw={maxWidth || 420}>
          {tab === 'linear' && <LinearFilterPanel hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'array' && <ArrayFilterPanel hideArrays={~hiddenTypes.indexOf('nested-arrays')} nested={nested} hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'event' && <EventsFilterPanel nested={nested} hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'aggregate' && <AggregatesFilterPanel hidePreview={hidePreview} onSelect={handleSelect} />}
          {tab === 'rfm-segment' && <RFMSegmentsFilterPanel hidePreview={hideRight} onSelect={handleSelect} />}
          {tab === 'unsubscribe-group' && <UnsubscribeGroupFilterPanel hidePreview={hidePreview} onSelect={handleSelect} />}
          {customTabs.filter(t => !!t.options?.length).map(t => t.name === tab && <CustomFilterPanel key={t?.name} hideArrays={~hiddenTypes.indexOf('nested-arrays')} hidePreview={hidePreview} onSelect={handleSelect} options={t.options} type={t.name} />)}
          {/*{tab === 'expression' && null}*/}

          {!hideRight && (
            <DescriptionContainer>
              <Description>
                {tab === 'linear' && p('linear_description')}
                {tab === 'array' && p('array_description')}
                {tab === 'event' && p('event_description')}
                {tab === 'aggregate' && p('aggregate_description')}
                {tab === 'unsubscribe-group' && p('unsubscribe-group_description')}
                {customTabs.map(t => (t.name === tab && t.description) && p(t.description))}
              </Description>
              {(tab === 'aggregate' && !disableAggregateCreation) && (
                <WithPermissions name={UserPermissions.CREATE_AGGREGATES}>
                  <CreateAggregateButton onClick={() => handleSelect(null, SegmentsRuleTypes.AGGREGATE)}>{p('create_aggregate')}</CreateAggregateButton>
                </WithPermissions>
              )}
            </DescriptionContainer>
          )}
        </Content>
      )
    } else if (searchInputValue.trim().length) {
      return renderSearchFilters();
    }
  }

  const getFilteredArray = (array) => {
    return array?.filter(f => f.label.toLowerCase().indexOf(searchInputValue.toLowerCase()) !== -1);
  }

  const renderSearchFilters = () => {
    const filterGroups = [
      { name: p('linear'), filter: 'linear', options: getFilteredArray(linearFields), ruleType: SegmentsRuleTypes.LINEAR },
      { name: p('arrays'), filter: 'array', options: getFilteredArray(arrayFields)?.map?.(a => ({ ...a, value: a.field })) || [], ruleType: SegmentsRuleTypes.ARRAY },
      { name: p('events'), filter: 'event', options: getFilteredArray(events.data)?.map?.(e => ({ ...e, value: e.name })) || [], ruleType: SegmentsRuleTypes.EVENT },
      { name: p('aggregates'), filter: 'aggregate', options: getFilteredArray(aggregates), ruleType: SegmentsRuleTypes.AGGREGATE },
      { name: p('rfm_segments'), filter: 'rfm_segment', options: getFilteredArray(rfmAnalyticsOptions), ruleType: SegmentsRuleTypes.RFM_SEGMENT },
      ...(customTabs || []).map(({ name, options, ruleType }) => ({ name: SEGMENT_FILTER_LABEL_MAP[name], options: getFilteredArray(options), ruleType })),
    ].filter(({ filter }) => !~hiddenTypes.indexOf(filter));

    const empty = filterGroups.filter(({ options }) => !!options.length > 0).length === 0;

    return (
      <FilteredContainer empty={empty} $isDefault={!isModal} $mw={maxWidth || 420}>
        {filterGroups.map(({ name, options, ruleType }) => options.length ? (
          <Group key={name}>
            <GroupTitle>{name}</GroupTitle>
            {options.map(({ label, ...rest }) => (
              <GroupItem key={label} onClick={() => handleSelect({ ...rest, label }, ruleType)}>{label}</GroupItem>
            ))}
          </Group>
        ) : null)}
        {empty ? <NoDataContainer><NoData /></NoDataContainer> : null}
      </FilteredContainer>
    )
  }

  const tabs = SEGMENT_FILTER_TYPE_MAP.concat(customTabs.map(extract('name'))).filter(t => !~hiddenTypes.indexOf(t));

  if (isModal) {
    return (
      <>
        <Modal
          data-testid={'store-segment-filter-dropdown'}
          open={opened}
          style={{ maxWidth: 576, minWidth: '576px' }}
          contentStyles={{ padding: 0, borderRadius: 13 }}
          onClose={() => setOpened(false)}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Overlay hidePreview={hidePreview}>
              <Header>
                <SearchContainer>
                  <Input
                    style={{ width: '100%', height: '30px', borderRadius: '5px' }}
                    placeholder={t('labels.search')}
                    withSuffix
                    withPrefix
                    prefix={<Icon size={16} name="Operators-search" color="#909399" />}
                    suffix={!!searchInputValue.length ? <div style={{ cursor: 'pointer' }} onClick={() => setSearchInputValue('')}><Icon size={16} name="Clear" color="#909399" /></div> : null}
                    type="text"
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.target.value)}
                  />
                </SearchContainer>
                {!searchInputValue.trim().length ? (
                  <Tabs $js={tabs.length === 1} disabled={!!searchInputValue.trim().length} data-testid={'store-segment-filter-dropdown-tabs'}>
                    {tabs.map((name) => {
                      const disabled = name === 'aggregate' && aggregateLimitReached;

                      if (disabled) {
                        return (
                          <Tooltip overlayStyle={{ width: 'fit-content', whiteSpace: 'nowrap', maxWidth: 600 }} key={name} placement="right" title="You have reached the limit of 5 aggregates per segment.">
                            <Tab
                              data-testid={'store-segment-filter-'+name+'-tab'}
                              key={name}
                              selected={name === tab}
                              ref={te.refChanger(name)}
                              onClick={() => !disabled ? setTab(name) : undefined}
                              disabled={disabled}
                            >
                              {SEGMENT_FILTER_LABEL_MAP[name]}
                            </Tab>
                          </Tooltip>
                        );
                      }

                      return (
                        <Tab
                          data-testid={'store-segment-filter-'+name+'-tab'}
                          key={name}
                          selected={name === tab}
                          ref={te.refChanger(name)}
                          onClick={() => !disabled ? setTab(name) : undefined}
                          disabled={disabled}
                        >
                          {SEGMENT_FILTER_LABEL_MAP[name]}
                        </Tab>
                      );
                    })}
                    <TabIndicator tab={te.tabEl} $id={id} key={te.updater}/>
                  </Tabs>
                ) : null}
              </Header>
              {renderContent()}
            </Overlay>
          </div>
        </Modal>
        <Container onClick={() => {
          if (disabled) {
            return
          }
          setOpened(true)
        }} style={style}>
          {children}
        </Container>
      </>
    );
  }

  return (
    <Dropdown
      data-testid={'store-segment-filter-dropdown'}
      trigger={['click', 'contextMenu']}
      open={opened}
      placement="topCenter"
      onOpenChange={setOpened}
      overlay={(
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Overlay $isDefault hidePreview={hidePreview} $mw={maxWidth || 420}>
            <Header>
              <SearchContainer>
                <Input
                  style={{ width: '100%', height: '30px', borderRadius: '5px' }}
                  placeholder={t('labels.search')}
                  withSuffix
                  withPrefix
                  prefix={<Icon size={16} name="Operators-search" color="#909399" />}
                  suffix={!!searchInputValue.length ? <div style={{ cursor: 'pointer' }} onClick={() => setSearchInputValue('')}><Icon size={16} name="Clear" color="#909399" /></div> : null}
                  type="text"
                  value={searchInputValue}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                />
              </SearchContainer>
              {!searchInputValue.trim().length ? (
                <Tabs $js={tabs.length === 1} disabled={!!searchInputValue.trim().length} data-testid={'store-segment-filter-dropdown-tabs'}>
                  {tabs.map((name) => (
                    <Tab
                      data-testid={'store-segment-filter-'+name+'-tab'}
                      key={name}
                      selected={name === tab}
                      ref={te.refChanger(name)}
                      onClick={() => setTab(name)}
                    >
                      {SEGMENT_FILTER_LABEL_MAP[name]}
                    </Tab>
                  ))}
                  <TabIndicator tab={te.tabEl} $id={id} key={te.updater}/>
                </Tabs>
              ) : null}
            </Header>
            {renderContent()}
          </Overlay>
        </div>
      )}
    >
      <Container style={style}>
        {children}
      </Container>
    </Dropdown>
  );
}

export default FiltersDropdown;

