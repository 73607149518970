import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 50px;
`;

export const TabsContainer = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  margin-bottom: 30px;
`;

export const Tab = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 12px;
  font-size: 14px;
  color: #303133;
  cursor: pointer;
`;

export const TabIndicator = styled.div`
  width: 33.3%;
  height: 3px;
  background: #31C447;
  position: absolute;
  bottom: -1px;
  transition: all 280ms ease;
  transform: translateX(${({ tab }) => tab * 100}%);
`;

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 6px;
`;

