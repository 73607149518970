import styled from 'styled-components';

import { extract } from '@utils';

import { Colors } from '@res';

export const Avatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: #DABB1F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.Sidebar.TEXT_CONTRAST};
  background: center / contain no-repeat url("${extract('image')}");
  background-size: cover;
  background-color: #fff;
  border: 1px solid #183979;
`;

