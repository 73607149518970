import { WorkflowOptionsActionTypes } from '@store/actions/types';
import { createRequestAction } from '@store/lib/utils/creators';

import { all, takeEvery, put } from 'redux-saga/effects';

import { WorkflowOptionsType } from '@hooks/useWorkflowOptions';

function* workflowOptionsOptionsSaga(action) {
  const search = action.payload.search || void 0;

  switch (action.payload.entity) {
    case WorkflowOptionsType.VIBER:
    case WorkflowOptionsType.EMAIL:
    case WorkflowOptionsType.SMS:
    case WorkflowOptionsType.MOBILE_PUSH:
    case WorkflowOptionsType.WEB_PUSH:
    case WorkflowOptionsType.WEBHOOK:
    case WorkflowOptionsType.WEB_POPUP:
    case WorkflowOptionsType.MESSAGE_FEED:
      return yield put(createRequestAction(action, {
        method: 'get',
        endpoint: `/api/${action.payload.entity}/simple`,
        params: {
          name: search,
          page: action.payload.page,
          per_page: action.payload.perPage
        },
      }));
    case WorkflowOptionsType.RFM_ANALYTICS:
      return yield put(createRequestAction(action, {
        method: 'get',
        endpoint: '/api/rfm/simple',
        params: {
          name: search,
          page: action.payload.page,
          per_page: action.payload.perPage
        },
      }));
    case WorkflowOptionsType.WORKFLOW:
      return yield put(createRequestAction(action, {
        method: 'get',
        endpoint: '/api/workflow/simple',
        params: {
          name: search,
          page: action.payload.page,
          per_page: 100
        },
      }));
    default:
      console.log('Unknown action: ', action);
  }
}

function* workflowOptionsPreviewSaga(action) {
  switch (action.payload.entity) {
    case WorkflowOptionsType.VIBER:
    case WorkflowOptionsType.EMAIL:
    case WorkflowOptionsType.SMS:
    case WorkflowOptionsType.MOBILE_PUSH:
    case WorkflowOptionsType.WEB_PUSH:
    case WorkflowOptionsType.WEBHOOK:
    case WorkflowOptionsType.WEB_POPUP:
    case WorkflowOptionsType.MESSAGE_FEED:
      return yield put(createRequestAction(action, {
        method: 'get',
        endpoint: `/api/${action.payload.entity}/simple`,
        params: {
          id: action.payload.ids,
          page: 1,
          per_page: 9999,
        },
      }));
    case WorkflowOptionsType.RFM_ANALYTICS:
      return yield put(createRequestAction(action, {
        method: 'get',
        endpoint: '/api/rfm/simple',
        params: {
          id: action.payload.ids,
          page: 1,
          resource_id: action.payload.resource_id,
          per_page: 200,
        },
      }));
    case WorkflowOptionsType.WORKFLOW:
      return yield put(createRequestAction(action, {
        method: 'get',
        endpoint: '/api/workflow/simple',
        params: {
          id: action.payload.ids,
          page: 1,
          per_page: 200,
        },
      }));
    default:
      console.log('Unknown action: ', action);
  }
}

export default function* workflowOptionsRootSaga() {
  yield all([
    takeEvery(WorkflowOptionsActionTypes.GET, workflowOptionsOptionsSaga),
    takeEvery(WorkflowOptionsActionTypes.PREVIEW, workflowOptionsPreviewSaga),
  ]);
}
