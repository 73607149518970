const Paths = {
  LOGIN: '/login',
  REGISTRATION: '/P2Kv6uLsYJQcqwVRbprtCUM3F74WSGmg9NydXkEhxz5fTeZaHj',
  REGISTRATION_SECOND_STEP: '/P2Kv6uLsYJQcqwVRbprtCUM3F74WSGmg9NydXkEhxz5fTeZaHj/step-2',
  REGISTRATION_SUCCESS: '/registration-success',
  CREATE_PASSWORD_PAGE: '/create-password',
  RESET_PASSWORD: '/forgot-password',
  RESET_PASSWORD_FORM: '/reset-password-form',
  INVITE_USER_FORM: '/invite-user-form',
  HOME: 'app/:appId/*',
  QUESTIONNAIRE: '/questionnaire',
  QUESTIONNAIRE_SUCCESS: '/questionnaire-success',
  ACCOUNT_CONFIRMATION: '/account-confirmation',

  EMAIL_VERIFICATION_SUCCESS_PAGE: '/email-verified',
  VERIFY_EMAIL: '/verify-email',

  DASHBOARD: '/dashboard',

  EXPRESSIONS: '/expressions',
  CREATE_AGGREGATE: '/players/fields/aggregates/editor',

  WORKFLOWS: '/workflows',
  CREATE_WORKFLOW: '/workflows/create',
  WORKFLOW_TEMPLATES: '/workflows/templates',
  EDIT_WORKFLOW: '/workflows/edit',

  CAMPAIGNS: '/instant-send',
  CREATE_CAMPAIGN: '/instant-send',

  RESOURCES_FILES: '/resources/files',
  RESOURCES_QUERIES: '/resources/queries',
  RESOURCES_SEGMENTS: '/resources/segments',
  EDIT_RESOURCES_SEGMENT: '/resources/segments/edit',
  RESOURCES_COMBINED_SEGMENTS: '/resources/combined-segments',

  ANALYTICS: '/analytics',

  CONTACTS_ALL: '/players',
  CONTACTS_IMPORT: '/players/import',
  CONTACTS_IMPORT_HISTORY: '/players/import/history',
  CONTACTS_FIELDS_SETTINGS: '/players/fields',
  CONTACT: '/players/player',

  EVENTS: '/events',

  TEMPLATES_EMAIL: '/templates/emails',

  TEMPLATES_VIBER: '/templates/viber',
  TEMPLATES_VIBERS: '/templates/viber/list',
  TEMPLATES_VIBER_CREATE: '/templates/viber/new',
  TEMPLATES_VIBER_EDIT: '/templates/viber/:id/edit',
  TEMPLATES_VIBER_VIEW: '/templates/viber/:id',
  TEMPLATES_VIBER_DUPLICATE: '/templates/viber/:id/duplicate',

  TEMPLATES_SMS: '/templates/sms',
  TEMPLATES_SMSES: '/templates/sms/list',
  TEMPLATES_SMS_CREATE: '/templates/sms/create',
  TEMPLATES_SMS_EDIT: '/templates/sms/:id/edit',
  TEMPLATES_SMS_VIEW: '/templates/sms/:id',
  TEMPLATES_SMS_DUPLICATE: '/templates/sms/:id/duplicate',

  TEMPLATES_MOBILE_PUSH: '/templates/mobile-push',
  TEMPLATES_MOBILE_PUSHES: '/templates/mobile-push/list',

  TEMPLATES_CONTENT_CARDS: '/templates/content-card',

  TEMPLATES_WEBPUSH: '/templates/web-push',
  TEMPLATES_WEBPUSHES: '/templates/web-push/list',
  TEMPLATES_WEBPUSH_CREATE: '/templates/web-push/new',
  TEMPLATES_WEBPUSH_EDIT: '/templates/web-push/:id/edit',
  TEMPLATES_WEBPUSH_VIEW: '/templates/web-push/:id',
  TEMPLATES_WEBPUSH_DUPLICATE: '/templates/web-push/:id/duplicate',

  TEMPLATES_API_REQUEST: '/templates/webhook',
  TEMPLATES_API_REQUESTS: '/templates/webhook/list',

  TEMPLATES_WEB_POPUP: '/templates/web-popups',
  TEMPLATES_WEB_POPUPS: '/templates/web-popups/list',
  TEMPLATES_WEB_POPUPS_CREATE: '/templates/web-popups/new',
  TEMPLATES_WEB_POPUPS_EDIT: '/templates/web-popups/:id/edit',
  TEMPLATES_WEB_POPUPS_VIEW: '/templates/web-popups/:id',
  TEMPLATES_WEB_POPUPS_DUPLICATE: '/templates/web-popups/:id/duplicate',

  SUBSCRIPTION_GROUPS: '/players/unsubscribe-groups',

  PROMO_CODES: '/promo-codes',

  NOTIFICATIONS: '/notifications',
  // NOTIFICATIONS_SMS: '/notifications/sms',
  // NOTIFICATIONS_EMAIL: '/notifications/email',
  NOTIFICATIONS_MOBILE_PUSHES: '/notifications/pushes/mobile',
  NOTIFICATIONS_BROWSER_PUSHES: '/notifications/pushes/browser',

  FUNNEL_ANALYTICS: '/analytics/funnel',
  RFM_ANALYTICS: '/analytics/rfm',

  UPDATES_HISTORY: '/updates-history',

  APPLICATIONS: '/applications',

  INTEGRATIONS: '/settings/integrations',

  OTP_TEMPLATE: '/settings/integrations/otp-template',

  USERS_SETTINGS: '/settings/users',
  ROLES_SETTINGS: '/settings/roles',
  ROLES_CREATE: '/settings/roles/create',
  ROLES_EDIT: '/settings/roles/edit',
  ROLES_DUPLICATE: '/settings/roles/duplicate',
  WORKSPACES: '/settings/workspaces',
  ACCOUNT_SETTINGS: '/settings/account',
  ACCOUNT_SETTINGS_USER: '/settings/account/account',
  ACCOUNT_SETTINGS_BILLING: '/settings/account/billing',

  NOT_FOUND: '/pagenotfound',

  UNDER_CONSTRUCTION: '/maintenance'
};

export default Paths;
