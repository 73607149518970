import styled, { css } from 'styled-components';

import { extract } from '@utils';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  padding: 12px;
  background: #FFFFFF;
  border: 1px solid #D3DBE7;
  border-radius: 5px;
  
  ${({ isLast }) => isLast ? '' : css`
    margin-bottom: 6px;
  `}
`;

export const Flag = styled.img`
 
`;

export const Status = styled.div`
  background-color: ${props => props.type === 'success' ? '#12B76A1F' : props.type === 'danger' ? '#F790091F' : '#F044381F'};
  color: #7C94B1;
  line-height: 16px;
  padding: 0 5px;
  border-radius: 4px;
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
  white-space: nowrap;
  width: 168px;
  height: 17px;
  font-family: Manrope-SemiBold;
  //color: #000000;
  // ${({ error }) => error ? 'color: #C8402F' : 'color: #1A232E'}
  
  & > div{
  color: #1A232E !important;
    margin-bottom: 2px;
  }
`;

export const WorkspaceName = styled.div`
  color: #7C94B1;
  margin-bottom: 8px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  color: #1A232E;
  font-size: 12px;
  line-height: 18px;
`;

export const Body = styled.div`
  //margin-left: 5px;
  font-size: 14px;
  line-height: 17px;

  /* Black text */

  color: #303133;


  * {
    //margin-left: 1.5px;
    //margin-right: 1.5px;
  }
`;

export const Link = styled.div`
  color: #1A232E;
  overflow: hidden;
  height: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  cursor: pointer;
  ${({ $underline }) => $underline ? `
    text-decoration: underline;
  ` : ''}
`;

export const StaticPoint = styled.div`
  position: absolute;
  right: 17px;
  top: 18px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #04A9BF;
`;

export const Time = styled.div`
  font-size: 12px;
  color: #7C94B1;
  font-family: Manrope-Medium;
`;

export const Image = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 7px;
  margin-left: 6px;
  border-radius: 50%;
  background: center / contain url("${extract('$url')}") no-repeat;
`;

export const Row = styled.div`
  display: flex;
`;

export const ItemBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 346px;
`;

export const BottomLeft = styled.div`
  display: flex;
  gap: 8px;
`;

export const Icon = styled.img`
`;
