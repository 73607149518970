import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  min-width: 380px;
  width: 50%;
  
  
  ${styledProp('$integrated', css`
    width: 100%;
    display: flex;
    flex-direction: column;
    

  `, css`
    margin-bottom: 15px;
    margin-left: 5px;

  `)}
`;

export const Content = styled.div`
  background: #F9FBFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  height: 590px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  
  ${styledProp('onboard', `
    border: 3px solid #BDD1F6;
  `)}

  ${styledProp('$integrated', css`
    //width: 380px;
    border: none;
    background: transparent;
    box-shadow: none;
  `)}
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;

  ${styledProp('selected', css`
    color: #31C447;
  `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  padding: 14px;
`;

export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #31C447;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ tab }) => {
    const el = document.querySelector(`#mp-tab-${tab}`);

    return css`
      width: ${el?.getBoundingClientRect()?.width}px;
      left: ${el?.offsetLeft}px;
    `;
  }}
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s linear;  
  position: absolute;
  top: ${props => props.$hasTabs ? '-78px' : '-38px'};
  width: 100%;
`