import { AccountActionTypes } from '@store/actions/types';
import { asCreator, withCallbacks } from '@store/lib';

import { compose } from '@utils';

export const getSubscription = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.GET_SUBSCRIPTION,
  }))
);

export const getDefaultPaymentMethod = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.GET_DEFAULT_PAYMENT_METHOD,
  })),
);

export const setupStripeIntent = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.SETUP_STRIPE_INTENT,
  }))
);

export const subscribe = compose(
  withCallbacks,
  asCreator(({ price_id, payment_method, product_id, start_quantity }) => ({
    type: AccountActionTypes.SUBSCRIBE,
    payload: { price_id, payment_method, product_id, start_quantity },
  })),
);

export const cancelSubscription = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.CANCEL_SUBSCRIPTION,
  }))
);

export const updatePaymentMethod = compose(
  withCallbacks,
  asCreator(({ payment_method }) => ({
    type: AccountActionTypes.UPDATE_PAYMENT_METHOD,
    payload: { payment_method },
  })),
);

export const getCustomer = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.GET_CUSTOMER,
  })),
);

export const updateCustomer = compose(
  withCallbacks,
  asCreator(({ id, billing_address, first_name, last_name }) => ({
    type: AccountActionTypes.UPDATE_CUSTOMER,
    payload: { id, billing_address, first_name, last_name },
  })),
);

export const getRegularInvoices = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.GET_REGULAR_INVOICES,
  })),
);

export const getProduct = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.GET_PRODUCT,
  })),
);

export const getQuantityLog = compose(
  withCallbacks,
  asCreator(() => ({
    type: AccountActionTypes.GET_QUANTITY_LOG,
  })),
);

export const resumeSubscription = compose(
  withCallbacks,
  asCreator(({ payment_method }) => ({
    type: AccountActionTypes.RESUME_SUBSCRIPTION,
    payload: { payment_method }
  })),
);

export const getAppsContactsCount = compose(
  withCallbacks,
  asCreator(({ appIds }) => ({
    type: AccountActionTypes.GET_APPS_CONTACTS_COUNT,
    payload: { appIds }
  })),
);

export const getAccountMetrics = compose(
  withCallbacks,
  asCreator(({ entity, range }) => ({
    type: AccountActionTypes.GET_ACCOUNT_METRICS,
    payload: { entity, range },
    meta: { entity },
  })),
);

export const getSendingLimitMetrics = compose(
  withCallbacks,
  asCreator(({ range, app_ids }) => ({
    type: AccountActionTypes.GET_SENDING_LIMIT_METRICS,
    payload: { range, app_ids },
  })),
);
