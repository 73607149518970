import { compose } from '@utils';

import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '../../lib';
import { PromoCodesActionTypes } from '../types';

export const getPromoCodes = compose(
  withPagination,
  withOrdering,
  withFilters(['name', '$type', 'user_id', 'status', 'start_date', 'end_date']),
  asCreator(() => ({
    type: PromoCodesActionTypes.GET,
  }))
);

export const deletePromoCode = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: PromoCodesActionTypes.DELETE,
    meta: { id },
  }))
);

export const savePromoCode = compose(
  withCallbacks,
  asCreator(({ id, fields }) => ({
    type: PromoCodesActionTypes.SAVE,
    meta: { id },
    payload: { fields },
  })),
);

export const archivePromoCode = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: PromoCodesActionTypes.ARCHIVE,
    payload: { id },
  })),
);

export const unArchivePromoCode = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: PromoCodesActionTypes.UN_ARCHIVE,
    payload: { id },
  })),
);
