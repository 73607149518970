import styled from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
    
    
`;

export const CircleContainer = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #909399;
  box-sizing: border-box;
  border-radius: 42px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;

    ${styledProp('disabled', `
  opacity: 0.4;
  `, `
  `)}
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 42px;
  transition: 280ms ease all;
    background: #31C447;
  
  ${styledProp('selected', `
    opacity: 1;
    transform: scale(1);
  `, `
    opacity: 0;
    transform: scale(0.5);
  `)}
`;

export const Title = styled.div`
  font-family: Manrope-SemiBold;
  ${styledProp('disabled', `
  color: #909399;
  `, `
  color: #303133;
  `)}
  
  line-height: 120%;
  ${styledProp('bigger', `
  font-size: 18px;
  `, `
  font-size: 13px;
  `)}

  ${styledProp('sendCard', `
  font-size: 12px;
  `)}
`;
