import { Button } from '@velitech/ui';
import { Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { UserPermissions } from '@constants';

import { Icon, WithPermissions } from '@components';

const AddFilterButton = ({ disabled, limitReached, isHardQuery }) => {
  const { p } = useTranslation('segments_page');

  if (limitReached || isHardQuery) {
    const isHardQueryText = isHardQuery && p('complex_query_tooltip');
    const limitReachedText = limitReached && 'You have reached the limit of 10 rules per segment.';

    return (
      <Tooltip overlayStyle={{ width: 'fit-content', whiteSpace: 'nowrap', maxWidth: 600 }} placement="right" title={isHardQueryText || limitReachedText}>
        <div>
          <Button
            onClick={e => e?.stopImmediatePropagation?.()}
            data-testid="store-segment-add-filter-button"
            addonLeft={<Icon size={16} name="Add-new" />}
            style={{ width: 'auto', marginTop: 20 }}
            disabled
          >
            <div>{p('add_filter')}</div>
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <WithPermissions name={UserPermissions.CREATE_SEGMENTS}>
      <Button
        onClick={e => e?.stopImmediatePropagation?.()}
        data-testid="store-segment-add-filter-button"
        addonLeft={<Icon size={16} name="Add-new" />}
        style={{ width: 'auto', marginTop: 20 }}
        disabled={disabled}
      >
        <div>{p('add_filter')}</div>
      </Button>
    </WithPermissions>
  );
};

export default AddFilterButton;
