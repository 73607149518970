import uniqBy from 'lodash.uniqby';

export const parseLinks = (html, prevLinks) => {

  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');
  const excludeLinksAttribute = ['dynamic.otp_link', 'dynamic.otp_code', 'unsubscribe_url'];
  const links = document.getElementsByTagName('a');

  const formattedLink = Array.from(links)
    .filter(link => {
      const shouldExclude = excludeLinksAttribute.some((text) => link?.attributes?.href?.value?.includes(text))
      if(shouldExclude) return false;
      return !!link?.attributes?.href?.value;
    })
    .map((link) => {
      const existLink = prevLinks?.find(l => l.url === link.href);
      return { url: link?.attributes?.href?.value || link.href, track: existLink ? existLink.track : true }
    });

  return uniqBy(formattedLink, 'url');
};
