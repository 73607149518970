import { DeprecatedIcon as Icon, ICON_SIZES, DEPRECATED_ICONS_NAMES as ICONS_NAMES, PALETTES } from "@velitech/ui";

import mobilePreview from "@res/images/Mobile_Message_Preview.png";
import placeholder from '@res/images/placeholder.png';

import { PreviewButton } from "@components/ui/PreviewTemplate/components/PreviewButton";
import { Image } from "@components/ui/PreviewTemplate/components/PreviewContent/styled";

import { ButtonContainer, Container, ImageContainer, Message, MobileContainer, TextContainer, Title } from './styled';

export const NotificationPhone = ({
  title,
  message,
  buttons,
  imageUrl,
}) => {
  const hasButtonsName = buttons?.some((button) => button.name);
  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={'light'} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });

  return (
    <MobileContainer image={mobilePreview}>
      <Container>
        <Icon className={'close-icon'} name={ ICONS_NAMES.CloseOutline } color={PALETTES.NEUTRAL_500} size={ICON_SIZES.S16}/>
        <ImageContainer>
          <Image src={imageUrl || placeholder} alt='' />
        </ImageContainer>
        <TextContainer>
          <Title>
            {title}
          </Title>
          <Message>
            {message}
          </Message>
          {hasButtonsName && (
            <ButtonContainer>
              {renderButtons}
            </ButtonContainer>
          )}
        </TextContainer>
      </Container>
    </MobileContainer>
  );
};
