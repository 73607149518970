import { COLS_SELECTION_OPTIONS, DEFAULT_COLS } from '@constants';

import { by } from '@utils';

import { ContactsActionTypes, UserActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  contacts: {
    page: 0,
    loading: false,
    data: null,
    meta: null,
  },
  count: {

  },
  aggregates: {
    loading: false,
  },
  cols: DEFAULT_COLS,
  colsOptions: COLS_SELECTION_OPTIONS,
  fieldsSettings: {
  },
  fieldsSettingsList: [],
  importHistory: {
    loading: false,
  },
  importMapping: {
  },
  importMetadata: {
    step: 'upload',
  },
  currentFieldInfo: {
    loading: false,
  },
  currentContactInfo: {
    sms: {
      data: [],
    },
    emails: {
      data: [],
    },
    contactEvents: {
      data: [],
    },
    contactEventsTotal: 0,
    info: {},
    push: {
      data: [],
    },
    webhook: {
      data: [],
    },
    involvement: {
      segments: {},
    },
    audits: [],
    summary: {
      sms: {},
      email: {},
      push: {},
    }
  },
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ContactsActionTypes.GET:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
        },
      };
    case ContactsActionTypes.GET_SUCCESS:
      return {
        ...state,
        contacts: {
          data: action.payload,
          loading: false,
        },
      };
    case ContactsActionTypes.GET_ERROR:
      return {
        ...state,
        contacts: {
          loading: false,
        },
      };
    case ContactsActionTypes.GET_CONTACT_EVENT:
      return {
        ...state,
        contactEvents: {
          loading: true,
        },
      };
    case ContactsActionTypes.GET_CONTACT_EVENT_ERROR:
      return {
        ...state,
        contactEvents: {
          loading: false,
        },
      };
    case ContactsActionTypes.GET_CONTACT_EVENT_SUCCESS:
      return {
        ...state,
        contactEvents: {
          ...action.payload,
          loading: false,
        },
      };
    case ContactsActionTypes.GET_CONTACT_EVENT_TOTAL_SUCCESS:
      return {
        ...state,
        contactEventsTotal: action.payload.total,
      };
    case ContactsActionTypes.GET_IMPORT_HISTORY:
      return {
        ...state,
        importHistory: {
          ...state.importHistory,
          loading: true,
        },
      };
    case ContactsActionTypes.GET_IMPORT_HISTORY_SUCCESS:
      return {
        ...state,
        importHistory: {
          ...action.payload,
          loading: false,
        },
      };
    case ContactsActionTypes.GET_IMPORT_HISTORY_ERROR:
      return {
        ...state,
        importHistory: {
          loading: false,
        },
      };
    case ContactsActionTypes.GET_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          info: action.payload,
        },
      }
    case ContactsActionTypes.GET_CUSTOMER_FIELD_INFO:
      return {
        ...state,
        currentFieldInfo: {
          loading: true,
        },
      }
    case ContactsActionTypes.GET_CUSTOMER_FIELD_INFO_ERROR:
      return {
        ...state,
        currentFieldInfo: {
          loading: false,
        },
      }
    case ContactsActionTypes.GET_CUSTOMER_FIELD_INFO_SUCCESS:
      return {
        ...state,
        currentFieldInfo: {
          data: action.payload,
          loading: false,
        },
      }
    case ContactsActionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        importMapping: action.payload,
        importMetadata: {
          ...state.importMetadata,
          step: 'field-confirmation',
        },
      };
    case ContactsActionTypes.GET_CONTACT_INVOLVEMENT_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          involvement: action.payload,
        },
      }
    case ContactsActionTypes.GET_CONTACT_AUDITS_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          audits: action.payload,
        },
      }
    case ContactsActionTypes.START_IMPORT_SUCCESS:
      return {
        ...state,
        importMapping: {
          ...state.importMapping,
          ...action.payload,
        },
        importMetadata: {
          ...state.importMetadata,
          step: 'success',
        },
        importProgress: null,
      };
    case ContactsActionTypes.GET_IMPORT_PROGRESS_SUCCESS:
      return {
        ...state,
        importProgress: action.payload,
      };
    case ContactsActionTypes.GET_FIELD_SETTINGS_SUCCESS:
      return {
        ...state,
        fieldsSettings: action.payload,
      };
    case ContactsActionTypes.GET_FIELD_LIST_SUCCESS:
      return {
        ...state,
        fieldsSettingsList: {
          data: action.payload,
          meta: {
            total: action.payload.length,
          }
        },
      };
    case ContactsActionTypes.GET_EVENT_LIST_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };
    case ContactsActionTypes.CHANGE_IMPORT_STEP:
      return {
        ...state,
        importMetadata: {
          ...state.importMetadata,
          step: action.payload.step,
        },
      };
    case ContactsActionTypes.UPDATE_IMPORT_FIELDS_CONFIGURATION:
      return {
        ...state,
        importMetadata: {
          ...state.importMetadata,
        },
      };
    case ContactsActionTypes.GET_CONTACT_EMAIL_INFO_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          emails: action.payload,
        }
      }
    case ContactsActionTypes.GET_CONTACT_SMS_INFO_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          sms: action.payload,
        }
      }
    case ContactsActionTypes.GET_EMAIL_SUMMARY_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          summary: {
            ...state.currentContactInfo.summary,
            email: action.payload,
          }
        }
      }
    case ContactsActionTypes.GET_WEBPUSH_SUMMARY_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          summary: {
            ...state.currentContactInfo.summary,
            push: action.payload,
          }
        }
      }
    case ContactsActionTypes.GET_WEBHOOK_SUMMARY_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          summary: {
            ...state.currentContactInfo.summary,
            webhook: action.payload,
          }
        },
      };
    case ContactsActionTypes.GET_SMS_SUMMARY_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          summary: {
            ...state.currentContactInfo.summary,
            sms: action.payload,
          }
        }
      }
    case ContactsActionTypes.GET_CONTACT_PUSH_INFO_SUCCESS:
      return {
        ...state,
        currentContactInfo: {
          ...state.currentContactInfo,
          push: action.payload,
        }
      };
    case ContactsActionTypes.SET_COLS:
      return {
        ...state,
        cols: action.payload.cols,
      };
    case ContactsActionTypes.SET_COLS_OPTIONS:
      if (state.colsOptions.length && action.meta.keepOrder) {
        return {
          ...state,
          colsOptions: action.payload.colsOptions
            .sort((a, b) => {
              if (COLS_SELECTION_OPTIONS.indexOf(a)) {
                return 10000;
              }
              if (COLS_SELECTION_OPTIONS.indexOf(b)) {
                return -10000;
              }

              return state.colsOptions.findIndex(({ value }) => value === a.value) - state.colsOptions.findIndex(({ value }) => value === b.value)
            })
        }
      }
      return {
        ...state,
        colsOptions: action.payload.colsOptions,
      };
    case ContactsActionTypes.GET_CUSTOMERS_COUNT:
      return {
        ...state,
        count: {
          ...state.count,
          [action.meta.customPage || action.meta.page]: {
            ...(state.count[action.meta.customPage || action.meta.page] || {}),
            loading: true,
          },
        },
      };
    case ContactsActionTypes.GET_CUSTOMERS_COUNT_SUCCESS:
      return {
        ...state,
        count: {
          ...state.count,
          [action.meta.customPage || action.meta.page]: {
            ...(state.count[action.meta.customPage || action.meta.page] || {}),
            ...action.payload,
            loading: false,
          },
        },
      };
    case ContactsActionTypes.GET_CUSTOMERS_COUNT_ERROR:
      return {
        ...state,
        count: {
          ...state.count,
          [action.meta.customPage || action.meta.page]: {
            // ...(state.count[action.meta.customPage || action.meta.page] || {}),
            loading: false,
          },
        },
      };
    case ContactsActionTypes.CLEAR_CUSTOMERS_COUNT:
      return {
        ...state,
        count: {},
      };
    case ContactsActionTypes.GET_AGGREGATES_LIST:
      return {
        ...state,
        aggregates: {
          loading: true,
          ...state.aggregates,
        },
      };
    case ContactsActionTypes.GET_AGGREGATES_LIST_SUCCESS:
      return {
        ...state,
        aggregates: {
          loading: false,
          ...action.payload
        }
      };
    case ContactsActionTypes.GET_AGGREGATES_LIST_ERROR:
      return {
        ...state,
        aggregates: {
          ...state.aggregates,
          loading: false,
        }
      };
    case ContactsActionTypes.CHANGE_FIELD_DISPLAYING_STATUS: {
      const { field, active, type } = action.payload;
      const entity = type === 'aggregate' ? 'aggregates' : 'fieldsSettingsList';
      const identifier = type === 'aggregate' ? 'name' : 'field';

      return {
        ...state,
        [entity]: {
          ...state[entity],
          data: (state[entity].data || []).map(e => e[identifier] === field ? ({ ...e, active }) : e),
        }
      };
    }
    case ContactsActionTypes.REORDER_FIELDS: {
      const { fields } = action.payload;

      return {
        ...state,
        fieldsSettingsList: {
          ...state.fieldsSettingsList,
          data: (state.fieldsSettingsList.data || []).map(f => {
            const found = fields.find(by('field', f.field));

            return {
              ...f,
              sort_order: found?.sort_order || f.sort_order,
            };
          }),
        },
        aggregates: {
          ...state.aggregates,
          data: (state.aggregates.data || []).map(a => {
            const found = fields.find(by('field', a.name));

            return {
              ...a,
              sort_order: found?.sort_order || a.sort_order,
            };
          })
        }
      }
    }
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    default:
      return { ...state };
  }
};

export default contactsReducer;
