import { useRef, useState } from 'react';

import { ICON_SIZES, DeprecatedIcon as Icon, Popover } from '@velitech/ui'

import { useOutsideClickListener } from '@hooks';

import languagesJSON from '@res/languagesList.json';

import {
  ToggleButton,
} from '@components'

import { LanguageItem } from './LnaguageItem';
import { Container, Content, ContryContainer, ListType } from './styled';

import { SearchInput } from '../SearchInput';

export const SelectLanguage = ({ selectedLanguages, onAddLang, handleOpenLang }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const ref = useRef();

  useOutsideClickListener(ref, (e) => {
    e.stopPropagation();
    setOpen(false);
  })

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value)
  };

  const languages = languagesJSON.filter(({ languageName }) => search ? languageName.toLowerCase().includes(search.toLowerCase()) : true);

  const existLanguages = selectedLanguages?.filter(({ value }) => search ? value.toLowerCase().includes(search.toLowerCase()) : true)
  const restLanguages = languages.filter(({ languageCode }) => !existLanguages?.some((langItem) => langItem.id === languageCode));

  const isDefault = (langCode) => {
    const current = selectedLanguages?.find(({ id }) => langCode === id);
    return current?.is_default;
  };

  const handleAddLang = (code) => {
    onAddLang(code);
    setOpen(false);
  };

  const handleOpenSelect = () => {
    setOpen(!open);
  }

  return (
    <Content>
      <div style={{ minWidth: '100px', marginRight: '10px' }} ref={ref}>
        <Popover
          noPadding
          open={open}
          closeOnClick={true}
          placement='bottom-start'
          zIndex={'10000'}
          content={
            <Container>
              <SearchInput
                value={search}
                onClear={() => setSearch('')}
                onChange={handleSearchChange}
              />
              <ContryContainer>
                {!!existLanguages?.length && (<ListType>
                  Existing languages:
                </ListType>)}
                {existLanguages?.map(({ icon, id, value, is_default }) => (
                  <LanguageItem classNames={'disabled'} key={id} code={id} icon={icon} name={value} isDefault={is_default}/>
                ))}
                <ListType>
                  Other languages:
                </ListType>
                {restLanguages?.map(({ emoji, languageCode, languageName }) => (
                  <LanguageItem onClick={() => handleAddLang(languageCode)} classNames={isDefault(languageCode) ? 'isDefault' : ''} key={languageCode} icon={emoji} name={languageName} code={languageCode} />
                ))}
              </ContryContainer>
            </Container>

          }
        >
          <ToggleButton
            testId="add_language-toggle"
            opened={open}

            onToggle={() => handleOpenLang(handleOpenSelect)}
          >
            <Icon size={ICON_SIZES.S16} name={Icon.NAMES.Add}/>
            Add Languge
          </ToggleButton>

        </Popover>

      </div>
    </Content>

  )
}
