import React from 'react';
import { useInView } from 'react-intersection-observer';

import languagesJSON from '@res/languagesList.json';

import { EmailTypeBadge, EmailLangBadge } from '@components/ui/LargeTemplateItem/components';

import { HoverMenu, Placeholder, Preview } from './styled';

export const IframePreview = React.memo(({ type, frame, data, showPlaceholder, hovered }) => {
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const defaultTemplateData = data.languages?.find((langItem) => langItem.is_default) || data.languages?.[0];

  const languagesList = data.languages?.map((langTemplateItem) => {
    const isDefault = langTemplateItem.is_default;
    const langItem = languagesJSON.find((langItem) => langItem.languageCode === langTemplateItem?.language_code);

    if(!langItem?.languageName) return null;

    return ` ${langItem?.languageName}${isDefault ? '(default)' : ''}`
  }).filter((data) => data)

  return (
    <div ref={inViewRef}>
      {inView && <Preview $type={type} loading='lazy' srcDoc={defaultTemplateData?.data || defaultTemplateData?.code} sandbox ref={frame} scrolling="no" /> }
      {type === 'email' && hovered && (
        <div style={{ display: 'flex', gap: '10px' }}>
          <EmailTypeBadge key={data.id} id={data.id} type={defaultTemplateData?.template_driver} />
          {languagesList?.length > 0 && <EmailLangBadge languageNames={languagesList} />}
        </div>
      )}
      <HoverMenu className="HoverMenu" />
      {(showPlaceholder || !inView)&& (
        <Placeholder>
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_9107_486013)">
              <rect width="64" height="64" rx="14" fill="white" />
              <rect width="64" height="64" rx="14" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55 17.5C55 22.7467 50.7467 27 45.5 27C40.2533 27 36 22.7467 36 17.5C36 12.2533 40.2533 8 45.5 8C50.7467 8 55 12.2533 55 17.5ZM24.7656 26.6404L36.5753 44.1247L42.2889 36.3333C43.4949 34.6887 45.955 34.7008 47.1447 36.3572L67 64H50H22H-6L19.8108 26.6151C21.0108 24.877 23.5834 24.8901 24.7656 26.6404Z"
                fill="#F0F2F6"
              />
            </g>
            <defs>
              <clipPath id="clip0_9107_486013">
                <rect width="64" height="64" rx="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Placeholder>
      )}
    </div>
  );
});
