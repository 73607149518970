import { ICON_SIZES, DeprecatedIcon as Icon } from '@velitech/ui';

import { Container, Count, RateName, RateNameContainer, SkeletonCount } from './styled';

export const RateItem = ({ data, loading }) => {
  return (
    <Container>
      <RateNameContainer>
        <Icon size={ICON_SIZES.S24} name={data.icon} />
        <RateName>{data?.title}</RateName>
      </RateNameContainer>
      {loading && <SkeletonCount active loading={loading} />}
      {!loading && <Count>{data?.persentage?.toFixed(2)} %</Count>}
    </Container>
  );
};
