import { Menu } from 'antd';
import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Button } from '@components/controls';

styled(Button)`
  &:hover {
    color: #fff;
    background: #31c447 !important;
  }
`;

styled(Menu)`
  padding: 6px 1px;
  border-radius: 7px;
  border: 1px solid #d3dbe7;
  background: #fff;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
`;

export const IconContainer = styled.span`
  display: inline-flex;
`;

styled(Menu.Item)`
  color: #1a232e;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px;
  ${styledProp(
    '$type',
    css`
      color: #f04438;
    `
  )}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Title = styled.h3`
  color: #7c94b1;
  font-family: Manrope-SemiBold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 22px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.span`
  color: var(--input-input-txt-danger, #f04438);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 12px 12px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.3);
  cursor: pointer;
  position: relative;
  border: 2px solid transparent;
  transition: all 0.3s ease;

  &:hover {
    border: 2px solid #31c447;
  }

  ${styledProp(
    '$active',
    css`
      border: 2px solid #31c447;
    `
  )}
  ${styledProp(
    '$disabled',
    css`
      pointer-events: none;
      border-radius: 10px;
      opacity: 0.4;
      background: #fff;
      color: var(--text-primary-body, #1a232e);
    `
  )}

  ${styledProp(
    '$error',
    css`
      border: 2px solid #f04438;
      &:hover {
        border: 2px solid #f04438;
      }
    `
  )}
`;

export const InfoItem = styled.div`
  display: flex;
  width: 100%;
  padding: 7px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  background: #fffaee;
  box-shadow: 0px 5px 10px 0px rgba(153, 163, 194, 0.3);
  cursor: pointer;
  position: relative;
  border: 1px solid #ffdc7b;
  transition: all 0.3s ease;

  color: var(--text-primary-body, #1a232e);
  text-align: center;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 18px; /* 138.462% */
  ${styledProp(
    '$error',
    css`
      border: 1px solid #c53d36;
      color: var(--buttons-danger-txt, #f04438);
      background: #f7e8ea;
    `
  )}
`;
export const ButtonContainer = styled.div`
  margin-top: 10px;
`;

export const Divider = styled.div`
  height: 1px;
  margin-bottom: 30px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -30px;
    right: -30px;
    height: 1px;
    background: var(--border-divider, #d3dbe7);
  }
`;

export const ItemContentWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  color: var(--text-primary-body, #1a232e);
  font-family: Manrope-Medium;
  font-size: 16px;
  line-height: 21px; /* 131.25% */
`;

export const SubContainer = styled.div``;

export const FixedContainer = styled.div`
  position: sticky;
  bottom: 0;
  background: #f4f5fd;
  padding-bottom: 20px;
  &::before {
    content: '';
    position: absolute;
    right: -30px;
    bottom: 0px;
    top: 1px;
    width: 30px;
    height: 177px;
    background: #f4f5fd;
  }
  &::after {
    content: '';
    position: absolute;
    left: -30px;
    bottom: 0px;
    top: 1px;
    width: 30px;
    height: 177px;
    background: #f4f5fd;
  }
`;
