import { useEffect, useState } from "react";

import { DeprecatedIcon as Icon, ICON_SIZES, DEPRECATED_ICONS_NAMES as ICONS_NAMES, PALETTES, Tooltip } from "@velitech/ui";
import moment from 'moment';

import Timezones from "@constants/timezones";

import { Container, TimerItem } from './styled';

import { useCurrentWorkspace } from "@/hooks";

const TimerDisplay = ({ time, timezone, offset, showIcon }) => (
  <TimerItem>
    <span className="time">
      {time} {offset}
    </span>
    <span className="timezone">
      {timezone}
      {showIcon && (
        <Icon
          name={ICONS_NAMES.Location}
          size={ICON_SIZES.S16}
          color={PALETTES.PRIMARY_500}
        />
      )}
    </span>
  </TimerItem>
);

export const Timer = ({ sidebarOpened }) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const workspace = useCurrentWorkspace();
  const DEFAULT_TIMEZONE = 'UTC';
  const [currentTime, setCurrentTime] = useState(moment().tz(timeZone).format('HH:mm'));
  const [workspaceTime, setWorkspaceTime] = useState(moment().tz(workspace?.timezone || DEFAULT_TIMEZONE)?.format('HH:mm'));

  useEffect(() => {
    const interval = setInterval(() => {
      setWorkspaceTime(moment().tz(workspace?.timezone || DEFAULT_TIMEZONE).format('HH:mm'));
      setCurrentTime(moment().tz(timeZone).format('HH:mm'));
    }, 1000);

    return () => clearInterval(interval);
  }, [workspace?.timezone]);

  const [appOffset, appTz] = (Timezones[workspace?.timezone] || '').split(' ');
  const currentOffset = moment().tz(timeZone).utcOffset();
  const currentOffsetString = String(Math.abs(currentOffset / 60));

  return (
    <Container>
      <TimerDisplay time={currentTime} timezone={timeZone} offset={`(GMT${currentOffset > 0 ? '+' : '-'}${currentOffsetString.length > 1 ? currentOffsetString : `0${currentOffsetString}`}:00)`} showIcon />
      <TimerDisplay time={workspaceTime} timezone={appTz} offset={appOffset} />
    </Container>
  )
}
