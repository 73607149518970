import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { updateOnboarding } from "@store/actions/creators";
import { hintModeSelector, segmentsSelector } from "@store/selectors";

import { useTranslation, useNavigate } from "@hooks";

import { Paths, UserPermissions } from "@constants";

import { formatNumberString, moment } from "@utils";

import { SegmentStatus } from '@components';

import { SegmentName, ActivityRow, DateCell, Container } from "./styled";

import { RioService } from "../../../../../../services";
import { OptionalItem } from "../OptionalItem";
import { RecentActivityTable } from "../RecentActivityTable";

const RecentSegmentsTable = () => {
  const hintMode = useSelector(hintModeSelector);
  const segments = useSelector(segmentsSelector);

  const { p } = useTranslation('dashboard_page');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSegmentsLinkClick = () => {
    navigate(`${Paths.RESOURCES_SEGMENTS}/dynamic`);
  };

  const handleSegmentsCreateClick = (fromLastTemplates) => {
    RioService.track('segments_dash');
    if (hintMode && typeof fromLastTemplates !== 'boolean') {
      navigate(`${Paths.RESOURCES_SEGMENTS}/dynamic/new`);
      dispatch(updateOnboarding('segments', {
        // key: 'segments',
        // stepToGo: 1,
        step: 2,
      }));
    } else {
      navigate(`${Paths.RESOURCES_SEGMENTS}/dynamic/new`);
    }
  }

  const renderSegmentRow = ({ customers, id, name, type, programs, status, updated_at }, index, arr) => {
    return (
      <ActivityRow key={id} bordered={index < arr.length - 1}>
        <div style={{ width: '30%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          <SegmentName to={`${Paths.RESOURCES_SEGMENTS}/${type}/${id}`}>{name}</SegmentName>
        </div>
        <div style={{ width: '20%', textAlign: 'right', paddingRight: '30px' }}>
          {formatNumberString(customers)}
        </div>
        <div style={{ width: '20%', textAlign: 'left' }}>
          <SegmentStatus workflows={programs} status={status}/>
        </div>
        <div>
          <DateCell>{moment(updated_at).format('YYYY-MM-DD')}</DateCell>
        </div>
      </ActivityRow>
    );
  };

  return (
    <Container data-testid="dashboard-recent-segments">
      <OptionalItem
        shouldRender={!!segments.meta?.total}
        tooltip={p('tab_after_segment')}
        fullWidth
        content={(
          <RecentActivityTable
            viewPermission={UserPermissions.REVIEW_SEGMENTS}
            createPermission={UserPermissions.CREATE_SEGMENTS}
            onLinkClick={handleSegmentsLinkClick}
            linkPath={`${Paths.RESOURCES_SEGMENTS}/dynamic`}
            onCreateClick={() => handleSegmentsCreateClick(true)}
            data={segments?.data || []}
            total={!!segments.meta?.total}
            renderItem={renderSegmentRow}
            link={p('all_segments')}
            title={p('last_segments')}
            containerStyle={{ marginLeft: '10px', pointerEvents: !!segments.meta?.total ? 'auto' : 'none' }}
          />
        )}
      />
    </Container>
  )
}

export default React.memo(RecentSegmentsTable);
