import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getWorkflowOptions } from "@store/actions/creators";
import { WORKFLOW_OPTIONS_WITHOUT_SEARCH } from "@store/reducers/workflowOptions";

import { TemplateType } from "@constants/templates";

import { ReducerKeys } from "@/constants";

export enum WorkflowOptionsType {
  EMAIL = TemplateType.EMAIL,
  SMS = TemplateType.SMS,
  WEB_PUSH = TemplateType.WEB_PUSH,
  MOBILE_PUSH = TemplateType.MOBILE_PUSH,
  WEB_POPUP = TemplateType.WEB_POPUP,
  WEBHOOK = TemplateType.WEBHOOK,
  VIBER = TemplateType.VIBER,
  MESSAGE_FEED = TemplateType.MESSAGE_FEED,
  RFM_ANALYTICS = 'rfmResources',
  WORKFLOW = 'workflows'
}

interface WorkflowOptionsLoadOptions {
  perPage?: number;
}

const DEFAULT_PER_PAGE = 20;

const useWorkflowOptions = (type: WorkflowOptionsType, options: WorkflowOptionsLoadOptions = {}) => {
  const dispatch = useDispatch();
  const data = useSelector(store => store[ReducerKeys.WORKFLOW_OPTIONS].options?.[type]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    dispatch(getWorkflowOptions({ entity: type, search: '', page: 1, perPage: options.perPage || DEFAULT_PER_PAGE }));
  }, [type, options.perPage]);

  useEffect(() => {
    if (search.length === 0) {
      return;
    }

    dispatch(getWorkflowOptions({ entity: type, search, page: 1, perPage: options.perPage || DEFAULT_PER_PAGE }));
  }, [search, type, options.perPage]);

  const loadMore = useCallback(() => {
    const page = data?.searches?.[search || WORKFLOW_OPTIONS_WITHOUT_SEARCH]?.meta?.page || 0;

    if (data?.loading?.[search || WORKFLOW_OPTIONS_WITHOUT_SEARCH]) {
      return;
    }

    dispatch(getWorkflowOptions({ entity: type, search, page: page + 1, perPage: options.perPage || DEFAULT_PER_PAGE }));
  }, [search, data?.searches?.[search || WORKFLOW_OPTIONS_WITHOUT_SEARCH]?.meta?.page, type, data?.loading?.[search || WORKFLOW_OPTIONS_WITHOUT_SEARCH]]);

  return {
    data: data?.searches?.[search || WORKFLOW_OPTIONS_WITHOUT_SEARCH]?.data || [],
    loading: data?.loading?.[search || WORKFLOW_OPTIONS_WITHOUT_SEARCH],
    search,
    setSearch,
    loadMore,
  };
};

export default useWorkflowOptions;
