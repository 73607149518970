import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { editRFMAnalytics, getSegmentExampleCount } from '@store/actions/creators';
import { isHardQuerySelector } from "@store/selectors";

import { v4 as uuid } from 'uuid';

import { SegmentsRuleTypes } from '@constants';
import { SegmentNodeTypes } from '@constants/segments-refactored';

import {
  createGroup,
  findRule,
  findRuleGroup,
  flatGroups,
  isRule,
  replaceGroup,
  update,
  by,
  validateSegment,
  getNames, zeroIfNaN, aggregateUsed, generateRandomName, rulesCount
} from '@utils';

import { AddFilterPanel } from '@components/lib/SegmentEditor/components';
import AddFilterButton from '@components/lib/SegmentEditor/components/AddFilterButton/AddFilterButton';

import FilterEditor from './components/FilterEditor/FilterEditor';
import { FiltersDropdown } from './components/FiltersDropdown';
import { FiltersPrecedenceTable } from './components/FiltersPrecedenceTable';
import { LogicOperatorSelect } from './components/LogicOperatorSelect';
import { Container, FiltersContainer, PrecedentTableContainer } from './styled';

const RULE_LIMIT = 10;
const AGGREGATE_LIMIT = 5;
const FUNNEL_LIMIT = 3;
const FUNNEL_STEP_LIMIT = 3;

const SegmentEditor = ({ query, hideScheme, altButton = false, headerHidden, disableAddFilter, onChange, showErrors }) => {
  const dispatch = useDispatch();
  const [selectedType, selectType] = useState('linear');
  const isHardQuery = useSelector(isHardQuerySelector);

  const handleChange = (updater) => {
    onChange(q => validateSegment(flatGroups(flatGroups(update(q, updater)))));
  }

  const handleFunnelsChange = (updater) => {
    onChange(q => validateSegment(({ ...q, funnels: update(q.funnels, updater) })));
  };

  const handleAggregatesChange = (updater) => {
    onChange(q => validateSegment(({ ...q, aggregates: update(q.aggregates, updater) })));
  };

  const handleSelectType = (type) => {
    selectType(type);
  };

  const handleSelectFilter = (item, ruleType) => {
    const newQuery = { ...query, aggregates: query.aggregates || [], funnels: query.funnels || [] };
    const names = getNames(query);

    const newRule = {
      type: SegmentNodeTypes.QUERY_RULE,
      query: {
        ruleType,
        negation: false,
        name: zeroIfNaN(Math.max(...names)) + 1,
        value: {
          type: 'scalar',
          value: '',
        },
        field: (ruleType === SegmentsRuleTypes.EVENT || ruleType === SegmentsRuleTypes.AGGREGATE) ? item?.name : item?.field,
        filters: [],
      },
    };

    if (ruleType === SegmentsRuleTypes.ARRAY || ruleType === SegmentsRuleTypes.EVENT) {

      if (ruleType === SegmentsRuleTypes.ARRAY) {
        newRule.query.type = 'array';
        newRule.query.field = item;
        newRule.query.value.value = [];
      }

      if (ruleType === SegmentsRuleTypes.EVENT) {
        newRule.query.logicalOperator = 'and';
        newRule.query.type = 'common';
        newRule.query.wasPerformed = true;
      }
    }
    if (ruleType === SegmentsRuleTypes.UNSUBSCRIBE_GROUP) {
      newRule.query.logicalOperator = 'and';
      newRule.query.filters = [
        { field: 'subscribed', operator: 'equals', value: { type: 'scalar' } },
        { field: 'subscribe_id', operator: 'equals', value: { type: 'scalar', value: item } },
        { field: 'subscribe_type', operator: 'equals', value: { type: 'scalar', value: 'EMAIL' } },
      ];
    }

    if (ruleType === SegmentsRuleTypes.RFM_SEGMENT) {
      if(item.length > 1) {
        if(newQuery.query.children.length === 0){
          newQuery.query.logicalOperator = 'or';
        }

        newRule.type = SegmentNodeTypes.QUERY_GROUP;
        newRule.query = {
          logicalOperator: "or",
          name: uuid(),
          children: item.map((itemQuery, index) => {
            dispatch(editRFMAnalytics({ id: itemQuery?.analytics }));
            dispatch(getSegmentExampleCount({
              page: 'rfm',
              store: `rfm-${itemQuery?.id}`,
              segmentId: itemQuery?.id,
            }));

            return {
              type: SegmentNodeTypes.QUERY_RULE,
              query: {
                ...newRule.query,
                ...(itemQuery.query?.children?.[0]?.query || {}),
                segment_id: itemQuery?.id,
                analytics: itemQuery?.analytics,
                ruleType,
                name: newRule.query.name + index,
              }
            }
          })
        };
      } else {
        const newItem = item[0];
        newRule.query.segment_id = newItem?.id;
        newRule.query.analytics = newItem?.analytics;
        newRule.query = {
          ...newRule.query,
          ...(newItem.query?.children?.[0]?.query || {}),
          ruleType,
          name: newRule.query.name,
        };
      }
    }

    if (ruleType === SegmentsRuleTypes.AGGREGATE && item) {
      newRule.query.aggregateId = item.id;
      newRule.query.field = item.name;
      newQuery.aggregates = [...(newQuery.aggregates || []), item];
    }
    if (ruleType === SegmentsRuleTypes.AGGREGATE && !item) {
      const id = uuid();
      const label = `Aggregate ${query.aggregates.length + 1}`;
      const name = generateRandomName();

      newRule.query.field = name;
      newRule.query.aggregateId = id;
      newQuery.aggregates = [...(newQuery.aggregates || []), {
        createdId: id,
        label,
        logicalOperator: 'and',
        type: '',
        filters: [],
        name,
        attribute: null,
        aggregate: 'count',
        aggregateMeta: {},
      }];
    }

    newQuery.query.children.push(newRule);
    selectType('linear');

    handleChange(newQuery);
  };

  const handleLogicalOperatorChange = (rule) => (logicalOperator) => {
    const foundRule = findRule(query, rule.query.name);
    const foundGroup = findRuleGroup(query, rule.query.name);
    const rulePosition = foundGroup.query.children.findIndex(({ query }) => query.name === foundRule.query.name);
    const updatedGroup = flatGroups(flatGroups({
      ...foundGroup,
      query: {
        ...foundGroup.query,
        logicalOperator,
        children: [
          createGroup({
            children: foundGroup.query.children.filter((_, index) => index < rulePosition),
            logicalOperator: foundGroup.query.logicalOperator,
          }),
          createGroup({
            children: foundGroup.query.children.filter((_, index) => index >= rulePosition),
            logicalOperator: foundGroup.query.logicalOperator
          }),
        ],
      }
    }));

    handleChange(q => flatGroups(replaceGroup(q, foundGroup.query.name, updatedGroup)));
  };

  const limitReached = rulesCount(query) >= RULE_LIMIT;
  const root = query;
  const renderFilters = (query, onChange) => query?.children?.map((q, index) => {
    const handleChildChange = (updater) => {
      onChange(qq => ({
        ...qq,
        query: {
          ...qq.query,
          children: qq.query.children.map((child) => {
            return child.query.name === q.query.name ? update(child, updater) : child;
          })
        }
      }));
    };

    const handleDeleteChild = () => {
      if (q.query.ruleType === SegmentsRuleTypes.FUNNEL) {
        handleFunnelsChange(fs => fs.filter(f => f.label !== q.query.field));
      }

      if (q.query.ruleType === SegmentsRuleTypes.AGGREGATE) {
        const used = aggregateUsed(q.query.field, root, [q.query.name]);

        if (!used) {
          handleAggregatesChange(as => as.filter(a => a.id ? a.id !== q.query.aggregateId : a.createdId !== q.query.aggregateId));
        }
      }

      onChange(qq => ({
        ...qq,
        query: {
          ...qq.query,
          children: qq.query.children.filter((child) => child.query.name !== q.query.name),
        },
      }));
    };

    const handleDuplicateChild = () => {
      const names = getNames(root);

      const child = query.children.find((child) => child.query.name === q.query.name);
      const updated = { ...child, query: { ...child.query, name: zeroIfNaN(Math.max(...names)) + 1, } };
      if (child.query.ruleType === SegmentsRuleTypes.FUNNEL) {
        const funnel = root.funnels.find(by('label', child.query.field));
        const newFunnel = { ...funnel, label: `funnel_${root.funnels.length + 1}` };
        updated.query.field = `funnel_${root.funnels.length + 1}`;
        handleFunnelsChange(fs => [...fs , newFunnel]);
      }
      if (child.query.ruleType === SegmentsRuleTypes.AGGREGATE) {
        const aggregate = root.aggregates.find(by('name', child.query.field));

        if (aggregate) {
          const id = uuid();
          const newAggregate = { ...aggregate, createdId: id };

          updated.query.aggregateId = id;
          handleAggregatesChange(ags => [...ags, newAggregate]);
        }
      }

      onChange(qq => ({
        ...qq,
        query: {
          ...qq.query,
          children: qq.query.children.flatMap((child) =>
            child.query.name === q.query.name ? [child, updated] : [child]
          ),
        },
      }));
    };

    if (isRule(q)) {
      return (
        <div key={q.query.name}>
          {index !== 0 && <LogicOperatorSelect index={index} operator={query.logicalOperator} onOperatorChange={handleLogicalOperatorChange(q)}/>}
          <FilterEditor
            key={q.query.name}
            funnels={root.funnels || []}
            funnelLimitReached={(root.funnels || []).length >= FUNNEL_LIMIT}
            isHardQuery={isHardQuery}
            funnelStepLimit={FUNNEL_STEP_LIMIT}
            aggregates={root.aggregates}
            addFilter={handleSelectFilter}
            onFunnelsChange={handleFunnelsChange}
            onAggregatesChange={handleAggregatesChange}
            query={q.query}
            limitReached={limitReached}
            aggregateLimitReached={root?.aggregates?.length >= AGGREGATE_LIMIT}
            root={root}
            showErrors={showErrors}
            onDelete={handleDeleteChild}
            onDuplicate={handleDuplicateChild}
            onChange={handleChildChange}
          />
        </div>
      );
    }

    return (
      <div key={q.query.name}>
        {index !== 0 && <LogicOperatorSelect index={index} operator={query.logicalOperator} onOperatorChange={handleLogicalOperatorChange(q)}/>}
        {renderFilters(q.query, handleChildChange)}
      </div>
    );
  });

  return (
    <Container data-testid={'store-segment-editor-container'}>
      <FiltersContainer data-testid={'store-segment-editor-filter-container'}>
        {renderFilters(query.query, handleChange)}
        {query.query.children?.length ? (
          <FiltersDropdown
            onSelect={handleSelectFilter}
            selectedType={selectedType}
            disabled={disableAddFilter || limitReached}
            hiddenTypes={['array']}
            isModal
            aggregateLimitReached={query?.aggregates?.length >= AGGREGATE_LIMIT}
            onTypeSelect={handleSelectType}
            style={{ justifyContent: 'flex-start', flex: 0, flexShrink: 1, alignSelf: 'flex-start' }}
          >
            <AddFilterButton
              style={{ alignSelf: 'flex-start' }}
              filled
              disabled={disableAddFilter}
              isHardQuery={isHardQuery}
              limitReached={limitReached}

            />
          </FiltersDropdown>
        ) : (
          <AddFilterPanel
            onSelect={handleSelectFilter}
            isModal
            disabled={disableAddFilter}
            hiddenTypes={['array']}
            altButton={altButton}
            selectedType={selectedType}
            onTypeSelect={handleSelectType}
          />
        )}
      </FiltersContainer>
      {!hideScheme && (
        <PrecedentTableContainer>
          <FiltersPrecedenceTable
            query={query}
            headerHidden={headerHidden}
            onChange={handleChange}
            onLogicalOperatorChange={handleLogicalOperatorChange}
          />
        </PrecedentTableContainer>
      )}
    </Container>
  );
};

export default SegmentEditor;
