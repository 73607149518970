import { Flow } from "@/constants/flow/nodes";
import { Treemap, TreemapNode } from "@/constants/flow/treemap";

export enum WorkerStatus {
  DRAFT,
  ACTIVE,
  STOPPED,
  FINISHED,
  ON_PAUSE
}

export enum ProgramStatus {
  DESIGN = 'Design',
  ACTIVE = 'Active',
  STOPPED = 'Stop',
  FINISHED = 'Done',
}

export enum DirectionMode {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum WorkerScheduleType {
  ONE_OFF,
  REPEATING,
  REALTIME
}

export interface WorkflowWorker {
  id: string;
  version: string;
  created_at: string | null;
  updated_at: string | null;
  status: WorkerStatus;
  goals: any;
  flow: Flow;
  tree_map: Treemap;
  styles: {
    stage_position: { x: number; y: number };
    scale: number;
    directionMode: DirectionMode;
  },
  participation: any;
  exit_criteria: any;
  total_limit: number | null;
  target_audience: any;
  last_launch_date: string | null;
  next_launch_date: string | null;
  start_date: string | null;
  schedule: {
    type: WorkerScheduleType;
    start_date: string | null;
    end_date: string | null;
    launch_date_if_no_start_date: string | null;
  };
}

export type WorkflowProgramWorkerOption = Pick<WorkflowWorker, "id" | "version" | "created_at" | "updated_at" | "status"> & {
  start_nodes: Array<Omit<TreemapNode, "children">>;
}

export interface WorkflowProgram {
  id: string;
  name: string;
  description: string | null;
  workers?: Array<WorkflowProgramWorkerOption>;
  is_archieved: boolean;
  participation: number;
  status: WorkerStatus;
  type: number;
  tags?: null;
  comments: never;
}

