import { ReducerKeys } from '@constants';

export const rfmAnalyticsSelector = (store) => store[ReducerKeys.RFM_ANALYTICS].rfmAnalytics;

export const rfmAnalyticsLoadingSelector = (store) => store[ReducerKeys.RFM_ANALYTICS].rfmAnalytics.loading;

export const rfmAnalyticsItemsSelector = (store) => store[ReducerKeys.RFM_ANALYTICS].rfmAnalyticsItems;

export const rfmListSelector = (store) => store[ReducerKeys.RFM_ANALYTICS].rfmList?.data;

