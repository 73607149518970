import { useLocation } from 'react-router';

import { useNavigate, useTranslation } from '@hooks';

import { removeLeadingSlash } from '@utils';

import logoutIcon from '@res/images/topbar-logout.svg'
import profileIcon from '@res/images/topbar-user-icon.svg'

import { WithPermissions } from '@components';

import { Col, Link, StyledPopover, Language, OptionsContainer, Item, InfoItem, UserImg, InfoInnerRight, Name, Position, Icon, Title, IconWrapper } from './styled';

const Popover = ({ pages, children, overlayClassName, larger, isSettings, setHovered = () => {}, isLanguage, options, onLanguageChange, language, isUserInfo, firstLetter, name, surname, accountPage, handleLogout, role }) => {
  const { p } = useTranslation('sidebar');
  const location = useLocation();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(`${accountPage?.path}`)
  }

  return (
    <StyledPopover
      onVisibleChange={setHovered}
      overlayClassName={overlayClassName}
      placement="bottom"
      mouseEnterDelay={0.6}
      content={(
        <Col isSettings={isSettings}>
          {isLanguage ? (
            options?.sort(({ value }) => value === language ? 1 : -1).map(({ value, label }, index) => (
              <Language
                isFirst={index === 0}
                isLast={index === options.length - 1}
                data-testid={`topbar-language-select-item-${value}`}
                key={value}
                onClick={() => onLanguageChange(value)}
              >
                {label}
              </Language>
            ))
          ) : isUserInfo ? (
            <OptionsContainer>
              <InfoItem data-testid={'topbar-info-item'} isFirst={true}>
                <UserImg>
                  {firstLetter}
                </UserImg>
                <InfoInnerRight>
                  <Name>{`${name} ${surname}`}</Name>
                  <Position>{role}</Position>
                </InfoInnerRight>
              </InfoItem>
              <Item onClick={handleProfileClick} data-testid={'topbar-profile-item'}>
                <IconWrapper>
                  <Icon src={profileIcon}/>
                </IconWrapper>
                <Title>{accountPage?.name}</Title>
              </Item>
              <Item onClick={handleLogout} data-testid={'topbar-logout-item'}>
                <IconWrapper>
                  <Icon src={logoutIcon}/>
                </IconWrapper>
                <Title>{p('log_out')}</Title>
              </Item>
            </OptionsContainer>
          ) : (
            pages.map(({ name, path, permission }, index) => permission ? (
              <WithPermissions key={path} name={permission}>
                <Link
                  data-testid={path}
                  $active={location.pathname === path}
                  isSettings={isSettings}
                  isFirst={index === 0}
                  isLast={index === pages.length - 1}
                  $larger={larger}
                  $white={overlayClassName === 'stt'}
                  to={removeLeadingSlash(path)}
                >
                  {p(name)}
                </Link>
              </WithPermissions>
            ) : (
              <Link
                data-testid={path}
                $active={location.pathname === path}
                isSettings={isSettings}
                isFirst={index === 0}
                isLast={index === pages.length - 1}
                $larger={larger}
                $white={overlayClassName === 'stt'}
                key={path}
                to={removeLeadingSlash(path)}
              >
                {p(name)}
              </Link>
            ))
          )}
        </Col>
      )}
    >
      {children}
    </StyledPopover>
  );
}

export default Popover;
