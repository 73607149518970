import { moment } from '@utils';

export const getDefaultMonth = (momentWithTimeZone) => {
  return [
    momentWithTimeZone().startOf('month').startOf('day'),
    momentWithTimeZone().endOf('month').endOf('day')
  ];
};

export const calculateRangeForVersion = (worker, momentWithTimeZone) => {
  const moment = momentWithTimeZone;

  if(!worker) {
    return getDefaultMonth(moment).map(d => d.unix() + '')
  }

  const schedule = worker.schedule;

  if(!schedule) {
    return getDefaultMonth(moment).map(d => d.unix() + '')
  }

  if(schedule.type === 1 || schedule.type === 2) {
    const firstStartedDay = moment(schedule.launch_date_if_no_start_date || schedule.start_date).startOf('day');

    return [firstStartedDay.unix(), moment().endOf('day').unix()]
  }

  if(schedule.type === 0) {
    const firstStartedDay = moment(schedule.launch_date_if_no_start_date || schedule.start_date).startOf('day');
    const lastFinishedDay = schedule.end_date ? moment(schedule.end_date) : moment(firstStartedDay).add(1, 'days').startOf('day');
    return [firstStartedDay.unix(), lastFinishedDay.unix()]
  } else {
    return getDefaultMonth(moment).map(d => d.unix() + '')
  }
};

function getLastQuarterRange() {
  const now = moment();
  let year = now.year();
  let currentQuarter = now.quarter();
  let lastQuarter = currentQuarter - 1;

  if (lastQuarter === 0) {
    lastQuarter = 4;
    year -= 1;
  }

  const startOfLastQuarter = moment()
    .year(year)
    .quarter(lastQuarter)
    .startOf('quarter');

  const endOfLastQuarter = moment()
    .year(year)
    .quarter(lastQuarter)
    .endOf('quarter');

  return {
    quarter: lastQuarter,
    year: year,
    start: startOfLastQuarter,
    end: endOfLastQuarter,
    range: [startOfLastQuarter, endOfLastQuarter],
  };
}

export const defaultRanges = {
  'labels.today': [
    moment().startOf('day'),
    moment().endOf('day')
  ],
  'labels.yesterday': [
    moment().startOf('day').add(-1, 'days'),
    moment().endOf('day').add(-1, 'days')
  ],
  'labels.this_week': [
    moment().startOf('week').startOf('day'),
    moment().endOf('week').endOf('day')
  ],
  'labels.last_week': [
    moment().startOf('week').add(-1, 'week'),
    moment().endOf('week').add(-1, 'week')
  ],
  'labels.this_month': [
    moment().startOf('month').startOf('day'),
    moment().endOf('month').endOf('day')
  ],
  'labels.last_month': [
    moment().startOf('month').add(-1, 'month').startOf('day'),
    moment().endOf('month').add(-1, 'month').endOf('day')
  ],
  'labels.this_year': [
    moment().startOf('year').startOf('day'),
    moment().endOf('year').endOf('day')
  ],
  'labels.last_year': [
    moment().startOf('day').startOf('year').add(-1, 'year'),
    moment().endOf('day').endOf('year').add(-1, 'year')
  ],
};

export const dashboardDefaultRanges = {
  'labels.this_week': [
    moment().startOf('week').startOf('day'),
    moment().endOf('week').endOf('day')
  ],
  'labels.last_week': [
    moment().startOf('week').add(-1, 'week'),
    moment().endOf('week').add(-1, 'week')
  ],
  'labels.last_quarter': getLastQuarterRange().range,
  'labels.last_6_months': [
    moment().startOf('day').add(-1, 'week'),
    moment().endOf('day').add(-6, 'months')
  ],
  'labels.this_month': [
    moment().startOf('month').startOf('day'),
    moment().endOf('month').endOf('day')
  ],
  'labels.last_month': [
    moment().startOf('month').add(-1, 'month').startOf('day'),
    moment().endOf('month').add(-1, 'month').endOf('day')
  ],
  'labels.this_year': [
    moment().startOf('year').startOf('day'),
    moment().endOf('year').endOf('day')
  ],
  'labels.last_year': [
    moment().startOf('day').startOf('year').add(-1, 'year'),
    moment().endOf('day').endOf('year').add(-1, 'year')
  ],
};

export const LastThreeMonthDaysRanges = {
  'labels.last_day': [
    moment().subtract(1, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day')
  ],
  'labels.last_7_days': [
    moment().subtract(7, 'days').startOf('day'),
    moment().endOf('day')
  ],
  'labels.last_30_days': [
    moment().subtract(30, 'days').startOf('day'),
    moment().endOf('day')
  ],
  'labels.last_60_days': [
    moment().subtract(60, 'days').startOf('day'),
    moment().endOf('day')
  ],
  'labels.last_90_days': [
    moment().subtract(90, 'days').startOf('day'),
    moment().endOf('day')
  ],
};
