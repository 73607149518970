import { createRequestAction } from "@store/lib/utils/creators";

import omit from 'lodash.omit';
import { all, takeEvery, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from "@constants";

import { mapEntries } from "@utils";

import { TemplatesActionTypes } from '../../actions/types';

function* archiveApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/webhook/archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* loadTemlatesLinksSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_LINKS_TEMPLATE_EMAIL,
      body: {
        links: action.payload.links,
      },
    })
  );
}

function* archiveEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/email/archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* archiveMobilePushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/mobile_push/archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* archiveSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/sms/archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* createViberTemplateSaga(action) {
  const formData = new FormData();
  formData.append('name', action.payload.name);

  formData.append('app_integration_id', action.payload.app_integration_id);

  action.payload.languages.forEach((lagItem, index) => {
    Object.keys(lagItem).forEach(key => {
      if(!lagItem[key]) {
        return;
      }

      if(key === 'is_default') {
        formData.append(`languages[${index}][${key}]`, lagItem[key] ? 1 : 0);
        return;
      }

      if(key === 'image') {
        const fileOrPath = lagItem[key]?.path || lagItem[key]?.file;
        if(!fileOrPath) {
          return;
        }
        formData.append(`languages[${index}][${key}]`, fileOrPath);
        return;
      }

      if(key === 'is_default') {
        formData.append(`languages[${index}][${key}]`, lagItem[key] ? 1 : 0);
        return;
      }
      formData.append(`languages[${index}][${key}]`, lagItem[key]);
    });
  });

  formData.append(`meta[viber_fields][]`,action.payload.meta.viber_fields);

  if (action.payload.links) {
    action.payload.links.forEach((link, index) => {
      Object.keys(link).forEach(key => {
        if(key === 'url') {
          formData.append(`links[${index}][${key}]`, link[key]);
        } else {
          formData.append(`links[${index}][${key}]`, link[key] ? 1 : 0);
        }
      });
    });
  }

  if (action.payload.folder_id) {
    formData.append('folder_id', action.payload.folder_id);
  }

  if (action.payload.id) {
    formData.append('id', action.payload.id);
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_VIBER,
      body: formData,
    })
  );
}

function* createWebpushSaga(action) {
  const formData = new FormData();
  formData.append('name', action.payload.body.name);
  formData.append('ttl', action.payload.body.ttl);
  formData.append('app_integration_id', action.payload.body.app_integration_id);

  if (action.payload.body.folder_id) {
    formData.append('folder_id', action.payload.body.folder_id);
  }

  if (action.payload.body.id) {
    formData.append('id', action.payload.body.id);
  }
  action.payload.body.languages.forEach((lagItem, index) => {
    Object.keys(lagItem).forEach(key => {
      if(key === 'is_default') {
        formData.append(`languages[${index}][${key}]`, lagItem[key] ? 1 : 0);
        return;
      }

      if(!lagItem[key]) {
        return;
      }

      if(key === 'meta') {
        if(lagItem[key].url == '' || !lagItem[key]?.url) {
          formData.append(`languages[${index}][meta]`, '');
        } else {
          formData.append(`languages[${index}][meta][url]`, lagItem[key].url);
        }
        return;
      }

      if(key === 'image' || key === 'icon') {
        const fileOrPath = lagItem[key]?.path || lagItem[key]?.file;
        if(!fileOrPath) {
          return;
        }
        formData.append(`languages[${index}][${key}]`, fileOrPath);
        return;
      }

      if(key === 'actions') {
        const actionsToSave = lagItem[key];
        actionsToSave.forEach((action, subIndex) => {
          Object.keys(action).forEach(key => {
            formData.append(`languages[${index}][actions][${subIndex}][${key}]`, action[key] || '');
          });
        });
        return;
      }
      formData.append(`languages[${index}][${key}]`, lagItem[key]);
    });
  });

  if (action.payload.body.links) {
    action.payload.body.links.forEach((link, index) => {
      Object.keys(link).forEach(key => {
        if(key === 'url') {
          formData.append(`links[${index}][${key}]`, link[key]);
        } else {
          formData.append(`links[${index}][${key}]`, link[key] ? 1 : 0);
        }
      });
    });
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_WEBPUSH,
      body: formData,
    })
  );
}

function* getApiRequestTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_API_REQUEST,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getSmsTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_SMS,
      params: { folder_id: action.payload.folder_id, all: action.payload.all, otp: action.payload.otp },
    })
  );
}

function* getViberSummarySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.VIBER_SUMMARY,
      params: { template_id: [action.payload.id] },
    })
  );
}

function* sendTestSmsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEND_TEST_SMS,
      body: {
        exampleData: action.payload.exampleData,
        message: action.payload.message,
        fake: action.payload.fake,
        language_code: action.payload.language_code,
        app_integration_id: action.payload.app_integration_id,
        phones: action.payload.phones,
        sms_is: action.payload.sms_id,
      },
    })
  );
}

function* sendTestWebPushSaga(action) {
  const formData = new FormData();
  formData.append('title', action.payload.data.title);
  formData.append('body', action.payload.data.body);
  formData.append('url', action.payload.data.url);
  formData.append('publicKey', action.payload.subscription.publicKey);
  formData.append('authToken', action.payload.subscription.authToken);
  formData.append('endpoint', action.payload.subscription.endpoint);
  formData.append('contentEncoding', action.payload.subscription.contentEncoding);

  if (action.payload.data.icon) {
    formData.append('icon', action.payload.data.icon);
  }
  if (action.payload.data.image) {
    formData.append('image', action.payload.data.image);
  }
  if (action.payload.data.actions) {
    formData.append('actions', action.payload.data.actions);
  }
  if (action.payload.data.id) {
    formData.append('id', action.payload.data.id);
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEND_TEST_WEB_PUSH,
      body: formData,
    })
  );
}

function* validateWebpushTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_WEBPUSH_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* validateWebhookTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_WEBHOOK_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* validateSmsTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_SMS_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* validateEmailTemplateNameSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.VALIDATE_EMAIL_TEMPLATE_NAME,
      body: {
        name: action.payload.name,
        id: action.payload.id,
      },
    })
  );
}

function* unarchiveWebpushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/web_push/un_archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveViberTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `${Endpoints.TEMPLATES_VIBER}/un_archive`,
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/sms/un_archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveMobilePushSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/mobile_push/un_archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/email/un_archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/webhook/un_archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* setViberFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/viber/set/folder',
      body: { id: action.payload.id, folder_id: action.payload.folder_id },
    })
  );
}

function* setFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/folder/set',
      body: {
        id: action.payload.id,
        ids: action.payload.ids,
        folder_id: action.payload.folder_id,
        type: action.payload.type
      },
    })
  );
}

function* setEmailFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/email/set/folder',
      body: { id: action.payload.id, folder_id: action.payload.folder_id },
    })
  );
}

function* sendTestEmailSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.SEND_TEST_EMAIL,
      body: {
        app_integration_id: action.payload.app_integration_id,
        subject: action.payload.subject,
        data: action.payload.data,
        emails: action.payload.emails,
        email_id: action.payload.email_id,
        bcc: action.payload.bcc,
        language_code: action.payload.language_code,
        driver: action.payload.driver,
        design: action.payload.design,
        exampleData: action.payload.exampleData,
        fake: action.payload.fake,
      },
    })
  );
}

function* getWebpushTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_WEBPUSH,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getViberTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_VIBER,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getTemplateStatisticsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/notificator/analytics/${action.payload.type}/statuses-summary-count`,
      params: action.payload,
    })
  );
}

function* getSubscriptionGroupsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/subscribe-groups/${action.meta.entity}`
    })
  );
}

function* getSingleViberSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_VIBER(action.payload.id),
    })
  );
}

function* getSingleWebpushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_WEBPUSH_TEMPLATE(action.meta.id),
    })
  );
}

function* getSingleSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_TEMPLATE_SMS(action.meta.id),
    })
  );
}

function* getSingleEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_TEMPLATE_EMAIL(action.payload.id),
    })
  );
}

function* getSingleApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_API_REQUEST_TEMPLATE(action.meta.id),
    })
  );
}

function* getMobilePushTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_MOBILE_PUSH,
      params: { folder_id: action.payload.folder_id, all: action.payload.all },
    })
  );
}

function* getMobilePushSummarySaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.MOBILE_PUSH_SUMMARY,
      params: { mobile_push_id: [action.payload.id] },
    })
  );
}

function* getFoldersSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/folder',
      params: { type: action.payload.type },
    })
  );
}

function* getFoldersListSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: '/api/folder',
      params: {
        type: action.payload.type,
        order_col: 'created_at',
        order_dir: 'desc',
        page: action.payload.page,
        per_page: action.payload.per_page,
        name: action.payload.search,
      },
    })
  );
}

function* getEmailTemplatesSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.TEMPLATES_EMAIL,
      params: { folder_id: action.payload.folder_id, all: action.payload.all, otp: action.payload.otp },
    })
  );
}

function* getEmailSampleDataSaga(action) {
  const url = action.payload.type === 'email' ? `${Endpoints.GET_EMAIL_SAMPLE_DATA}/?type=email` : Endpoints.GET_EMAIL_SAMPLE_DATA;

  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: url,
    })
  );
}

function* getTemplatePreviewInfoSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_RENDER,
      body: {
        templates: mapEntries({
          ...action.payload.fields
        }, ([, v]) => v !== null, 'filter'),
        customer_id: action.payload.customer_id,
      },
    })
  );
}

function* editSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_SMS,
      body: {
        id: action.payload.id,
        name: action.payload.name,
        languages: action.payload.languages,
        meta: action.payload.meta,
        links: action.payload.links,
        otp: action.payload?.otp || false,
        otp_type: action.payload?.otp_type,
        folder_id: action.payload.folder_id,
        app_integration_id: action.payload.app_integration_id,
      },
    })
  );
}

function* editEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_EMAIL,
      body: {
        id: action.payload.id,
        name: action.payload.name,
        app_integration_id: action.payload.app_integration_id,
        bcc: action.payload.bcc,
        languages: action.payload.languages,
        driver: action.payload.driver,
        otp: action.payload?.otp || false,
        otp_type: action.payload?.otp && action.payload?.otp_type,
        links: action.payload.links,
        meta: action.payload.meta,
        folder_id: action.payload.folder_id,
        group_id: action.payload.group_id,
      },
    })
  );
}

function* deleteWebpushSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.TEMPLATES_WEBPUSH}/delete`,
      body: { ids: action.meta.ids },
    })
  );
}

function* deleteViberTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_VIBER,
      body: {
        ids: action.payload.ids,
      },
    })
  );
}

function* deleteSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_TEMPLATE_SMS,
      body: {
        ids: action.payload.ids,
      },
    })
  );
}

function* deleteMobilePushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: Endpoints.DELETE_MOBILE_PUSH,
      body: { ids: action.payload.ids },
    })
  );
}

function* deleteFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: '/api/folder/delete',
      body: { id: action.payload.id, type: action.payload.type },
    })
  );
}

function* deleteEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.DELETE_TEMPLATE_EMAIL}/delete`,
      body: { ids: action.payload.ids },
    })
  );
}

function* deleteApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `${Endpoints.TEMPLATES_API_REQUEST}/delete`,
      body: { ids: action.meta.ids },
    })
  );
}

function* createSmsTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_SMS,
      body: {
        name: action.payload.name,
        meta: action.payload.meta,
        languages: action.payload.languages,
        links: action.payload.links,
        otp: action.payload?.otp || false,
        otp_type: action.payload?.otp_type,
        folder_id: action.payload.folder_id,
        app_integration_id: action.payload.app_integration_id,
      },
    })
  );
}

function* createMobilePushSaga(action) {
  const formData = new FormData();
  formData.append('name', action.payload.body.name);
  formData.append('meta[use_external_id]', action.payload.body.meta.use_external_id ? 1 : 0);
  if (action.payload.body.meta.mobile_push_fields) {
    for (let i = 0; i < action.payload.body.meta.mobile_push_fields.length; i++) {
      formData.append('meta[mobile_push_fields][]', action.payload.body.meta.mobile_push_fields[i]);
    }
  }

  formData.append('app_integration_id', action.payload.body.app_integration_id);

  if (action.payload.body.folder_id) {
    formData.append('folder_id', action.payload.body.folder_id);
  }

  if (action.payload.body.id) {
    formData.append('id', action.payload.body.id);
  }

  action.payload.body.languages.forEach((lagItem, index) => {
    Object.keys(lagItem).forEach(key => {
      const skippedKey = ['custom_data' || 'code'].includes(key);
      if(skippedKey) {
        return;
      }

      if(key === 'is_default') {
        formData.append(`languages[${index}][${key}]`, lagItem[key] ? 1 : 0);
        return;
      }

      if(key === 'meta') {
        if(!lagItem['meta']['code']) {
          formData.append(`languages[${index}][meta][]`, {});
          return;
        }
        const custom_data = JSON.parse(lagItem['meta']['code']); //read from code key
        Object.keys(custom_data).forEach((customDataKey) => {
          if(!custom_data[customDataKey]) {
            return;
          }
          formData.append(`languages[${index}][meta][custom_data][${customDataKey}]`, custom_data[customDataKey]);
        });

        return;
      }

      if(!lagItem[key]) {
        return;
      }

      if(key === 'actions') {
        lagItem[key].forEach((action, buttonIndex) => {
          Object.keys(action).forEach((actionKey) => {
            formData.append(`languages[${index}][actions][${buttonIndex}][${actionKey}]`, action[actionKey]);
          });
        });
        return;
      }
      if(key === 'image') {
        const fileOrPath = lagItem[key]?.path || lagItem[key]?.file;
        fileOrPath && formData.append(`languages[${index}][${key}]`, fileOrPath);
        return;
      }
      formData.append(`languages[${index}][${key}]`, lagItem[key]);
    });
  });

  if (action.payload.body.links) {
    action.payload.body.links.forEach((link, index) => {
      Object.keys(link).forEach(key => {
        if(key === 'url') {
          formData.append(`links[${index}][${key}]`, link[key]);
        } else {
          formData.append(`links[${index}][${key}]`, link[key] ? 1 : 0);
        }
      });
    });
  }

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_MOBILE_PUSH,
      body: formData,
    })
  );
}

function* createFolderSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/folder',
      body: {
        id: action.payload.id,
        name: action.payload.name,
        type: action.payload.type
      },
    })
  );
}

function* createEmailTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.CREATE_TEMPLATE_EMAIL,
      body: {
        name: action.payload.name,
        app_integration_id: action.payload.app_integration_id,
        bcc: action.payload.bcc || [],
        languages: action.payload.languages,
        meta: action.payload.meta,
        otp: action.payload?.otp || false,
        otp_type: action.payload?.otp && action.payload?.otp_type,
        driver: action.payload.driver,
        links: action.payload.links,
        folder_id: action.payload.folder_id,
        email_fields: action.payload.email_fields,
        group_id: action.payload.group_id
      },
    })
  );
}

function* createApiRequestTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: Endpoints.TEMPLATES_API_REQUEST,
      body: action.payload.body,
    })
  );
}

function* archiveWebpushTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: '/api/web_push/archive',
      body: { ids: action.payload.ids },
    })
  );
}

function* getSingleMobilePushSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: Endpoints.SINGLE_MOBILE_PUSH(action.meta.id),
    })
  );
}

function* archiveViberTemplateSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `${Endpoints.TEMPLATES_VIBER}/archive`,
      body: { ids: action.payload.ids },
    })
  );
}

function* getContentCardsSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/message_feed`,
    })
  );
}

function* getWebpopupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/web_popup`,
    })
  );
}

function* getContentCardSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/message_feed/${action.payload.id}/edit`,
    })
  );
}

function* saveContentCardSaga(action) {
  const fd = new FormData();

  Object.entries(omit(action.payload, [
    'onSuccess',
    'onError',
    'updated_at',
    'created_at',
    'user_id',
    'workers',
    'status',
    'languages'
  ])).forEach(([k, v]) => {
    if (v !== null && v !== '' && v !== undefined) {
      fd.append(k, v);
    }
  });

  action.payload.languages.forEach((lagItem, index) => {
    Object.keys(lagItem).forEach(key => {
      if(key === 'buttons') {
        const emptyButtons = lagItem[key]?.every((button) => !button.name);

        if(lagItem[key] === null || emptyButtons) {
          fd.append(`languages[${index}][buttons]`, []);
          return;
        }
        const buttons = lagItem[key].filter((button) => button.name);

        buttons.forEach((button, subIndex) => {
          Object.keys(button).forEach(buttonKey => {
            fd.append(`languages[${index}][buttons][${subIndex}][${buttonKey}]`, button[buttonKey] || '');
          });
        });
        return;
      }

      if (lagItem[key] === null || lagItem[key] === undefined) {
        return;
      }

      if(key === 'is_default') {
        fd.append(`languages[${index}][${key}]`, lagItem[key] ? 1 : 0);
        return;
      }

      if(key === 'image') {
        const fileOrPath = lagItem[key]?.path || (lagItem[key]?.name && lagItem[key]);
        if(!fileOrPath) {
          return;
        }
        fd.append(`languages[${index}][${key}]`, fileOrPath);
        return;
      }

      fd.append(`languages[${index}][${key}]`, lagItem[key]);
    });
  });
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/message_feed/`,
      body: fd,
    })
  );
}

function* archiveContentCardSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/message_feed/archive`,
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveContentCardSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/message_feed/un_archive`,
      body: { ids: action.payload.ids },
    })
  );
}

function* deleteContentCardSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `/api/message_feed/delete`,
      body: { ids: action.payload.ids },
    })
  );
}

function* saveWebPopupSaga(action) {
  const fd = new FormData();

  Object.entries(omit(action.payload, [
    'onSuccess',
    'onError',
    'updated_at',
    'created_at',
    'user_id',
    'workers',
    'status',
    'used',
  ])).forEach(([key, value]) => {
    if(key === 'excluded_pages') {
      value?.forEach((excludePage, index) => {
        fd.append(`excluded_pages[${index}]`, excludePage);
      });
      return;
    }

    if(key === 'languages') {
      const keyWithPossibleNull = ['title', 'message', 'layout', 'buttons', 'redirect_url', 'redirect_image_url', 'code'];
      value.forEach((lagItem, index) => {
        Object.keys(lagItem).forEach(langKey => {

          if(langKey === 'is_default') {
            fd.append(`languages[${index}][${langKey}]`, lagItem[langKey] ? 1 : 0);
            return;
          }

          if(!lagItem[langKey] && !keyWithPossibleNull.includes(langKey)) {
            return;
          }

          if(langKey === 'image') {
            const isFile = lagItem[langKey].name && lagItem[langKey];
            const fileOrPath = lagItem[langKey]?.path || isFile;
            if(!fileOrPath) {
              return;
            }
            fd.append(`languages[${index}][${langKey}]`, fileOrPath);
            return;
          }

          if(langKey === 'buttons') {
            const emptyButtons = lagItem[langKey]?.every((button) => !button.name)
            if(lagItem[langKey] === null || emptyButtons) {
              fd.append(`languages[${index}][buttons]`, []);
              return;
            }

            const buttons = lagItem[langKey].filter((button) => button.name);
            buttons.forEach((button, subIndex) => {
              Object.keys(button).forEach(buttonKey => {

                fd.append(`languages[${index}][buttons][${subIndex}][${buttonKey}]`, button[buttonKey] || '');
              });
            });
            return;
          }
          fd.append(`languages[${index}][${langKey}]`, lagItem[langKey]);
        });
      });
      return;
    }

    fd.append(key, value);
    return
  });

  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/web_popup/`,
      body: fd,
    })
  );
}

function* getSingleWebpopupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'get',
      endpoint: `/api/web_popup/${action.payload.id}/edit`,
    })
  );
}

function* archiveWebpopupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/web_popup/archive`,
      body: { ids: action.payload.ids },
    })
  );
}

function* unarchiveWebpopupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'post',
      endpoint: `/api/web_popup/un_archive`,
      body: { ids: action.payload.ids },
    })
  );
}

function* deleteWebpopupSaga(action) {
  yield put(
    createRequestAction(action, {
      method: 'delete',
      endpoint: `/api/web_popup/delete`,
      body: { ids: action.payload.ids },
    })
  );
}

function* getSimpleTemplates(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/${action.payload.entity}`,
    params: {
      name: action.payload.search,
      folder_id: action.payload.folder_id,
      page: action.payload.page,
      per_page: action.payload.perPage,
    },
  }));
}

function* getTemplateAnalyticSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/notificator/analytics/${action.payload.type}/statuses-count-per-customer/day`,
    params: {
      start_date: action.payload.start_date,
      end_date: action.payload.end_date,
      template_id: action.payload.template_id,
    },
  }));
}

function* getTemplateConversionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/seg/analytics/otp/${action.payload.type}/funnel/daily`,
    params: {
      event: 'profile_verification',
      template_id: action.payload.template_id,
      start_date: action.payload.start_date,
      end_date: action.payload.end_date,

    },
  }));
}

function* getTemplateLoginConversionSaga(action) {
  yield put(createRequestAction(action, {
    method: 'get',
    endpoint: `/api/seg/analytics/otp/${action.meta.type}/funnel/daily`,
    params: {
      event: 'login',
      template_id: action.payload.template_id,
      start_date: action.payload.start_date,
      end_date: action.payload.end_date,
    },
  }));
}
export default function* templatesRootSaga() {
  yield all([
    takeLatest(TemplatesActionTypes.GET_SMS, getSmsTemplatesSaga),
    takeLatest(TemplatesActionTypes.CREATE_SMS, createSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_WEBPUSH, getWebpushTemplatesSaga),
    takeLatest(TemplatesActionTypes.EDIT_SMS, editSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.DELETE_SMS, deleteSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_SMS, getSingleSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_EMAILS, getEmailTemplatesSaga),
    takeLatest(TemplatesActionTypes.CREATE_EMAIL, createEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_EMAIL_SAMPLE_DATA, getEmailSampleDataSaga),
    takeLatest(TemplatesActionTypes.DELETE_EMAIL, deleteEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_EMAIL, getSingleEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.EDIT_EMAIL, editEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_WEBPUSH, getSingleWebpushTemplateSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_EMAIL_TEMPLATE_NAME, validateEmailTemplateNameSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_SMS_TEMPLATE_NAME, validateSmsTemplateNameSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_WEBPUSH_TEMPLATE_NAME, validateWebpushTemplateNameSaga),
    takeLatest(TemplatesActionTypes.VALIDATE_WEBHOOK_TEMPLATE_NAME, validateWebhookTemplateNameSaga),
    takeLatest(TemplatesActionTypes.CREATE_WEBPUSH, createWebpushSaga),
    takeLatest(TemplatesActionTypes.DELETE_WEBPUSH, deleteWebpushSaga),
    takeLatest(TemplatesActionTypes.SEND_TEST_SMS, sendTestSmsSaga),
    takeLatest(TemplatesActionTypes.SEND_TEST_EMAIL, sendTestEmailSaga),
    takeLatest(TemplatesActionTypes.GET_API_REQUEST, getApiRequestTemplatesSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_API_REQUEST, getSingleApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.CREATE_API_REQUEST, createApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.DELETE_API_REQUEST, deleteApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_TEMPLATE_PREVIEW_INFO, getTemplatePreviewInfoSaga),
    takeLatest(TemplatesActionTypes.SEND_TEST_WEB_PUSH, sendTestWebPushSaga),
    takeLatest(TemplatesActionTypes.GET_SUBSCRIPTION_GROUPS, getSubscriptionGroupsSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_EMAIL_TEMPLATE, archiveEmailTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_SMS_TEMPLATE, archiveSmsTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_WEBPUSH_TEMPLATE, archiveWebpushTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_WEBHOOK_TEMPLATE, archiveApiRequestTemplateSaga),
    takeEvery(TemplatesActionTypes.UNARCHIVE_EMAIL_TEMPLATE, unarchiveEmailTemplateSaga),
    takeEvery(TemplatesActionTypes.UNARCHIVE_SMS_TEMPLATE, unarchiveSmsTemplateSaga),
    takeEvery(TemplatesActionTypes.UNARCHIVE_WEBPUSH_TEMPLATE, unarchiveWebpushTemplateSaga),
    takeLatest(TemplatesActionTypes.UNARCHIVE_WEBHOOK_TEMPLATE, unarchiveApiRequestTemplateSaga),
    takeLatest(TemplatesActionTypes.UNARCHIVE_VIBER_TEMPLATE, unarchiveViberTemplateSaga),
    takeLatest(TemplatesActionTypes.DELETE_VIBER, deleteViberTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_VIBER_TEMPLATE, archiveViberTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_VIBERS, getViberTemplatesSaga),
    takeLatest(TemplatesActionTypes.GET_VIBER_SUMMARY, getViberSummarySaga),
    takeLatest(TemplatesActionTypes.SET_VIBER_FOLDER, setViberFolderSaga),
    takeLatest(TemplatesActionTypes.SET_FOLDER, setFolderSaga),
    takeLatest(TemplatesActionTypes.SET_SMS_FOLDER, setViberFolderSaga),
    takeLatest(TemplatesActionTypes.CREATE_VIBER, createViberTemplateSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_VIBER, getSingleViberSaga),
    takeLatest(TemplatesActionTypes.GET_MOBILE_PUSH_SUMMARY, getMobilePushSummarySaga),
    takeLatest(TemplatesActionTypes.CREATE_FOLDER, createFolderSaga),
    takeLatest(TemplatesActionTypes.GET_MOBILE_PUSH, getMobilePushTemplatesSaga),
    takeLatest(TemplatesActionTypes.DELETE_FOLDER, deleteFolderSaga),
    takeLatest(TemplatesActionTypes.LOAD_TEMPLATES_LINK, loadTemlatesLinksSaga),
    takeLatest(TemplatesActionTypes.SET_EMAIL_FOLDER, setEmailFolderSaga),
    takeLatest(TemplatesActionTypes.CREATE_MOBILE_PUSH, createMobilePushSaga),
    takeLatest(TemplatesActionTypes.GET_FOLDERS, getFoldersSaga),
    takeLatest(TemplatesActionTypes.DELETE_MOBILE_PUSH, deleteMobilePushTemplateSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_MOBILE_PUSH_TEMPLATE, archiveMobilePushTemplateSaga),
    takeLatest(TemplatesActionTypes.UNARCHIVE_MOBILE_PUSH_TEMPLATE, unarchiveMobilePushSaga),
    takeLatest(TemplatesActionTypes.GET_SINGLE_MOBILE_PUSH, getSingleMobilePushSaga),
    takeLatest(TemplatesActionTypes.GET_TEMPLATE_STATISTICS, getTemplateStatisticsSaga),
    takeLatest(TemplatesActionTypes.GET_CONTENT_CARD, getContentCardSaga),
    takeLatest(TemplatesActionTypes.GET_CONTENT_CARDS, getContentCardsSaga),
    takeLatest(TemplatesActionTypes.SAVE_CONTENT_CARD, saveContentCardSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_CONTENT_CARD, archiveContentCardSaga),
    takeLatest(TemplatesActionTypes.DELETE_CONTENT_CARD, deleteContentCardSaga),
    takeLatest(TemplatesActionTypes.UN_ARCHIVE_CONTENT_CARD, unarchiveContentCardSaga),
    takeLatest(TemplatesActionTypes.GET_FOLDERS_LIST, getFoldersListSaga),
    takeLatest(TemplatesActionTypes.GET_WEB_POPUPS, getWebpopupSaga),
    takeLatest(TemplatesActionTypes.SAVE_WEB_POPUP, saveWebPopupSaga),
    takeLatest(TemplatesActionTypes.GET_WEB_POPUP, getSingleWebpopupSaga),
    takeLatest(TemplatesActionTypes.ARCHIVE_WEB_POPUP, archiveWebpopupSaga),
    takeLatest(TemplatesActionTypes.UN_ARCHIVE_WEB_POPUP, unarchiveWebpopupSaga),
    takeLatest(TemplatesActionTypes.DELETE_WEB_POPUP, deleteWebpopupSaga),
    takeLatest(TemplatesActionTypes.GET_SIMPLE, getSimpleTemplates),
    takeLatest(TemplatesActionTypes.GET_ANALYTICS_TREND, getTemplateAnalyticSaga),
    takeLatest(TemplatesActionTypes.GET_ANALYTICS_CONVERSION, getTemplateConversionSaga),
    takeLatest(TemplatesActionTypes.GET_ANALYTICS_LOGIN_CONVERSION, getTemplateLoginConversionSaga)
  ]);
}
