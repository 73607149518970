import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  font-family: Manrope-SemiBold;
  font-size: 18px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const Name = styled.div`
  font-size: 18px;
  color: #303133;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  width: 83px;
  border-radius: 7px;
  //border: 1px solid #DCDFE6;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? '335px' : '100%')};
  height: 438px;
`;

export const PreviewIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`;

export const IconContainer = styled.div`
  border-radius: ${({ isFirst }) => (isFirst ? '7px 0px 0px 7px' : '0px 7px 7px 0px')};
  border: 1px solid #DCDFE6;
  padding: 8px 10px 8px 10px;
  cursor: pointer;
   background: ${({ isActive }) => (isActive ? '#D3DBE7' : '#FFFFFF')};
`;
