import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Device } from '@res';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(251.17deg, #E6F8FC 2.11%, #F8FEFF 90.83%);
`;

export const InnerContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #F0F2F6;
  box-shadow: 8px 20px 30px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  padding: 36px 20px 20px 20px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  @media screen and ${Device.desktop} {
    width: 194px;
    height: 60px;
    margin-bottom: 110px;
    margin-top: 120px;
  }

  width: 194px;
  height: 60px;
  margin-bottom: 50px;
  margin-top: 50px;

  @media screen and (max-width: 769px) {
    width: 194px;
    height: 60px;
    margin-bottom: 110px;
  }

  @media screen and (max-width: 500px) {
    width: 124px;
    height: 38px;
    margin-bottom: 40px;
    margin-top: 50px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  line-height: 130%;
  color: #FFFFFF;
  padding-left: 5px;
  
  ${styledProp('valid', css`
    color: #909399;
  `)}
`;

export const Title = styled.h2`
  margin-bottom: 30px;
`;

