import styled from 'styled-components';

export const ActionsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex: 1;
`;

export const Title = styled.h4`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-bottom: 18px;

`

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const Listcontainer = styled.div`
  background-color: #fff;
  padding: 6px 0;
  gap: 8px;
  border: 1px solid #DCDFE6;
  border-radius: 7px;

  .search {
    min-height: 20px;
  }
`
export const Container = styled.div`
  padding-bottom: 32px;
 
`
export const ContryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;

`
export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const CountryIcon = styled.span`
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  max-height: 24px;
`

export const CountryName = styled.p`
  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #1A232E;
  `

export const SelectedValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  gap: 8px;

  font-family: Manrope-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`
