import { SegmentsActionTypes, UserActionTypes, WorkspacesActionTypes } from '../actions/types';

const initialState = {
  segments: {},
  editMetadata: {},
  loading: false,
  isHardQuery: false,
  options: {
    loading: false,
    fields: {},
  },
  aggregates: {
    loading: false,
  },
  singleAggregate: {
    loading: false,
  },
  contactsExample: {
    loading: false,
  },
  filterResults: {
    loading: false,
  },
  nestedAggregates: {
    loading: false,
  },
  csvFiles: {

  },
};

const segmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SegmentsActionTypes.UPLOAD_FILE:
      return {
        ...state,
        csvFiles: {
          [action.meta.id]: {
            ...(state.csvFiles[action.meta.id] || {}),
            loading: true,
          },
        },
      };
    case SegmentsActionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        csvFiles: {
          [action.meta.id]: {
            ...(state.csvFiles[action.meta.id] || {}),
            loading: false,
            ...action.payload,
          },
        },
      };
    case SegmentsActionTypes.UPLOAD_FILE_ERROR:
    case SegmentsActionTypes.UPLOAD_FILE_CANCEL:
      return {
        ...state,
        csvFiles: {
          [action.meta.id]: {
            ...(state.csvFiles[action.meta.id] || {}),
            loading: false,
          },
        },
      };
    case SegmentsActionTypes.LIST_NESTED_AGGREGATES:
      return {
        ...state,
        nestedAggregates: {
          ...state.nestedAggregates,
          loading: true,
        },
      };
    case SegmentsActionTypes.LIST_NESTED_AGGREGATES_SUCCESS:
      return {
        ...state,
        nestedAggregates: {
          ...state.nestedAggregates,
          ...action.payload,
          loading: false,
        },
      };
    case SegmentsActionTypes.LIST_NESTED_AGGREGATES_ERROR:
      return {
        ...state,
        nestedAggregates: {
          ...state.nestedAggregates,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET:
      return {
        ...state,
        loading: true,
      };
    case SegmentsActionTypes.GET_SUCCESS:
      return {
        ...state,
        segments: action.payload,
        loading: false,
      };
    case SegmentsActionTypes.GET_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SegmentsActionTypes.GET_SINGLE_AGGREGATE:
      return {
        ...state,
        singleAggregate: {
          ...state.singleAggregate,
          loading: true,
        },
      };
    case SegmentsActionTypes.GET_SINGLE_AGGREGATE_SUCCESS:
      return {
        ...state,
        singleAggregate: {
          data: {
            ...action.payload,
          },
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_SINGLE_AGGREGATE_ERROR:
      return {
        ...state,
        singleAggregate: {
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_AGGREGATE:
      return {
        ...state,
        aggregates: {
          ...state.aggregates,
          loading: true,
        },
      };
    case SegmentsActionTypes.GET_AGGREGATE_ERROR:
      return {
        ...state,
        aggregates: {
          ...state.aggregates,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_AGGREGATE_SUCCESS:
      return {
        ...state,
        aggregates: {
          ...action.payload,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_OPTIONS:
      return {
        ...state,
        options: {
          ...initialState.options,
          loading: true,
        }
      };
    case SegmentsActionTypes.GET_OPTIONS_SUCCESS:
      if (action.meta?.part === 'aggregate') {
        return {
          ...state,
          options: {
            ...state.options,
            aggregates: action.payload?.data,
            loading: false,
          },
        }
      }
      if (action.meta?.part === 'workflow') {
        return {
          ...state,
          options: {
            ...state.options,
            fields: {
              ...state.options.fields,
              attributes: action.payload?.data,
            },
            loading: false,
          },
        };
      }
      if (action.meta?.part === 'app') {
        return {
          ...state,
          options: {
            ...state.options,
            fields: {
              ...state.options.fields,
              ...(action.payload?.fields || {})
            },
            loading: false,
          },
        }
      }

      if (action.meta?.part === 'events') {
        return {
          ...state,
          options: {
            ...state.options,
            fields: {
              ...state.options.fields,
              events: action.payload.data,
            },
            loading: false,
          },
        }
      }

      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_OPTIONS_ERROR:
      return {
        ...state,
        options: {
          ...initialState.options,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_EDIT_METADATA:
      return {
        ...state,
        editMetadata: {
          ...state.editMetadata,
          loading: true,
        },
      };
    case SegmentsActionTypes.GET_EDIT_METADATA_SUCCESS:
      return {
        ...state,
        editMetadata: {
          ...action.payload,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_EDIT_METADATA_ERROR:
      return {
        ...state,
        editMetadata: {
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_FILTER_RESULTS:
      return {
        ...state,
        filterResults: {
          ...state.filterResults,
          loading: true,
        },
      };
    case SegmentsActionTypes.GET_FILTER_RESULTS_SUCCESS:
      return {
        ...state,
        filterResults: {
          ...action.payload,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_FILTER_RESULTS_ERROR:
      return {
        ...state,
        filterResults: {
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_CONTACTS_EXAMPLE:
    case SegmentsActionTypes.CLEAR_FILTER_RESULTS:
      return {
        ...state,
        contactsExample: {
          loading: true,
        },
      };
    case SegmentsActionTypes.GET_CONTACTS_EXAMPLE_SUCCESS:
      return {
        ...state,
        contactsExample: {
          data: action.payload,
          loading: false,
        },
      };
    case SegmentsActionTypes.GET_CONTACTS_EXAMPLE_ERROR:
      return {
        ...state,
        contactsExample: {
          loading: false,
        },
      };
    case SegmentsActionTypes.SAVE_NEW_SEGMENT_PRESET:
      return {
        ...state,
        newPreset: action.payload.segment,
      };
    case SegmentsActionTypes.CLEAR_NEW_SEGMENT_PRESET:
      return {
        ...state,
        newPreset: null,
      };
    case UserActionTypes.LOGOUT:
    case WorkspacesActionTypes.SWITCH_SUCCESS:
    case WorkspacesActionTypes.SWITCH_TO_DEMO_SUCCESS:
    case WorkspacesActionTypes.SAVE_SUCCESS:
      return {
        ...initialState,
      }
    case SegmentsActionTypes.SET_IS_HARD_QUERY:
      return {
        ...state,
        isHardQuery: action.payload
      }
    default:
      return state;
  }
};

export default segmentsReducer;
