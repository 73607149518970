import styled from 'styled-components';

import { styledProp } from '@utils';

import * as Colors from '@res/theme/colors';

export const Container = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  position: relative;
  outline: none;
  border-bottom: none;
  text-decoration: none;
`;

export const Item = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${styledProp('isLanguage', `
    color: #5F728A !important;
    font-family: Manrope-SemiBold !important;
    font-size: 14px !important;
  `)}
  
  &:hover{
    background-color: #7C94B11F;
    cursor: pointer;
  }
`;

export const Img = styled.img`
  
`;

