import { ReducerKeys } from '@constants';

export const segmentsSelector = store => store[ReducerKeys.SEGMENTS].segments;

export const segmentsLoadingSelector = store => store[ReducerKeys.SEGMENTS].loading;

//DEPRICATED:START use options reducer instead
export const segmentsOptionsSelector = store => store[ReducerKeys.SEGMENTS].options;

//DEPRICATED:END use options reducer instead

export const segmentEditMetadataSelector = store => store[ReducerKeys.SEGMENTS].editMetadata;

export const segmentContactsExampleSelector = store => store[ReducerKeys.SEGMENTS].contactsExample;

export const segmentContactsExampleLoadingSelector = store => store[ReducerKeys.SEGMENTS].contactsExample.loading;

export const singleAggregateSelector = store => store[ReducerKeys.SEGMENTS].singleAggregate?.data;

export const aggregatesSelector = store => store[ReducerKeys.SEGMENTS]?.aggregates;

export const newSegmentPresetSelector = store => store[ReducerKeys.SEGMENTS].newPreset;

export const nestedAggregatesListSelector = store => store[ReducerKeys.SEGMENTS].nestedAggregates;

export const segmentCSVFilesSelector = store => store[ReducerKeys.SEGMENTS].csvFiles;

export const isHardQuerySelector = store => store[ReducerKeys.SEGMENTS].isHardQuery;
