import { useEffect, useMemo } from 'react';

import get from 'lodash.get';

import useWorkflowOptions, { WorkflowOptionsType } from '@hooks/useWorkflowOptions';

import { identity } from '@utils';

const resolvePath = (event, fields, nestedField) => {
  if (event) {
    return [nestedField, ...fields].filter(identity);
  }

  return [...fields, nestedField].filter(identity);
};

const resolveFilter = (event) => {
  if (!event) {
    return void 0;
  }

  return { 'event': event };
};

const createPath = (path, filter) => {
  if (!filter) {
    return path.join('.');
  }

  return Object.entries(filter).reduce((acc, [k, v]) => `${acc}.${k}.${v}`, path.join('.'));
}

const useWorkflowOptionsAutocomplete = ({ event, fields = [], nestedField, query }) => {
  const path = resolvePath(event, fields, nestedField);
  const filter = resolveFilter(event, fields, nestedField);
  const mapPath = createPath(path, filter);

  const optionsTypeMap = useMemo(() => ({
    'nf_notification_id': {
      'event': {
        'email': WorkflowOptionsType.EMAIL,
        'sms': WorkflowOptionsType.SMS,
        'web_push': WorkflowOptionsType.WEB_PUSH,
        'viber': WorkflowOptionsType.VIBER,
        'mobile_push': WorkflowOptionsType.MOBILE_PUSH,
        'content_card': WorkflowOptionsType.MESSAGE_FEED,
        'web_popup': WorkflowOptionsType.WEB_POPUP,
      }
    },
    'nf_entity_id': {
      'event': {
        'email': WorkflowOptionsType.WORKFLOW,
        'sms': WorkflowOptionsType.WORKFLOW,
        'web_push': WorkflowOptionsType.WORKFLOW,
        'viber': WorkflowOptionsType.WORKFLOW,
        'mobile_push': WorkflowOptionsType.WORKFLOW,
        'content_card': WorkflowOptionsType.WORKFLOW,
      }
    },
    'nf_sub_entity_id': {
      'event': {
        'email': WorkflowOptionsType.WORKFLOW,
        'sms': WorkflowOptionsType.WORKFLOW,
        'web_push': WorkflowOptionsType.WORKFLOW,
        'viber': WorkflowOptionsType.WORKFLOW,
        'mobile_push': WorkflowOptionsType.WORKFLOW,
        'content_card': WorkflowOptionsType.WORKFLOW,
      }
    },
    'rf_rfm_id': {
      'event': {
        'rfm_segment_drifting': WorkflowOptionsType.RFM_ANALYTICS,
      },
    },
  }), []);

  const type = get(optionsTypeMap, mapPath);
  const options = useWorkflowOptions(type);

  const suggestions = (options.data).map(v => ({
    value: String(v.id),
    label: v.version ? `${v.name} (${v.version})` : v.name,
  })).filter(({ value }) => value !== '');

  useEffect(() => {
    options.setSearch(query);
  }, [query]);

  return {
    loadMore: options.loadMore,
    loading: options?.loading,
    suggestions,
  };
};

export default useWorkflowOptionsAutocomplete;
