import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeDashboardAnalyticsOptions,
  getDashboardAnalyticsOptions,
  getDatabaseEfficiency,
  getDatabaseEfficiencyPart2,
  getGeneralActivityDaily,
  getGeneralActivityHourly
} from '@store/actions/creators';
import { dashboardAnalyticsOptionsSelector, sidebarOpenedSelector, userSelector } from '@store/selectors';
import {
  dashboardAnalyticsEntitiesSelector,
  dashboardDatabaseEfficiencySelector,
  dashboardGeneralActivityDailySelector,
  dashboardGeneralActivityHourlySelector
} from '@store/selectors/dashboard';

import { AreaChart } from "@carbon/charts-react";
import uniq from 'lodash.uniq';
import uniqBy from 'lodash.uniqby';

import { useTranslation } from '@hooks';
import useCurrentWorkspace from "@hooks/useCurrentWorkspace";

import { dashboardDefaultRanges, defaultRanges } from '@constants';

import { by, clamp, extract, formatNumberString, identity, mapEntries, moment, testId, zeroIfNaN } from '@utils';

import { NoData, RangePicker } from '@components';

import { BaseActivityLoader, EntitiesTitle, SettingsButton } from './components';
import { Container, NoDataContainer, SettingsContainer, } from './styled';

import {
  ActivityContainer,
  ContactsChartContainer,
  ContactsChartsTitleRow,
  ContactsChartTitle,
  ContactsSection,
  HeaderRow,
  Row,
  TitleContainer,
} from '../../styled';
import { CompareContactsChart } from '../CompareContactsChart';

const entityColors = [
  '#31C447', '#24B277', '#F2A237',
  '#852064', '#1B75B5', '#A7C959',
  '#F1820B', '#BC2927', '#4C3689',
  '#6F4C40', '#FDC613', '#C85F28',
  '#3D518F', '#581845', '#CF7310',
  '#17CBBA', '#EC3171', '#A336B6',
  '#16C3D9', '#009291'
];

const resolveActivity = (ga) => {
  return Array.isArray(ga) ? ga : (ga.segments || ga.total);
};

const resolveRangeFromOptions = (options) => {
  if (Array.isArray(options.range)) {
    return (options.range || dashboardDefaultRanges['labels.this_week']).map(d => moment(d));
  }

  if (options.range?.absolute) {
    return options.range.absolute.map(d => moment(d));
  }

  if (options.range?.relative) {
    switch (options.range.relate) {
      case 'today':
      case 'this_week':
      default:
        return defaultRanges['labels.this_week'];
    }
  }

  return (options.range || defaultRanges['labels.this_week']).map(d => moment(d));
}

const offset = moment().utcOffset();

const normalizeData = (type, data, segments, funnels, funnelId) => {
  const normalizedData = [];

  if (type === 'segment') {
    for (const item of data) {
      normalizedData.push({
        date: item.date,
        count: item.customers_count,
        type: type,
        group: segments.find(by(item.segment_id))?.name,
        entity: item.segment_id,
      });
    }
  } else if (type === 'base') {
    for (const item of data) {
      normalizedData.push({
        date: moment(item.date).add(-moment().utcOffset(), 'minutes'),
        count: item.active,
        type,
        group: 'base.a',
        entity: null,
      });
      normalizedData.push({
        date: moment(item.date).add(-moment().utcOffset(), 'minutes'),
        count: item.total,
        type,
        group: 'base.t',
        entity: null
      });
      normalizedData.push({
        date: moment(item.date).add(-moment().utcOffset(), 'minutes'),
        count: item.added,
        type,
        group: 'base.n',
        entity: null
      });
    }
  } else if (type === 'funnel') {
    for (const item of data) {
      normalizedData.push({
        date: item.date,
        count: item.count,
        type: type,
        group: (funnels || []).find(by(funnelId))?.name,
        entity: null
      });
    }
  }

  return normalizedData;
};

const generateDates = (startDate, endDate, interval = 'day') => {
  const dates = [];
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  let currentDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes(), startDate.getSeconds()));
  const endUTCDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds()));

  while (currentDate <= endUTCDate) {
    dates.push(new Date(currentDate));

    if (interval === 'day') {
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    } else if (interval === 'hour') {
      currentDate.setUTCHours(currentDate.getUTCHours() + 1);
    } else {
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
  }

  return dates;
};

const getInterval = (startDate, endDate) => {
  const diff = Math.abs(moment(startDate).diff(moment(endDate), 'days'));
  // if (diff > 28) {
  //   return 'month';
  // }
  //
  // if (diff > 7) {
  //   return 'week';
  // }

  return diff ? 'days' : 'hour'
}

function adjustDate(date, intervalType, value) {
  const newDate = new Date(date);
  if (intervalType === 'days') {
    newDate.setDate(newDate.getDate() + value);
  } else if (intervalType === 'hours') {
    newDate.setHours(newDate.getHours() + value);
  }
  return newDate;
}

const sortByDate = (a, b) => (+new Date(a.date)) - (+ new Date(b.date));

const fillWithEmpty = (data, type, [startDate, endDate], segments) => {
  const groups = Array.from(new Set(data.map(extract('group'))));
  const intervalType = getInterval(startDate, endDate);
  const intervals = type !== 'funnel' ? generateDates(startDate, endDate, intervalType) : [];

  return groups.flatMap(group => {
    const groupData = data.filter(({ group: g }) => g === group).sort((a, b) => a.date - b.date);
    const empty = {
      group,
      type,
      entity: data.find(({ group: g }) => g === group)?.entity,
      count: 0,
    };

    return Array.from(
      new Set(
        intervals.map(i => {
          const utcOffset = empty.group?.startsWith?.('base.') ? (offset * 60 * 1000) : 0;
          const ex = groupData.find(({ date }) => +i - utcOffset === +(new Date(date)));
          const last = groupData.filter(({ date }) => +i >= +(new Date(date))).sort(sortByDate).pop();
          const next = groupData.filter(({ date }) => +i <= +(new Date(date))).sort(sortByDate).shift();

          if (ex) {
            return ex;
          }

          return { ...empty, count: last?.count || next?.count || 0, date: new Date(+i - utcOffset) };
        })
          .concat(groupData)
          .sort(sortByDate)
          .filter(({ date }) => (+new Date(date)) < Date.now())
          .filter(({ date, type, entity }) => {
            const segment = segments?.find?.(by(entity));
            if (!segment) {
              return true;
            }

            return type !== 'segment' || (+new Date(date)) > (+new Date(segment?.created_at));
          })
          .filter(({ date }) => (+new Date(date)) >= adjustDate(startDate, intervalType, -1) && (+new Date(date)) <= adjustDate(endDate, intervalType, 2)),
        ({ date, group }) => new Date(date).toISOString() + group)
    ).map(({ date, ...record }) => ({ ...record, date: new Date(date).toISOString() }));
  });
};

const resolveOptions = (o = {}, e = { segments: [], funnels: [] }) => {
  if (!o) {
    return {};
  }

  // eslint-disable-next-line array-callback-return
  const idsFiltered = uniq((o.ids || []).filter(id => {
    if (o.type === 'base') {
      return true;
    }

    if (o.type === 'funnel') {
      return !!e.funnels?.find?.(by(id));
    }

    if (o.type === 'segment') {
      return !!e.segments?.find?.(by(id));
    }
  }));

  return {
    ...o,
    type: idsFiltered.length ? o.type : 'base',
    ids: idsFiltered.length ? idsFiltered : ['base.a', 'base.n', 'base.t'],
  };
};

const createThresholds = (ids, type, { funnels, segments }, colors) => {
  if (type === 'base' || type === 'funnel') {
    return [];
  }

  const entities = {
    segment: segments,
    funnel: funnels
  }[type];

  return ids.map((id) => {
    const entity = (entities || []).find(by(id));

    return {
      value: moment(entity.created_at).local(),
      label: `${entity.name} created`,
      fillColor: colors[entity.name],
    };
  }).filter(identity);
}

const BaseActivitySection = () => {
  const databaseEfficiency = useSelector(dashboardDatabaseEfficiencySelector);
  const { p, t, i18n } = useTranslation('dashboard_page');
  const user = useSelector(userSelector);
  const generalActivityDaily = useSelector(dashboardGeneralActivityDailySelector);
  const generalActivityHourly = useSelector(dashboardGeneralActivityHourlySelector);
  const dispatch = useDispatch();
  const analyticEntities = useSelector(dashboardAnalyticsEntitiesSelector);
  const analyticsOptionsSelected = useSelector(dashboardAnalyticsOptionsSelector);
  const analyticsOptions = useMemo(() => resolveOptions(analyticsOptionsSelected, analyticEntities),
    [analyticsOptionsSelected, analyticEntities]);
  const range = resolveRangeFromOptions(analyticsOptions);
  const workspace = useCurrentWorkspace();
  const format = m => moment(m).format('MM-DD hh');
  const sidebarOpened = useSelector(sidebarOpenedSelector);

  const setRange = useCallback(([nr1, nr2]) => {
    let r1 = moment(nr1).isAfter(moment()) ? moment().startOf('day') : nr1;
    let r2 = moment(nr2).isAfter(moment()) ? moment().endOf('day') : nr2;
    let rr = [moment(r1).isValid() ? moment(r1).startOf('day') : range[0], moment(r2).isValid() ? moment(r2).endOf('day') : range[1]];

    if (!moment(r1).isValid() && !moment(r2).isValid()) {
      rr = defaultRanges['labels.today'];
    }

    if (moment(rr[0]).isSame(range[0], 'day') && moment(rr[1]).isSame(range[1], 'day')) {
      return;
    }

    dispatch(changeDashboardAnalyticsOptions({
      app_id: user?.app?.id,
      ...analyticsOptions,
      range: rr,
      onSuccess: () => {
        dispatch(getDashboardAnalyticsOptions({ app_id: user?.app?.id }));
      }
    }));
  }, [user?.app?.id, analyticsOptions, range.map(format).join()]);

  const getRangeDiff = () => {
    if (!range.every(d => !!d)) {
      return 0;
    }

    const [dateFrom, dateTo] = range;

    return Math.abs(moment(dateFrom).diff(moment(dateTo), 'days'));
  };

  const isDaily = getRangeDiff() > 1;

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const { ids, id, ...other } = analyticsOptions;
    const opt = { ...other };

    if (opt.loading || !opt) {
      return;
    }

    if (other.type === 'funnel') {
      opt.id = ids[0];
    } else {
      opt.ids = ids;
    }

    if (isDaily) {
      dispatch(getGeneralActivityDaily({ ...opt, range: [moment(range[0]).add(-1, 'day'), moment(range[1]).add(1, 'day')] }));
    } else {
      dispatch(getGeneralActivityHourly({ ...opt, range }));
    }

    dispatch(getDatabaseEfficiency({ ...opt, range }));
    dispatch(getDatabaseEfficiencyPart2({ ...opt, range }));
  }, [user?.app?.id, analyticsOptions?.type, analyticsOptions.range?.map?.(r => moment(r).toISOString())?.join?.('.'), analyticsOptions.ids?.join?.('.')]);

  const handleAnalyticsOptionsChange = useCallback(({ type, ids }) => {
    dispatch(changeDashboardAnalyticsOptions({
      app_id: user?.app?.id,
      type,
      ids,
      range,
      onSuccess: () => {
        dispatch(getDashboardAnalyticsOptions({ app_id: user?.app?.id }));
      }
    }));
  }, [dispatch, user?.app?.id, range.map(d => moment(d).toISOString()).join('.')]);

  const generalActivity = (isDaily ? generalActivityDaily.data : generalActivityHourly.data)|| [];

  const noChartData = analyticsOptions.type === 'funnel' && !generalActivity.length && !generalActivityDaily.loading && !generalActivityHourly.loading;
  const normalized = normalizeData(analyticsOptions?.type, resolveActivity(generalActivity), analyticEntities.segments || [], analyticEntities.funnels, analyticsOptions?.ids?.[0]);
  const mergedActivity = normalized.length ? fillWithEmpty(normalized, analyticsOptions.type, range, analyticEntities.segments || []) : [];

  const data = mergedActivity.filter(({ type, group }) => {
    if (type !== 'base') {
      return true;
    }

    return !!~(analyticsOptions?.ids || []).indexOf(group);
  }).map(({ date, count, group, type }) => ({
    date: moment.utc(date).local().toISOString(),
    value: zeroIfNaN(count),
    type,
    group: type === 'base' ? p(group) : group,
  })).concat((analyticsOptions.ids || []).filter(id => !String(id).startsWith('base')).map(id => ({
    group: analyticEntities[analyticsOptions.type === 'segment' ? 'segments' : 'funnels']?.find?.(by(id))?.name,
    value: 0,
    date: moment('2099-12-12'),
    entity: id,
    type: analyticsOptions.type,
  }))).filter(extract('group'));

  const baseColors = {
    [i18n.isInitialized ? p('base.n') : 'base.n']: '#109DFB',
    [i18n.isInitialized ? p('base.a') : 'base.a']: '#80D8DF',
    [i18n.isInitialized ? p('base.t'): 'base.t']: '#DCDFE6'
  };

  const groupColors = {
    ...baseColors,
    ...Object.fromEntries((analyticsOptions.ids || []).map((id, i) => [
      analyticEntities[analyticsOptions.type === 'segment' ? 'segments' : 'funnels']?.find?.(by(id))?.name,
      entityColors[i % (entityColors.length)]
    ]))
  };

  const getFillColor = (group) => {
    return groupColors[group];
  };

  const getStrokeColor = (group) => {
    return groupColors[group];
  };

  const getIsFilled = () => {
    return analyticsOptions.ids?.length <= 3;
  }

  const getDomain = () => {
    if (data.every(({ value }) => !isNaN(value) && value <= 0)) {
      return {
        domain: [0, 10],
        key: 'has-domain',
      };
    }

    return {
      domain: undefined,
      key: 'no-domain'
    };
  };

  const domain = getDomain();
  const thresholds = createThresholds(analyticsOptions.ids, analyticsOptions.type, analyticEntities, groupColors);

  const options = {
    title: "Dashboard analytics",
    curve: 'curveMonotoneX',
    height: '290px',
    getFillColor,
    getStrokeColor,
    getIsFilled,
    animations: false,
    grid: {
      x: { enabled: false },
    },
    toolbar: {
      numberOfIcons: 1,
      controls: ['Export as CSV', 'Export as PNG', 'Export as JPG', 'Make fullscreen'].map(type => ({ type, title: i18n.isInitialized && p(type), text: i18n.isInitialized && p(type) })),
    },
    legend: {
      position: 'top',
      alignment: 'right'
    },
    tooltip: {
      customHTML: ([{ date, value, group }, ...other]) => {
        const groups = [{ value, group }, ...other];

        return `
          <div class="sac-container">
            <div class="sac-date">
                <div>${moment(date).format('YYYY-MM-DD')}</div>
                <div>${moment(date).format('hh:mm A')}</div>
            </div>
            ${uniqBy(groups, extract('group')).map(({ value, group }) => `
              <div class="sac-group-row">
                <div class="sac-group-legend-circle" style="background-color: ${groupColors[group]}"></div>
                <div class="sac-group-legend-title">
                    <div>${group}</div>
                    <div>${formatNumberString(value)}</div>
                </div>
              </div>
            `).join('\n')}    
          </div>
        `;
      }
    },
    axes: {
      left: {
        domain: domain.domain,
        ticks: {
          min: 1,
          number: 5,
          formatter: t => t % 1 !== 0 ? null : t,
        },
        mapsTo: 'value',
        scaleType: 'linear',
      },
      bottom: {
        domain: [
          moment(range[0]).add(-1, 'minute'),
          moment(range[1]).isAfter(moment().add(1, 'minute'))
            ? moment().add(1, 'minute')
            : moment(range[1]).add(1, 'minute')
        ],
        mapsTo: 'date',
        thresholds: thresholds,
        ticks: {
          min: 2,
          number: (() => {
            const r2 = moment(range[1]).isAfter(moment()) ? moment() : moment(range[1]);

            if (!isDaily) {
              return clamp(Math.abs(Math.ceil(moment(range[0]).diff(r2, 'hours'))), 2, 24);
            }

            const dayDiff = Math.abs(Math.ceil(moment(range[0]).diff(r2, 'days')));

            return clamp(dayDiff, 2, 12);
          })(),
          formatter: t => isDaily ? moment(t).format('MMM DD') : moment(t).format('hh:mm A'),
        },
        scaleType: 'time'
      },
    },
  };

  const resolveActivityProps = () => {
    if (analyticsOptions.type === 'base') {
      return [
        {
          primary: formatNumberString(databaseEfficiency?.total),
          secondary: (
            <>
              <span style={{ color: '#80D8DF' }}>+{zeroIfNaN(databaseEfficiency?.createdCount / databaseEfficiency?.total * 100).toFixed(2)} %</span>
              <span className="black">(+{formatNumberString(databaseEfficiency?.createdCount)} {t('segments_page.customers').toLowerCase()})</span>
            </>
          ),
          chartType: 'quantity',
          tooltip: p('total_number_customers'),
          title: p('current_quantity'),
        },
        {
          primary: <span style={{ color: '#80D8DF' }}>{zeroIfNaN(databaseEfficiency?.activityCount / databaseEfficiency?.total * 100).toFixed(2)}%</span>,
          secondary: `${formatNumberString(databaseEfficiency?.activityCount)} of ${formatNumberString(databaseEfficiency?.total)}`,
          chartColors: ['#80D8DF'],
          chartType: 'base',
          chartData: {
            total: databaseEfficiency?.total,
            count: databaseEfficiency?.activityCount,
          },
          tooltip: p('interaction_through_workflow'),
          title: p("active_from_range"),
        }
      ];
    }

    if (analyticsOptions.type === 'funnel') {
      return [
        {
          title: p('total_in_funnel'),
          primary: formatNumberString(databaseEfficiency?.total),
          tooltip: p('total_in_funnel_description'),
          noData: !databaseEfficiency.loading && !databaseEfficiency?.total,
        },
        {
          title: p('total_completed'),
          primary: `${zeroIfNaN(databaseEfficiency?.completed / databaseEfficiency?.total * 100).toFixed(2)}%`,
          secondary: `${formatNumberString(databaseEfficiency?.completed)} of ${formatNumberString(databaseEfficiency?.total)}`,
          chartType: 'funnel',
          chartColors: ['#80D8DF'],
          tooltip: p('total_completed_description'),
          noData: !databaseEfficiency.loading && !databaseEfficiency?.total,
          chartData: {
            completed: databaseEfficiency?.completed,
            total: databaseEfficiency?.total
          }
        }
      ];
    }

    if (analyticsOptions.type === 'segment' && analyticsOptions.ids?.length > 1) {
      return [
        {
          hidden: true
        },
        {
          title: p('current_segments_penetration'),
          chartType: 'segment',
          chartColors: groupColors,
          tooltip: p('current_segments_penetration_description'),
          chartData: {
            total: databaseEfficiency?.total || 100,
            segments: databaseEfficiency?.segments || [],
          },
        }
      ]
    } else if (analyticsOptions.type === 'segment') {
      return [
        {
          title: p('current_quantity'),
          tooltip: p('total_in_segment_description'),
          primary: formatNumberString(databaseEfficiency?.segments?.[0]?.customer_count),
        },
        {
          title: p('current_penetration'),
          chartType: 'funnel',
          segment: true,
          primary: `${zeroIfNaN(databaseEfficiency?.segments?.[0]?.customer_count / databaseEfficiency?.total * 100).toFixed(2)}%`,
          secondary: <span className="black">{zeroIfNaN(databaseEfficiency?.segments?.[0]?.customer_count)} of {zeroIfNaN(databaseEfficiency?.total)}</span>,
          tooltip: p('current_penetration_description'),
          chartData: {
            total: zeroIfNaN(databaseEfficiency?.total),
            completed: zeroIfNaN(databaseEfficiency?.segments?.[0]?.customer_count),
          },
        }
      ]
    }

    return [{}, {}];
  };

  const activityProps = resolveActivityProps();

  const disabledDate = useCallback(d => moment(d).isBefore(moment(user?.app?.created_at)) || moment(d).isAfter(moment().endOf('day')), []);
  const defaultRangesMemoized = useMemo(() => mapEntries(dashboardDefaultRanges, ([k, [s, e]]) => [t(k), [s, moment(e).isAfter(moment()) ? moment() : moment(e)]]), [t]);
  const btnStyle = useMemo(() => ({ marginRight: 10 }), []);

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current?.chart?.model?.state?.options?.axes?.left) {
      ref.current.chart.model.state.options.axes.left.domain = domain.domain;
    }
  }, [domain.domain]);

  const [updater, setUpdater] = useState(sidebarOpened);

  useEffect(() => {
    setTimeout(() => { setUpdater(sidebarOpened) }, 400);
  }, [sidebarOpened]);

  const tid = testId('base-activity');

  return (
    <Container data-testid="dashboard-base-activity-section" className={getIsFilled() ? 'dashboard-analytics' : 'rmv-fill dashboard-analytics'}>
      <HeaderRow data-testid="dashboard-header">
        <TitleContainer>
          <EntitiesTitle type={analyticsOptions.type} ids={analyticsOptions.ids} colors={groupColors} />
        </TitleContainer>
        <SettingsContainer>
          <SettingsButton
            options={analyticsOptions}
            onOptionsChange={handleAnalyticsOptionsChange}
            style={btnStyle}
          />
          <RangePicker
            disabledDate={disabledDate}
            ranges={defaultRangesMemoized}
            value={range}
            dashboard
            onChange={setRange}
            timeZone={workspace.timezone}
          />
        </SettingsContainer>
      </HeaderRow>
      <ContactsSection>
        <ActivityContainer>
          <BaseActivityLoader {...tid(`contacts-loader`)} active={databaseEfficiency.loading} />
          {!activityProps[0].hidden && (
            <Row style={{ marginBottom: '10px' }}>
              <CompareContactsChart {...activityProps[0]} />
            </Row>
          )}
          <CompareContactsChart {...activityProps[1]} double={activityProps[0].hidden} />
        </ActivityContainer>
        <ContactsChartContainer>
          <BaseActivityLoader {...tid(`contacts-chart-loader`)} active={generalActivityDaily.loading || generalActivityHourly.loading || !i18n.isInitialized} />
          <ContactsChartsTitleRow>
            <ContactsChartTitle>
              {p('contacts_chart')}
            </ContactsChartTitle>
          </ContactsChartsTitleRow>
          {noChartData ? (
            <NoDataContainer>
              <NoData style={{ maxWidth: 200 }} description={p('no_customers_in_funnel')} />
            </NoDataContainer>
          ) : (
            <AreaChart ref={ref} key={domain.key + String(updater)} data={data || []} options={options} />
          )}
        </ContactsChartContainer>
      </ContactsSection>
    </Container>
  );
};

export default BaseActivitySection;
