import { Slider as ANTDSlider } from 'antd';

import { Container } from './styled.js';

const Slider = ({ value, onChange, vertical, min, max, step, preview, testId, ...props }) => {
  return (
    <Container $vertical={vertical} preview={preview} style={props.style} data-testid={testId}>
      <ANTDSlider
        step={step}
        vertical={vertical}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        {...props}
        style={vertical ? {
          width: '0px',
          height: '100%',
          transform: 'translateX(-2px)'
        } : {
          width: '100%',
          height: '0px',
        }}
        trackStyle={{
          background: '#303133',
          height: vertical ? '140px' : '2px',
          borderRadius: '2px',
          width: vertical ? 2 : '100px',
        }}
        handleStyle={{
          background: '#303133',
          width: '10px',
          marginTop: vertical ? '0' : '-4px',
          marginLeft: vertical ? '-4px' : '0',
          height: '10px',
          border: 'none',
        }}
      />
    </Container>
  );
}

export default Slider;

