import React from 'react';

import { Badge, DeprecatedIcon as Icon, ICON_SIZES, DEPRECATED_ICONS_NAMES as ICONS_NAMES, PALETTES } from '@velitech/ui';
import { Dropdown, Tooltip } from 'antd';

import { useTranslation } from '@hooks';

import { Paths } from '@constants';

import { Link } from '@components';

import { StatusDraftContainer, WorkflowsContainer } from './styled';

const SegmentStatus = ({ workflows, status }) => {
  const { t } = useTranslation('segments_page');

  const workflowItems = Object.entries(workflows).filter(([key]) => !isNaN(+key)).map(([, wf]) => wf)?.filter(w => w.type === 2);
  const campaigns = Object.entries(workflows).filter(([key]) => !isNaN(+key)).map(([, wf]) => wf)?.filter(w => w.type === 1);

  if(status === 'pending_review'){
    return (
      <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={PALETTES.WARNING_500}>
        <StatusDraftContainer>
          Pending Manager Review
          <Tooltip
            title={'For help contact your EDGE manager.'}
            placement={'bottom'}>
            <Icon name={ICONS_NAMES.Information} color={PALETTES.WARNING_500} size={ICON_SIZES.S16} />
          </Tooltip>
        </StatusDraftContainer>
      </Badge>
    )
  }

  return (
    <Badge size={Badge.SIZES.SMALL} variant={Badge.VARIANT.GHOST} color={((workflowItems.length || 0) + (campaigns.length || 0)) === 0 ? Badge.COLORS.NEUTRAL_500 : Badge.COLORS.POSITIVE}>
      {(workflowItems.length || campaigns.length) ? (
        <Dropdown trigger={['hover']} placement="bottomCenter" overlay={(
          <WorkflowsContainer>
            {workflowItems.map(({ name, id }) => <Link key={id} to={`${Paths.EDIT_WORKFLOW}/${id}/editor`}>{name}</Link>)}
            {campaigns.map(({ name, id, s }) => <Link key={id} to={`${Paths.CAMPAIGNS}/${id}/${s === 'Design' ? 'editor' : 'analytic'}`}>{name}</Link>)}
          </WorkflowsContainer>
        )}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {t('statuses.used').toUpperCase()}
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6" fill="none">
              <path
                d="M5.66602 5.9998L0.666016 0.999804L1.36602 0.299805L5.66602 4.5998L9.96602 0.299805L10.666 0.999804L5.66602 5.9998Z"
                fill="#12B76A"/>
            </svg>
          </div>
        </Dropdown>
      ) : (
        t('statuses.draft').toUpperCase()
      )}
    </Badge>
  );
};

export default React.memo(SegmentStatus);
