import styled from 'styled-components';

import { extract } from "@/utils";

styled.div`
    width: 260px;
    height: 520px;
    padding: 40px 20px 47px;
    position: relative;
    background-size: cover;
    background-repeat:   no-repeat;
    background-position: center center;
    background-image: url(${extract('image')});
    
    display: flex;
    align-items: flex-end;
`;

export const Container = styled.div`
    border-radius: 11px;
    border: 1px solid #D3DBE7;
    padding: 20px;
    min-width: 440px;
    max-width: 600px;
    position: relative;
    display: flex;
    gap: 14px;
    background-color: #fff;
    
    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;

export const ImageContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 11px;
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 85%;
    gap: 10px;
    
`

export const Title = styled.h4`
    font-family: Manrope-SemiBold;
    font-size: 22px;
    line-height: 24px;
    color: #1A232E;
    word-wrap: break-word;
`

export const Message = styled.p`
    font-family: Manrope-Medium;
    font-size: 18px;
    line-height: 20px;
    color: #1A232E;
    word-wrap: break-word;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  `