import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

import { Device } from '@res';

export const Image = styled.img`
  @media screen and ${Device.desktop} {
    width: 194px;
    height: 60px;
    margin-bottom: 110px;
    margin-top: 120px;
  }

  width: 194px;
  height: 60px;
  margin-bottom: 50px;
  margin-top: 50px;

  @media screen and (max-width: 769px) {
    width: 194px;
    height: 60px;
    margin-bottom: 110px;
  }

  @media screen and (max-width: 500px) {
    width: 124px;
    height: 38px;
    margin-bottom: 40px;
    margin-top: 50px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  line-height: 130%;
  color: #FFFFFF;
  padding-left: 5px;
  
  ${styledProp('valid', css`
    color: #909399;
  `)}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #fbfcf8;
  overflow-y: auto;
  min-height: 550px;
  min-width: 320px;

  @media screen and (max-width: 500px) {
    padding-bottom: 21px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 30px;
  text-align: center;
  max-width: 300px;
  font-family: Manrope-SemiBold;
  font-size: 20px;
  line-height: 23.7px;
  margin-bottom: 54px;
  color: #fff;

  @media screen and (max-width: 500px) {
    margin-bottom: 86px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px 43px 21px 43px;
  width: 400px;
  box-shadow: 8px 20px 30px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  align-items: center;
  background: #FFFFFF;

  @media screen and (max-width: 769px) {
  }

  @media screen and (max-width: 500px) {
    text-align: center;
    width: 100%;
    max-width: calc(100vw - 10px);
    margin: 0 10px;
  }
`;

