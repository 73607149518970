import {
  WEB_POPUPS_TYPES,
  WEB_POPUPS_LAYOUT,
  WEB_POPUPS_ORIENTATION,
} from '../constants.js';

export const typeTabs = [
  {
    name: WEB_POPUPS_TYPES.MODAL,
    label: 'wp_type_modal',
  },
  {
    name: WEB_POPUPS_TYPES.FULL_SCREEN,
    label: 'wp_type_full_screen',
  },
  {
    name: WEB_POPUPS_TYPES.CODE,
    label: 'wp_type_code',
  },
  {
    name: WEB_POPUPS_TYPES.INSTANT_MESSAGE,
    label: 'wp_type_instant_message',
  },
  {
    name: WEB_POPUPS_TYPES.ON_SITE_NOTIFICATION,
    label: 'wp_type_on_site_notification',
  },
];

export const LayoutTabs = {
  [WEB_POPUPS_TYPES.MODAL]: [
    {
      name: WEB_POPUPS_LAYOUT.IMAGE_ONLY,
      label: 'wp_layout_imageOnly',
    },
    {
      name: WEB_POPUPS_LAYOUT.TEXT,
      label: 'wp_layout_optional_image_text',
    },
  ],
  [WEB_POPUPS_TYPES.FULL_SCREEN]: [
    {
      name: WEB_POPUPS_LAYOUT.IMAGE_ONLY,
      label: 'wp_layout_imageOnly',
    },
    {
      name: WEB_POPUPS_LAYOUT.TEXT_WITH_OPTIONAL_IMAGE,
      label: 'wp_layout_text',
    },
  ],
  [WEB_POPUPS_TYPES.CODE]: [],
  [WEB_POPUPS_TYPES.INSTANT_MESSAGE]: [],
};

export const OrientationTabs = {
  [WEB_POPUPS_TYPES.FULL_SCREEN]: [
    {
      name: WEB_POPUPS_ORIENTATION.PORTRAIT,
      label: 'wp_orientation_portrait',
    },
    {
      name: WEB_POPUPS_ORIENTATION.LANDSCAPE,
      label: 'wp_orientation_landscape',
    },
  ],
};

export const redirectTabs = [
  {
    name: 'button',
    label: 'cc_redirect_button',
  },
  {
    name: 'link',
    label: 'cc_redirect_link',
  },
];

export const imageTypeTabs = [
  {
    name: 'image',
    label: 'cc_image_type_image',
  },
  {
    name: 'image_url',
    label: 'cc_image_type_url',
  },
];

export const showTextContent = (tmp) => {
  const isCorrectType = [WEB_POPUPS_TYPES.MODAL, WEB_POPUPS_TYPES.FULL_SCREEN, WEB_POPUPS_TYPES.INSTANT_MESSAGE, WEB_POPUPS_TYPES.ON_SITE_NOTIFICATION].includes(tmp.type);
  const isCorrectLayout = [WEB_POPUPS_LAYOUT.TEXT, WEB_POPUPS_LAYOUT.TEXT_WITH_OPTIONAL_IMAGE].includes(tmp.layout);
  const withoutLayout = [WEB_POPUPS_TYPES.INSTANT_MESSAGE, WEB_POPUPS_TYPES.ON_SITE_NOTIFICATION].includes(tmp.type);

  return isCorrectType && (isCorrectLayout || withoutLayout)
}