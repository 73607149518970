import { useEffect, useState } from 'react';

import { DeprecatedIcon as Icon, MenuItem, useMenuContext, Popover } from '@velitech/ui';

import { resolveDemoWorkspaceIcon } from '@components/layout/Sidebar/components/Popover/WorkspacePopover';

import { WorkspaceAvatar, Container } from './styled';

import { Avatar } from '../../styled';
import { WorkspaceList } from '../WorkspaceList/WorkspaceList';

export const SidebarHeader = ({ user, toggle }) => {
  const ctxMenu = useMenuContext();
  const [opened, setOpened] = useState();

  useEffect(() => {
    toggle(ctxMenu.opened || ctxMenu.opening);
  }, [ctxMenu.opened, ctxMenu.collapsing, ctxMenu.opening]);

  return (
    <Container data-testid={'sidebar-workspace-menu-item'}>
      <Popover
        placement='right-end'
        hover
        noPadding
        onOpenChange={setOpened}
        offset={0}
        content={<WorkspaceList menuIsOpened={ctxMenu.opened}/>}
        contentClassName='popover-sidebar'
      >
        <MenuItem
          onClick={() => null}
          active={opened}
          addonLeft={
            user?.app?.is_demo ? (
              <WorkspaceAvatar>
                {resolveDemoWorkspaceIcon(user?.app?.name)}
              </WorkspaceAvatar>
            ) : (
              <WorkspaceAvatar>
                <Avatar image={user?.app?.link}>
                  {!user?.app?.link && user?.app?.name[0]}
                </Avatar>
              </WorkspaceAvatar>
            )
          }
          addonRight={
            <Icon inherit name={'ChevronRight'} size={Icon.SIZES.S18} />
          }
        >
          {user?.app?.name}
        </MenuItem>
      </Popover>
    </Container>
  );
};
