import { v4 } from "uuid";

import { ControlGroupNode, Flow, FlowEdge, FlowNode, NodeActionType, NodeName } from "@/constants/flow";
import { Treemap, TreemapNode } from "@/constants/flow/treemap";
import { FlowService } from "@/services/FlowService";

const isControlGroup = (node: FlowNode): node is ControlGroupNode => node.data.actionType === NodeActionType.CONTROL_GROUP;

const decorateWithControlGroup = (controlGroupNode: ControlGroupNode | undefined, children: TreemapNode[], controlGroupChildren: TreemapNode[]): TreemapNode[] => {
  if (!controlGroupNode) {
    return children;
  }

  const segments = [{
    value: 100 - controlGroupNode.data.value,
    label: 'internal',
    id: v4(),
  }, {
    value: controlGroupNode.data.value,
    label: 'internal',
    id: v4(),
    flowId: controlGroupNode.id,
    controlGroup: true,
  }]

  const id = v4();

  return [{
    type: NodeName.AB_TEST,
    id,
    data: { segments, id },
    children: segments.map(({ id, value, flowId, controlGroup }) => {
      return {
        id,
        type: NodeName.AB_SPLITTER,
        data: { value, id, flowId, is_control_group: true },
        children: !controlGroup ?
          children.filter(({ data }) => data.actionType !== NodeActionType.CONTROL_GROUP)
          : controlGroupChildren,
      };
    }),
  }];
}

const node2TreemapChildren = (node: FlowNode, flow: Flow): TreemapNode[] => {
  const children = FlowService.getChildren(node.id, flow);
  const controlGroup = children.find(isControlGroup);
  const controlGroupChildren = FlowService.getChildren(controlGroup?.id || '', flow);

  const decoratedChildren = decorateWithControlGroup(
    controlGroup,
    children.flatMap(child => node2TreemapChildren(child, flow)),
    controlGroupChildren.flatMap(child => node2TreemapChildren(child, flow))
  );
  const id = v4();
  if (node.data.name === NodeName.EVENT) {
    return (node.data.events || []).map((data) => {
      const id = v4();
      return {
        id,
        type: node.data.name,
        data: {
          ...data,
          id,
          flowId: node.id,
        },
        children: decorateWithControlGroup(
          controlGroup,
          children.flatMap(child => node2TreemapChildren(child, flow)),
          controlGroupChildren.flatMap(child => node2TreemapChildren(child, flow))
        ),
      }
    });
  }
  if (node.data.name === NodeName.ENTRY_ANOTHER_WF) {
    return (node.data.workers || []).map((data) => ({
      id,
      type: node.data.name,
      data: {
        ...data,
        id,
        flowId: node.id,
      },
      children: decoratedChildren,
    }));
  }
  if (node.data.name === NodeName.BEST_CHANNEL_TO_SEND) {
    return [{
      id,
      type: node.data.name,
      data: {
        ...node.data,
        id,
        flowId: node.id,
        channels: (node.data.channels || []).map(channel => {
          const newId = v4();

          return {
            id: newId,
            type: channel.type,
            default: channel.default,
            data: {
              ...channel.data,
              name: channel.type,
              id: newId,
            }
          };
        }),
      },
      children: decoratedChildren,
    }]
  }

  if (node.data.name === NodeName.MESSAGE_FEED && node.data.content_card_id && ((node.data.expiration?.type === 'duration' || !node.data.expiration?.type) && !node.data.expiration?.duration)) {
    return [{
      id,
      type: node.data.name || node.data.actionType,
      data: {
        ...node.data,
        id,
        flowId: node.id,
        expiration: {
          type: 'duration',
          duration: 30,
        }
      },
      children: decoratedChildren,
    }];
  }

  return [{
    id,
    type: node.data.name || node.data.actionType,
    data: {
      ...node.data,
      id,
      flowId: node.id,
    },
    children: decoratedChildren,
  }];
}

export const flow2Treemap = (flow: { nodes: FlowNode[], edges: FlowEdge[] }): Treemap => {
  const entryNode = FlowService.getEntryNode(flow);

  if (!entryNode) {
    return {
      type: null,
      id: null,
      data: null,
      children: [],
    }
  }

  return {
    type: null,
    id: null,
    data: null,
    children: node2TreemapChildren(entryNode, flow),
  };
}
