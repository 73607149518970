import { useContext, useRef, useState } from 'react';
import { useSelector } from "react-redux";

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useModalState, useTranslation } from '@hooks';

import { findAttributes, replaceKeysWithValues } from "@utils";
import { clickhouseFields2Customer } from '@utils/fields';
import { Platforms } from '@utils/platform';

import {
  EmailSampleData, TemplatesGeneralAttributesDrawer,
  ToggleButton,
  WebPushPreview,
} from '@components';
import { SelectLanguage, MultiLanguageContext, DefaultLangModal } from '@components/lib';
import { FallbackValuesModal } from "@components/modals/FallbackValuesModal";

import { Container, Content, Tabs, Tab, TabIndicator, PreviewContainer } from './styled.js';

const getTabs = (p) => [
  {
    name: Platforms.ANDROID,
    title: p('android'),
  },
  {
    name: Platforms.IOS,
    title: p('ios'),
  },
];

const MobilePushPreview = ({ hasTabs, onChange, onTabClick, template, previewData, hideActions, image, integrated }) => {
  const fallbackModal = useModalState();
  const clickhouseFields = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(clickhouseFields).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const [tab, setTab] = useState(Platforms.ANDROID);
  const attributesModal = useModalState();
  const discountModal = useModalState();
  const container = useRef(null);

  const { multiLangTabsController, ...multiLangTemplate } = useContext(MultiLanguageContext) || {};
  const langModal = multiLangTemplate.langModal;

  const { p } = useTranslation('templates');

  const tabs = getTabs(p);

  const handleTabClick = (tab) => () => {
    setTab(tab);
    onTabClick?.(tab);
  };

  const handleToggleAttributes = () => {
    discountModal.close();
    attributesModal.toggle();
  };

  const handleToggleDiscount = () => {
    attributesModal.close();
    discountModal.toggle();
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values,['message', 'title'], template);
    onChange?.(t => ({ ...t, ...replaced }));
  }

  return (
    <Container $integrated={integrated}>
      {(!integrated && !hideActions) && (
        <PreviewContainer $hasTabs={hasTabs}>
          <SelectLanguage 
            selectedLanguages={multiLangTabsController.languages} 
            onAddLang={multiLangTabsController.onAddLang} 
            handleOpenLang={multiLangTabsController.onOpenSelectLang}
          />
          {(template.message?.indexOf('{{customer.') !== -1 || template.title.indexOf('{{customer.') !== -1) && (
            <ToggleButton
              testId="sms-fallback-toggle"
              opened={fallbackModal.opened}
              style={{ marginRight: 4 }}
              fallback
              onToggle={fallbackModal.open}
            >
              {p('fallback_values')}
            </ToggleButton>
          )}
          <ToggleButton
            testId="email-attributes-toggle"
            opened={attributesModal.opened}
            style={{ marginRight: 12 }}
            onToggle={handleToggleAttributes}
          >
            {p('customer_attributes')}
          </ToggleButton>
          <ToggleButton
            testId="email-discount-toggle"
            style={{ marginRight: 10 }}
            opened={discountModal.opened}
            onToggle={handleToggleDiscount}
          >
            {p('general_attributes')}
          </ToggleButton>
        </PreviewContainer>
      )}
      <Content $integrated={integrated} ref={container} id="drawer-container">
        <Tabs>
          {tabs.map(({ name, title }, idx) => (
            <Tab
              key={idx}
              id={`mp-tab-${name}`}
              selected={name === tab}
              onClick={handleTabClick(name)}
            >
              {title}
            </Tab>
          ))}
          <TabIndicator tab={tab} />
        </Tabs>
        <WebPushPreview
          platform={tab}
          integrated={integrated}
          title={!template.title ? template.title : (previewData.title || template.title)}
          text={!template.message ? template.message : (previewData.message || template.message)}
          icon={image || ""}
          subtitle={!template.subtitle ? template.subtitle : (previewData.subtitle || template.subtitle)}
          mobilePush
          buttons={template.buttons || template.actions}
          image={image || ""}
          company="Chrome"
        />
        <EmailSampleData
          onClose={attributesModal.close}
          opened={attributesModal.opened}
          width="100%"
          smaller
          getContainer={() => container.current}
          onVisibilityChanged={attributesModal.setOpened}
        />
        <TemplatesGeneralAttributesDrawer
          onClose={discountModal.close}
          width="100%"
          hiddenTabs={['subscription']}
          opened={discountModal.opened}
          onVisibilityChanged={discountModal.setOpened}
        />

        <FallbackValuesModal
          opened={fallbackModal.opened}
          onClose={fallbackModal.close}
          testId="fallback-modal"
          onSubmit={handleSubmitFallbacks}
          fallbacks={findAttributes([template.message, template.title], fields)}
        />
        {langModal && <DefaultLangModal
          opened={langModal.opened}
          title={langModal.title}
          onClose={langModal.onDecline}
          onSave={langModal.onAccept}
          options={langModal.languageOptions}
        />}
      </Content>
    </Container>
  );
}

export default MobilePushPreview;

