import { OptionsActionTypes } from '../types';

export const getOptions = () => ({
  type: OptionsActionTypes.GET_OPTIONS,
});

export const getOptionsWorkflow = () => ({
  type: OptionsActionTypes.GET_WORKFLOW_OPTIONS,
});

export const setLastUpdate = (date) => ({
  type: OptionsActionTypes.SET_LAST_UPDATE,
  payload: date
});
