import { Tab, Tabs, Tooltip, DeprecatedIcon as Icon, DEPRECATED_ICONS_NAMES as ICONS_NAMES, ICON_SIZES, PALETTES } from '@velitech/ui';
import { Menu } from 'antd';

import { useTranslation } from "@hooks";

import * as Colors from '@res/theme/colors';

import { MoreButton } from '@components';

import { CountryIcon, TabItem, TabsContainer } from './styled';

export const LanguageTab = ({ icon, value, isActive = false, isDefault, onSetDefaultLang, onDeleteLang, onlyOne, hasError }) => {
  const { t } = useTranslation('templates');

  const handleSetDefaultLang = (event) => {
    event.domEvent.stopPropagation();
    onSetDefaultLang();
  }

  const handleDelete = (event) => {
    event.domEvent.stopPropagation();
    onDeleteLang();
  }

  return (
    <TabItem className={`tab-item ${isActive && 'active'} ${hasError && 'error'}`} $active={isActive}>
      <CountryIcon>{icon}</CountryIcon>
      {value}
      {isDefault && (` (${t('default')})`)}
      {!onlyOne && <MoreButton
        onClick={(e) => e.stopPropagation()}
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
        click
        id={'more-btn'}
        testId={'more-btn'}
        iconSize={16}
        iconStyles={{ width: '16px', height: '16px' }}
        menu={(
          <Menu style={{ borderRadius: '10px', overflow: 'hidden' }}>
            {!isDefault && <Menu.Item key="87" onClick={handleSetDefaultLang}>
              {t('templates.set_default')}
            </Menu.Item>
            }
            <Menu.Item key="817" style={{ color: Colors.Text.DANGER }} onClick={handleDelete}>
              {t('templates.delete')}
            </Menu.Item>
          </Menu>
        )}>
      </MoreButton>}
      {hasError && (
        <Tooltip className='tooltip' content={'Required fields are not filled in'}>
          <Icon
            name={ICONS_NAMES.InformationFilled}
            size={ICON_SIZES.S20}
            color={PALETTES.NEGATIVE_500}
          />
        </Tooltip>
      )}
    </TabItem>
  );
};

export const LanguageTabs = ({ tabs, errors, onChange }) => {

  return (
    <TabsContainer>
      <Tabs
        tabs={tabs}
        className="tabs-container"
        onChange={onChange}
        renderItem={(props) => {
          const hasErrors = errors && Object.keys(errors).some((errorKey) => errors[errorKey] && errorKey.split('-')[1] === props.id);

          return <Tab className={`tab ${hasErrors && 'error'}`} style={{ minWidth: 'unset' }} {...props} />;
        }}
      />
    </TabsContainer>
  );
};
