import { ReducerKeys } from '@constants';

export const rolesSelector = (store) => store[ReducerKeys.ROLES].roles;

export const rolesMetaSelector = (store) => store[ReducerKeys.ROLES].meta;

export const rolesLoadingSelector = (store) => store[ReducerKeys.ROLES].loading;
export const permissionsSelector = (store) => store[ReducerKeys.ROLES].permissions;

export const currentRoleSelector = (store) => store[ReducerKeys.ROLES].currentRole;

export const rolesListSelector = (store) => store[ReducerKeys.ROLES].rolesList;

