import { DeprecatedIcon as Icon, ICON_SIZES, DEPRECATED_ICONS_NAMES as ICONS_NAMES, PALETTES } from "@velitech/ui";

import laptopPreview from "@res/images/laptop.png";
import placeholder from '@res/images/placeholder.png';

import { PreviewButton } from "@components/ui/PreviewTemplate/components/PreviewButton";
import { Image } from "@components/ui/PreviewTemplate/components/PreviewContent/styled";

import {
  ButtonContainer,
  Container,
  ImageContainer,
  LaptopContainer,
  Message,
  TextContainer,
  Title
} from './styled';

export const NotificationLaptop = ({
  title,
  message,
  buttons,
  imageUrl,
}) => {
  const hasButtonsName = buttons?.some((button) => button.name);

  const renderButtons = buttons?.map((button) => {
    if (!button.name) {
      return null;
    }

    return (
      <PreviewButton key={button.name} size={'small'} type={button.type}>
        {button.name}
      </PreviewButton>
    );
  });

  return (
    <LaptopContainer image={laptopPreview}>
      <Container>
        <Icon className={'close-icon'} name={ ICONS_NAMES.CloseOutline } color={PALETTES.NEUTRAL_500} size={ICON_SIZES.S12}/>
        <ImageContainer>
          <Image src={imageUrl || placeholder} alt='' />
        </ImageContainer>
        <TextContainer>
          <Title>
            {title}
          </Title>
          <Message>
            {message}
          </Message>
          {hasButtonsName && (
            <ButtonContainer>
              {renderButtons}
            </ButtonContainer>
          )}
        </TextContainer>
      </Container>
    </LaptopContainer>
  );
};
