import styled from 'styled-components';
export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #D3DBE7;
  gap: 10px;
`;

export const Time = styled.p`
    font-family: Manrope-Medium;
    font-size: 12px;
    font-weight: 500;
    color: #7C94B1;
`;

export const Row = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
`;

export const Text = styled.p`
    font-family: Manrope-Medium;
    font-size: 12px;
    font-weight: 500;
`;

export const PrimaryCount = styled.p`
    font-family: Manrope-SemiBold;
    font-size: 16px;
    font-weight: 600;
    color: #12B76A;
`

export const SecondaryCount = styled.p`
    font-family: Manrope-Medium;
    font-size: 12px;
    font-weight: 500;
    color: ${props => (props.$isPositive ? '#12B76A' : '#BF4F74')};
    
`

