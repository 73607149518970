import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  width: 120px;
  background: #D3DBE7;
  padding: 0 2px;
  height: 26px;
  display: flex;
  align-items: center;
  background: var(--Colors-Additional-White, #FFF);

  ${styledProp('$vertical', css`
    justify-content: center;
    height: 140px;
    width: 40px;
  `)}

  ${styledProp('preview', css`
    height: 42px !important;
    background: #FFFFFF !important;
  `)}
    
    & .ant-slider-dot {
      visibility: hidden !important;
    }
    
    & .ant-slider.ant-slider-vertical {
        margin: 0 !important;
    }
`;
