import styled, { css } from 'styled-components';

import { styledProp, switchStyle } from '@utils';

export const Container = styled.div`
  margin-left: 192px;
  
  ${styledProp('$appearance', css`
    margin-left: 0;
    background: #F0F2F6;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
    padding: 4px 0 0 0;
  `)}
`;

export const AddFilterButton = styled.div`
  color: #31C447;
  font-size: 13px;
  line-height: 120%;
  display: flex;
  flex: 0;
  flex-grow: 0;
  flex-shrink: 1;
  width: max-content;
  cursor: pointer;
  margin-top: 12px;


  ${styledProp('$appearance', css`
    width: 100%;
    margin-top: 0;
    justify-content: center;
    padding: 8px 0;
  `)}

  ${styledProp('$disabled', css`
      color: #A1B3C8;
  `)}

  ${styledProp('$bordered', css`
    border-top: 1px solid #DCDFE6;
  `)}
`;

export const FieldRow = styled.div`
  margin-top: 8px;
  display: flex;
  //align-items: center;

  ${styledProp('$appearance', css`
    flex-direction: column;
    margin-top: 0px;
    padding: 16px 10px 12px 10px;
  `)}

  ${styledProp('$bordered', css`
    border-top: 1px solid #DCDFE6;
  `)}

  & > *:last-child {
    opacity: 0;
    transition: 330ms ease;
    transform: scale(1.2);
  }
  
  &:hover {
    & > *:last-child {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  ${switchStyle('$appearance', {
    column: css`
      
    `
  })}
`;

export const SelectRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 6px;
`;

export const FieldLabel = styled.div`
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: flex-end;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 100%;
  margin-right: 6px;
  color: #909399;
`;
