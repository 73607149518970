import { compose } from '@utils';

import { asCreator, withCallbacks, withFilters, withOrdering, withPagination } from '../../lib';
import { SegmentsActionTypes } from '../types';

export const listSegments = compose(
  withCallbacks,
  withPagination,
  withOrdering,
  withFilters(['name', 'status', '$type']),
  asCreator(() => ({ type: SegmentsActionTypes.GET }))
);

export const createAggregate = compose(
  withCallbacks,
  asCreator(({ label, name, field, filters, createdId, aggregateMeta, nestedField, id, type, aggregate, attribute, logicalOperator, segmentId }) => ({
    type: SegmentsActionTypes.CREATE_AGGREGATE,
    payload: { label, field, createdId, nestedField, name, aggregateMeta, filters, id, type, aggregate, logicalOperator, attribute, segmentId },
  }))
);

export const deleteAggregate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: SegmentsActionTypes.DELETE_AGGREGATE,
    payload: { id },
  }))
);

export const getSingleAggregate = compose(
  asCreator(({ id }) => ({
    type: SegmentsActionTypes.GET_SINGLE_AGGREGATE,
    payload: { id },
  }))
)

export const getAggregates = compose(
  withPagination,
  withOrdering,
  withFilters(['input_value', 'start_date', 'end_date', 'user_id', '$type', 'used']),
  withCallbacks,
  asCreator(({ paginate }) => ({
    type: SegmentsActionTypes.GET_AGGREGATE,
    payload: { paginate },
  }))
);

export const deleteSegment = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: SegmentsActionTypes.DELETE,
    payload: { id },
  })),
);

export const getSegmentEditMetadata = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: SegmentsActionTypes.GET_EDIT_METADATA,
    meta: { id },
  }))
);

export const saveSegment = compose(
  withCallbacks,
  asCreator(({ id, segment_id, query, static_query, name, type, description, aggregates, funnels, is_pending = false }) => ({
    type: SegmentsActionTypes.SAVE,
    payload: { query, segment_id, name, static_query, description, id, type, aggregates, funnels, is_pending },
    meta: id,
  }))
);

export const editSegment = compose(
  withCallbacks,
  asCreator(({ id, segment_id, query, name, static_query, type, description, aggregates, funnels, is_pending = false }) => ({
    type: SegmentsActionTypes.EDIT,
    payload: { query, segment_id, name, description, static_query, type, id, aggregates, funnels, is_pending },
    meta: id,
  }))
)

export const clearFilterResult = () => ({
  type: SegmentsActionTypes.CLEAR_FILTER_RESULTS,
});

export const getSegmentExampleContacts = compose(
  withPagination,
  withOrdering,
  withCallbacks,
  withFilters(['input_value']),
  asCreator(({ type, resourceId, fields = [], query, funnels, funnel = {}, aggregates, page, per_page, filterDrillValue, input_value, end_date, start_date }) => ({
    type: SegmentsActionTypes.GET_CONTACTS_EXAMPLE,
    payload: {
      type,
      segmentId: resourceId,
      page,
      per_page,
      funnels,
      fields,
      filterDrillValue,
      aggregates,
      input_value,
      end_date,
      start_date,
      ...funnel,
      query: funnel?.query?.children ? funnel?.query : query,
    },
    meta: { page: type },
  }))
);

export const exportSegmentCustomers = compose(
  withCallbacks,
  asCreator(({ query, name, aggregates, id, funnels, fields }) => ({
    type: SegmentsActionTypes.EXPORT_CUSTOMERS,
    payload: { query, fields, id, aggregates, funnels },
    meta: { name },
  })),
);

export const saveNewSegmentPreset = ({ segment }) => ({
  type: SegmentsActionTypes.SAVE_NEW_SEGMENT_PRESET,
  payload: { segment },
});

export const clearNewSegmentPreset = () => ({
  type: SegmentsActionTypes.CLEAR_NEW_SEGMENT_PRESET,
});

export const listNestedAggregates = compose(
  withCallbacks,
  asCreator(() => ({
    type: SegmentsActionTypes.LIST_NESTED_AGGREGATES,
  }))
);

export const uploadSegmentFile = compose(
  withCallbacks,
  asCreator(({ id, file, fields, onProgress }) => ({
    type: SegmentsActionTypes.UPLOAD_FILE,
    payload: { id, file, fields, onProgress },
    meta: { id },
  })),
);

export const cancelSegmentFileUpload = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: SegmentsActionTypes.UPLOAD_FILE_CANCEL,
    payload: { id },
    meta: { id },
  })),
);

export const saveCSVSegment = compose(
  withCallbacks,
  asCreator(({ id, fileId, name, description }) => ({
    type: SegmentsActionTypes.SAVE_CSV_SEGMENT,
    payload: { id, fileId, name, description },
    meta: { id }
  })),
);

export const setIsHardQuery = (value) => ({
  type: SegmentsActionTypes.SET_IS_HARD_QUERY,
  payload: value,
});

