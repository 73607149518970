import DefaultFolders from './defaultFolders';

const FolderIcons = {
  [DefaultFolders.DEFAULT]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M11.17 6L14.59 9.41L15.17 10H28V26H4V6H11.17ZM11.17 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V26C2 26.5304 2.21071 27.0391 2.58579 27.4142C2.96086 27.7893 3.46957 28 4 28H28C28.5304 28 29.0391 27.7893 29.4142 27.4142C29.7893 27.0391 30 26.5304 30 26V10C30 9.46957 29.7893 8.96086 29.4142 8.58579C29.0391 8.21071 28.5304 8 28 8H16L12.59 4.59C12.4039 4.40283 12.1827 4.25434 11.939 4.15308C11.6953 4.05182 11.4339 3.99979 11.17 4Z" fill="#7C94B1"/>
    </svg>
  ),
  [DefaultFolders.ARCHIVE]: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 21H12C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19V17C10 16.4696 10.2107 15.9609 10.5858 15.5858C10.9609 15.2107 11.4696 15 12 15H20C20.5304 15 21.0391 15.2107 21.4142 15.5858C21.7893 15.9609 22 16.4696 22 17V19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21ZM12 17V19H20V17H12Z"
        fill="#7C94B1"/>
      <path
        d="M28 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V10C2 10.5304 2.21071 11.0391 2.58579 11.4142C2.96086 11.7893 3.46957 12 4 12V28C4 28.5304 4.21071 29.0391 4.58579 29.4142C4.96086 29.7893 5.46957 30 6 30H26C26.5304 30 27.0391 29.7893 27.4142 29.4142C27.7893 29.0391 28 28.5304 28 28V12C28.5304 12 29.0391 11.7893 29.4142 11.4142C29.7893 11.0391 30 10.5304 30 10V6C30 5.46957 29.7893 4.96086 29.4142 4.58579C29.0391 4.21071 28.5304 4 28 4ZM26 28H6V12H26V28ZM28 10H4V6H28V10Z"
        fill="#7C94B1"/>
    </svg>
  ),
}

export default FolderIcons;
