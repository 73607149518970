import styled from 'styled-components';

import { extract } from "@/utils";

export const LaptopContainer = styled.div`
    width: 100%;
    min-width: 648px;
    height: 362px;
    padding: 26px 84px 43px;
    position: relative;
    background-size: cover;
    background-repeat:   no-repeat;
    background-position: center center;
    background-image: url(${extract('image')});
    
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const Container = styled.div`
    border-radius: 6px;
    border: 1px solid #D3DBE7;
    padding: 5px;
    width: 100%;
    max-width: 140px;
    position: relative;
    display: flex;
    gap: 5px;
    background-color: #fff;
    .close-icon {
        position: absolute;
        right: 2px;
        top: 2px;
        cursor: pointer;
        transform: scale(0.8);
    }
`;

export const ImageContainer = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 5px;
`

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 75%;
    
`

export const Title = styled.h4`
    font-family: Manrope-SemiBold;
    font-size: 6px;
    color: #1A232E;
    word-wrap: break-word;
`

export const Message = styled.p`
    font-family: Manrope-Medium;
    font-size: 5px;
    color: #1A232E;
    word-wrap: break-word;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  margin-top: 5px;
  `