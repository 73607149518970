import { useContext, useRef, useState } from 'react';
import { useSelector } from "react-redux";

import { clickhouseFieldsSelector } from '@store/selectors/fields';

import { useModalState , useTranslation } from '@hooks';

import { by, findAttributes, replaceKeysWithValues } from '@utils';
import { clickhouseFields2Customer } from '@utils/fields';
import { Platforms } from '@utils/platform';

import {
  EmailSampleData,
  TemplatesGeneralAttributesDrawer,
  WebPushPreview,
  ToggleButton, 
  MultiLanguageContext,
  SelectLanguage,
  DefaultLangModal
} from '@components';
import { FallbackValuesModal } from "@components/modals/FallbackValuesModal";

import { Container, Content, Tabs, Tab, TabIndicator, TabOptionsContainer, TabOption, PreviewContainer } from './styled';

const getTabs = (p) => [
  {
    name: 'desktop',
    title: p('desktop'),
  },
  {
    name: 'mobile',
    title: p('mobile'),
  },
];

const getTabOptions = (p) => ({
  'desktop': [
    {
      platform: Platforms.WINDOWS,
      title: p('windows'),
    },
    {
      platform: Platforms.MACOS,
      title: p('macos'),
    },
  ],
  'mobile': [
    {
      platform: Platforms.ANDROID,
      title: p('android'),
    },
    {
      platform: Platforms.IOS,
      title: p('ios'),
    },
  ]
});

const WebpushPreview = ({ template, onChange, previewData, icon, image, buttonsOptions, integrated, hasTabs }) => {
  const clickhouseFields = useSelector(clickhouseFieldsSelector)?.data || [];
  const fields = clickhouseFields2Customer(clickhouseFields).map(({ field, label, ...rest }) => ({ name: field, label, ...rest, key: field }));
  const [platform, setPlatform] = useState(Platforms.MACOS);
  const attributesModal = useModalState();
  const discountModal = useModalState();
  const fallbackModal = useModalState();
  const container = useRef(null);
  const { p } = useTranslation('templates');

  const { multiLangTabsController, ...multiLangTemplate } = useContext(MultiLanguageContext) || {};
  const langModal = multiLangTemplate.langModal;

  const tabs = getTabs(p);
  const TabOptions = getTabOptions(p);

  const handleTabClick = (tab) => () => {
    setPlatform(TabOptions[tab][0].platform);
  };

  const handleToggleAttributes = () => {
    discountModal.close();
    attributesModal.toggle();
  };

  const handleToggleDiscount = () => {
    attributesModal.close();
    discountModal.toggle();
  };

  const handlePlatformChange = (platform) => () => {
    setPlatform(platform);
  };

  const handleSubmitFallbacks = (values) => {
    const replaced = replaceKeysWithValues(values,['body', 'title'], template);
    onChange((temp) => {
      return {
        ...temp,
        ...replaced
      }
    });
  }

  const tab = Object.entries(TabOptions).find(([, options]) => options.some(by('platform', platform))).shift();

  return (
    <Container $integrated={integrated}>
      {/*<ContentTitle>Preview</ContentTitle>*/}
      {/*<SliderTabs />*/}
      {!integrated && (
        <PreviewContainer $hasTabs={hasTabs}>
          <SelectLanguage 
            selectedLanguages={multiLangTabsController.languages} 
            onAddLang={multiLangTabsController.onAddLang} 
            handleOpenLang={multiLangTabsController.onOpenSelectLang}
          />
          {(template.body?.indexOf('{{customer.') !== -1 || template.title.indexOf('{{customer.') !== -1) && (
            <ToggleButton
              testId="webpush-fallback-toggle"
              opened={fallbackModal.opened}
              style={{ marginRight: 4 }}
              fallback
              onToggle={fallbackModal.open}
            >
              {p('fallback_values')}
            </ToggleButton>
          )}
          <ToggleButton
            testId="email-attributes-toggle"
            opened={attributesModal.opened}
            style={{ marginRight: 12 }}
            onToggle={handleToggleAttributes}
          >
            {p('customer_attributes')}
          </ToggleButton>
          <ToggleButton
            testId="email-discount-toggle"
            style={{ marginRight: 10 }}
            opened={discountModal.opened}
            onToggle={handleToggleDiscount}
          >
            {p('general_attributes')}
          </ToggleButton>
        </PreviewContainer>
      )}
      <Content $integrated={integrated} ref={container} id="drawer-container">
        <Tabs>
          {tabs.map(({ name, title }, idx) => (
            <Tab
              key={idx}
              id={`wpp-tab-${name}`}
              selected={name === tab}
              onClick={handleTabClick(name)}
            >
              {title}
            </Tab>
          ))}
          <TabIndicator tab={tab} />
        </Tabs>
        <TabOptionsContainer>
          {TabOptions[tab].map(({ platform: p, title }, idx) => (
            <TabOption key={idx} selected={platform === p} onClick={handlePlatformChange(p)}>{title}</TabOption>
          ))}
        </TabOptionsContainer>
        <WebPushPreview
          platform={platform}
          integrated={integrated}
          title={!template.title ? template.title : (previewData.title || template.title)}
          text={!template.body ? template.body : (previewData.body || template.body)}
          icon={icon || ""}
          image={image || ""}
          company="Chrome"
          buttons={buttonsOptions}
        />
        <EmailSampleData
          onClose={attributesModal.close}
          opened={attributesModal.opened}
          width="100%"
          smaller
          getContainer={() => container.current}
          onVisibilityChanged={attributesModal.setOpened}
        />
        <TemplatesGeneralAttributesDrawer
          onClose={discountModal.close}
          width="100%"
          hiddenTabs={['subscription']}
          opened={discountModal.opened}
          onVisibilityChanged={discountModal.setOpened}
        />

        <FallbackValuesModal
          opened={fallbackModal.opened}
          onClose={fallbackModal.close}
          testId="fallback-modal"
          onSubmit={handleSubmitFallbacks}
          fallbacks={findAttributes([template.body, template.title], fields)}
        />
        {langModal && <DefaultLangModal
          opened={langModal.opened}
          title={langModal.title}
          onClose={langModal.onDecline}
          onSave={langModal.onAccept}
          options={langModal.languageOptions}
        />
        }
      </Content>
    </Container>
  );
}

export default WebpushPreview;

