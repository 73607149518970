import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  //min-width: 271px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
`;
export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  margin-top: 2px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 120%;

  ${styledProp('selected', css`
    color: #31C447;
  `)}
`;

export const Content = styled.div`
  display: flex;
`;

export const Item = styled.div`
  cursor: pointer;
  font-size: 13px;
  line-height: 120%;
  padding: 8px 20px;
  width: 100%;

  transition: background-color 290ms ease;

  &:hover {
    background: #ecebeb;
  }
`;

export const DynamicColumn = styled.div`
  max-height: 212px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

