import React from 'react'

import { PopupAnalytic } from "pages/HomeScene/pages/SettingsScene/pages/OTPAnalyticPage/components/PopupAnalytic";
import ReactDOMServer from 'react-dom/server';

import { moment } from "@/utils";

export const groupByStatusDate = (data) => {
  const result = {};

  data.forEach(({ status, count, created_at }) => {
    if (!result[status]) {
      result[status] = {};
    }
    const dateWithoutTime = created_at.split('T')[0];
    if (!result[status][dateWithoutTime]) {
      result[status][dateWithoutTime] = 0;
    }

    result[status][dateWithoutTime] += count;
  });

  return result;
};

export const groupByDateConversion = (data) => {
  const result = {};

  data?.forEach(({ date, cnt }) => {
    result[date] = +cnt;
  });

  return result;
};

export const groupByName = (data) => {
  const result = {};

  data.forEach(({ status, count }) => {

    if (!result[status]) {
      result[status] = 0;
    }

    result[status] += count;
  });

  return result;
};

const getRangeDiff = (range) => {
  if (!range.every((d) => !!d)) {
    return 0;
  }

  const [dateFrom, dateTo] = range.map((d) => moment(+d));

  return Math.abs(dateFrom.diff(dateTo, 'days'));
};

export const optionsTrendChart = ({ maxCount, range, type = 'area', series, trendTab }) => {
  return {
    tooltip: {
      enabled: true,
      enabledOnSeries: false,
      onDatasetHover: true,
      custom: (data) => {
        if(series[0]?.data?.[data.dataPointIndex]?.y || !!series[0]?.data?.[data.dataPointIndex]?.data?.total) {
          return ReactDOMServer.renderToString(<PopupAnalytic data={series[0]?.data?.[data.dataPointIndex]} prevDay={series[0]?.data?.[data.dataPointIndex - 1]} trendTab={trendTab} />);
        }
        return '';
      },
      hideEmptySeries: true,
      fillSeriesColor: false,
    },
    chart: {
      type: type,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    fill: type === 'area' ? {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.55,
        opacityTo: 0.10,
        stops: [50, 100]
      }
    } : {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      show: true,
      lineCap: 'butt',
      width: 3,
      curve: 'straight',

    },
    xaxis: {
      tooltip: {
        hideEmptySeries: false,
        enabled: false,
      },
      width: '100%',
      tickPlacement: 'on',
      labels: {
        offsetX: 0,
        offsetY: 0,
        style: {
          width: '100%',
          colors: '#7C94B1',
          cssClass: 'custom-apexcharts-xaxis-label',
        },
        formatter: (date) => {
          return moment(date).format('DD.MM')
        }
      },
      axisBorder: {
        show: true,
        color: '#D3DBE7',
      },
      tickAmount: (() => {
        const diff = getRangeDiff(range);

        if (diff <= 10) {
          return diff;
        }

        if (diff <= 30) {
          return diff / 3;
        }
        if (diff <= 70) {
          return diff / 7;
        }

        return diff / 30;
      })(),
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    grid: {
      borderColor: '#D3DBE7',
      strokeDashArray: 5,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      padding: { left: 5, right: 5, top: 0, bottom: 0 },
    },
    yaxis: {
      ...(maxCount ? { max: maxCount } : {}),
      type: 'numeric',
      decimalsInFloat: 0,
      floating: true,
      labels: {
        align: 'left',
        offsetX: 40,
        offsetY: -10,
        style: {
          colors: '#7C94B1',
          cssClass: 'custom-apexcharts-yaxis-label',
        },
        formatter: function (val) {
          return val?.toFixed(0) || 0;
        },
      },

      tickAmount: (() => {
        return 2
      })(),

    },
    legend: {
      show: false,
    },
  };
};

export const adjustDateForTimeZone = (dateString, isStart, timezone) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = moment.tz(dateString, timezone);
  const targetOffset = Math.abs(date.utcOffset());
  const offset = targetOffset === 0 ? moment.tz(dateString, userTimeZone).utcOffset() : targetOffset;
  if (timezone && timezone !== userTimeZone) {
    const adjustment = isStart ? offset : -offset;
    return date.add(adjustment, 'minutes');
  }

  return date;

};
