import { createRequestAction } from "@store/lib/utils/creators";

import { all, takeLatest, put } from 'redux-saga/effects';

import { Endpoints } from "@constants";

import { OptionsActionTypes } from '../../actions/types';

function* getOptionsSaga(action) {
  yield all([
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'app' } }, {
        method: 'get',
        endpoint: '/api/apps/options',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'workflow' } }, {
        method: 'get',
        endpoint: '/api/cus/settings/fields/clickhouse/list',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'segment' } }, {
        method: 'get',
        endpoint: '/api/seg/analytics/options',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'aggregate' } }, {
        method: 'get',
        endpoint: '/api/seg/settings/aggregate/list',
        disableTimeout: true,
      })
    ),
    put(
      createRequestAction({ ...action, meta: { ...action.meta, part: 'events' } }, {
        method: 'get',
        endpoint: Endpoints.EVENTS,
        disableTimeout: true,
      }),
    ),
  ]);
}

function* getWorkflowOptions(action) {
  yield put(
    createRequestAction({ ...action, meta: { ...action.meta, part: 'workflowOptions' } }, {
      method: 'post',
      endpoint: Endpoints.WORKFLOW_OPTIONS,
    })
  );
  yield put(
    createRequestAction({ ...action, meta: { ...action.meta, part: 'allWorkflowOptions' } }, {
      method: 'get',
      endpoint: '/api/workflow/options/all-workflows',
    })
  )
}

export default function* optionsRootSaga() {
  yield all([
    takeLatest(OptionsActionTypes.GET_OPTIONS, getOptionsSaga),
    takeLatest(OptionsActionTypes.GET_WORKFLOW_OPTIONS, getWorkflowOptions),
  ]);
}
