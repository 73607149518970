import { ReducerKeys } from '@constants';

export const campaignsSelector = store => store[ReducerKeys.CAMPAIGNS].campaigns;

export const campaignsCalendarSelector = store => store[ReducerKeys.CAMPAIGNS].campaignsCalendar;

export const campaignEditMetadataSelector = store => store[ReducerKeys.CAMPAIGNS].editMetadata;

export const campaignContactsSelector = store => store[ReducerKeys.CAMPAIGNS].contacts;

export const campaignNotesSelector = store => store[ReducerKeys.CAMPAIGNS].notes;

export const cachedCampaignSelector = store => store[ReducerKeys.CAMPAIGNS].cachedCampaign;

