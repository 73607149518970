import { WITHOUT_VALUE_OPERATORS } from '@constants';

export const isRequired = v => (!String(v).length || v === undefined) && 'field_is_required';

export const isValidUrl = v => !new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i').test(v) && 'invalid_url';

export const min = m => v => v < m && `greater_than_${m}`;
export const max = m => v => v > m && `less_than_${m}`;

export const maxLength = (n, message) => s => (s?.length || 0) > n && message;

export const minLength = (n, message) => s => (s?.length || 0) < n && message;

export const testArr = (regexp, message) => v => {
  return !(v || []).every(vv => new RegExp(regexp, 'g').test(vv)) && message;
};

export const fullPercentage = vs => vs.reduce((acc, { value }) => acc + Number(value), 0) !== 100 && 'sum_segments';

export const isValidBuilder = ({ filters }) => {
  const errors = filters.map(({ field, value, operator }) => ({
    field: isRequired(field),
    operator: isRequired(operator),
    value: (!~WITHOUT_VALUE_OPERATORS.indexOf(operator)) && isRequired(value.value),
  }));
  return !!errors.some(({ field, value }) => field || value) && errors;
}
