import { ReducerKeys } from '@constants';

export const appRedirectsSelector = store => store[ReducerKeys.APP].redirects;

export const sidebarOpenedSelector = store => store[ReducerKeys.APP].sidebarOpened;

export const hintModeSelector = store => store[ReducerKeys.APP].hintMode;

export const onboardingSelector = store => store[ReducerKeys.APP].onboarding;

export const messageChannelsDisclaimerClosedSelector = store => store[ReducerKeys.APP].messageChannelsDisclaimerClosed;

export const healthCheckWebSDKSelector = store => store[ReducerKeys.APP].healthCheckWebSDK;
