import { Container, AlertText } from './styled';

export const Alert = ({ type, withMargin = true, children }) => {
  return (
    <Container $type={type} $withMargin={withMargin}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Warning--alt--filled">
          <g id="Vector">
            <path d="M10.0013 3.85713H9.99876L2.90544 17.4979L2.90663 17.5H17.0934L17.0946 17.4979L10.0013 3.85713ZM9.29688 7.50001H10.7031V13.125H9.29688V7.50001ZM10 16.25C9.81458 16.25 9.63333 16.195 9.47916 16.092C9.32499 15.989 9.20483 15.8426 9.13387 15.6713C9.06291 15.5 9.04434 15.3115 9.08052 15.1296C9.11669 14.9478 9.20598 14.7807 9.33709 14.6496C9.4682 14.5185 9.63525 14.4292 9.81711 14.393C9.99897 14.3568 10.1875 14.3754 10.3588 14.4464C10.5301 14.5173 10.6765 14.6375 10.7795 14.7917C10.8825 14.9458 10.9375 15.1271 10.9375 15.3125C10.9375 15.5611 10.8387 15.7996 10.6629 15.9754C10.4871 16.1512 10.2486 16.25 10 16.25Z" fill="#F79009"/>
            <path d="M18.125 18.75H1.875C1.76763 18.75 1.66206 18.7223 1.56848 18.6697C1.4749 18.617 1.39647 18.5411 1.34075 18.4493C1.28502 18.3576 1.25389 18.253 1.25034 18.1456C1.2468 18.0383 1.27096 17.9319 1.3205 17.8366L9.4455 2.21163C9.49828 2.11016 9.57789 2.0251 9.67566 1.96575C9.77344 1.90639 9.88562 1.875 10 1.875C10.1144 1.875 10.2266 1.90639 10.3243 1.96575C10.4221 2.0251 10.5017 2.11016 10.5545 2.21163L18.6795 17.8366C18.729 17.9319 18.7532 18.0383 18.7497 18.1456C18.7461 18.253 18.715 18.3576 18.6593 18.4493C18.6035 18.5411 18.5251 18.617 18.4315 18.6697C18.338 18.7223 18.2324 18.75 18.125 18.75ZM2.90663 17.5H17.0934L17.0946 17.4979L10.0013 3.85713H9.99876L2.90544 17.4979L2.90663 17.5Z" fill="#F79009"/>
          </g>
        </g>
      </svg>
      <AlertText>{children}</AlertText>
    </Container>
  )
}