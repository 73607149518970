import { Container, Row, Time, Text, PrimaryCount, SecondaryCount } from "@/pages/HomeScene/pages/SettingsScene/pages/OTPAnalyticPage/components/PopupAnalytic/styled";

export const PopupAnalytic = ({ data, trendTab, prevDay }) => {

  const isPercent = trendTab === 'percent';
  const prevCount = (data?.y || 0) - (prevDay?.y || 0);
  const sign = prevCount > 0 ? '+' : '';
  return (
    <Container>
      <Row>
        <Time>{data.x}</Time>
      </Row>
      <Row>
        <Text>Converted</Text>
        <PrimaryCount>
          {isPercent ? `${data.y.toFixed(2)} %` : data.y}
        </PrimaryCount>
      </Row>
      <Row>
        <Text>Unique conversions</Text>
        <Text>{data.data.conversion || 0} / {data.data.total || 0}</Text>
      </Row>
      <Row>
        <Text>From prev day</Text>
        <SecondaryCount $isPositive={prevCount >= 0}>{isPercent ? `${sign} ${prevCount.toFixed(2)} %` : `${sign} ${prevCount}`}</SecondaryCount>
      </Row>

    </Container>
  )
}