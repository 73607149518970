import { v4 as uuidv4 } from 'uuid';

import { WorkflowNodes, WorkflowNodeTypes } from '@constants';

import { by, identity, update } from '@utils';
import { excludeInclude, isSplitter, notEnd, notSplitter } from '@utils/workflows/refactored/common';
import { createNode } from '@utils/workflows/refactored/creators';
import { getExitPoints, getLastNode } from '@utils/workflows/refactored/getters';
import { iterate, toArray } from '@utils/workflows/refactored/structureParser';

export const updateNode = (root, id, updater) => {
  if (root.id === id) {
    return update(root, updater);
  }

  return {
    ...root,
    data: {
      ...(root.data || {}),
      children: root.data?.children ? root.data?.children?.map?.(child => updateNode(child, id, updater)) : root.data?.children,
    },
    children: root?.children?.map?.(child => updateNode(child, id, updater)),
  };
};

export const appendChild = (root, id, node, target, position = 'right') => {
  if (root.id === id) {
    if (root.type === WorkflowNodes.AB_TEST || root.type === WorkflowNodes.SWITCH_FILTER) {
      return appendChild(root, root.children[0].id, node, target, position);
    }

    const targetAt = (root.children || []).findIndex(by(target));
    const insert = { ...node, data: { ...node.data, serverId: uuidv4() } };
    const newChildren = (root.children || []).filter(({ type }) => type !== 'end');

    if (~targetAt) {
      if (position === 'right') {
        return {
          ...root,
          children: [
            ...newChildren.slice(0, targetAt + 1),
            insert,
            ...newChildren.slice(targetAt + 1),
          ]
        };
      }

      if (position === 'left') {
        return {
          ...root,
          children: [
            ...newChildren.slice(0, targetAt),
            insert,
            ...newChildren.slice(targetAt),
          ]
        };
      }
    }

    return {
      ...root,
      children: [...newChildren, insert],
    };
  }

  return {
    ...root,
    children: root?.children?.map(child => appendChild(child, id, node, target, position)),
  };
};

export const insertAfter = (root, id, node) => {
  if (root.id === id) {
    if (root.type === WorkflowNodes.AB_TEST || root.type === WorkflowNodes.SWITCH_FILTER) {
      return insertAfter(root, root.children[0].id, node);
    }

    if (node.type === WorkflowNodes.AB_TEST || node.type === WorkflowNodes.SWITCH_FILTER) {
      node.children = node.children.map((child, index) => ({
        ...child,
        children: ((root.children || [])[index] ? [(root.children || [])[index]] : []).filter(({ type }) => type !== 'end'),
      })).filter(({ type }) => type !== 'end');
    } else {
      node.children = [...node.children, ...(root.children || [])];
    }

    return {
      ...root,
      children: [node],
    };
  }

  return {
    ...root,
    children: root.children?.map?.(child => insertAfter(child, id, node)),
  };
};

export const insertBefore = (root, id, node) => {
  const child = root.children?.find?.(by(id));

  if (child) {
    const newNode = { ...node };

    if (newNode.type === WorkflowNodes.AB_TEST || newNode.type === WorkflowNodes.SWITCH_FILTER) {
      newNode.children[0].children = [child];
    } else {
      newNode.children = [...newNode.children, child];
    }

    return {
      ...root,
      children: (root.children || []).map((ch) => ch.id === child.id ? newNode : ch),
    };
  }

  return {
    ...root,
    children: root.children?.map?.(child => insertBefore(child, id, node)),
  };
};

export const appendFinishNodes = (root, translate, first, add = 0) => {
  const firstRoot = first || root;

  if (!root) {
    return null;
  }

  if ((root.children || []).length === 1 && root.children[0].type === WorkflowNodes.END_PATH) {
    return root;
  }

  const next = Math.max(...[0, ...getExitPoints(firstRoot).map(({ data }) => parseInt(data.label?.match(/^Exit point (\d+)$/)?.[1], 10) || 0)]) + 1 + add;

  const updated = (root.children || []).map((child, index, self) => {
    const mutated = self.filter((r, i) => i < index && !getLastNode(r).children[0]);

    return appendFinishNodes(child, translate, firstRoot, mutated.length);
  });

  return {
    ...root,
    children: updated?.length ? updated : [createNode({ type: WorkflowNodes.END_PATH, data: { source: root.id, label: `Exit point ${next}` } })[0]],
  }
};

export const removeChild = (root, id) => {
  if (root.id === id) {
    if (root.children?.[0]?.type === 'end') {
      return null;
    }

    return root.children?.[0] || null;
  }

  const child = root.children?.find?.(by(id));
  if (child) {
    let removed = {
      ...root,
      children: root.children.filter(({ id: curr }) => curr !== id).concat(child.children),
    };

    for (const cc of child.children) {
      if (isSplitter(cc) || excludeInclude(cc)) {
        removed = removeChild(removed, cc.id)
      }
    }

    if (removed.children.length > 1) {
      removed.children = removed.children.filter(({ type }) => type !== 'end');
    }

    return removed;
  }

  return {
    ...root,
    children: root.children?.map?.(child => removeChild(child, id)),
  };
};

export const removeBranchFrom = (root, id) => {
  if (root.id === id) {
    return null;
  }

  const child = root.children?.find?.(by(id));
  if (child) {
    return {
      ...root,
      children: root.children.filter(({ id: curr }) => curr !== id),
    };
  }

  return {
    ...root,
    children: root.children?.map?.(child => removeBranchFrom(child, id)),
  };
};

export const clearTempNodes = (root, styles) => {
  if (!root) {
    return null;
  }

  let newRoot = root;
  let temp = toArray(root).find(({ id }) => Object.values(styles).find(by(id))?.temp);

  while (temp) {
    newRoot = removeChild(newRoot, temp.id);
    temp = toArray(newRoot).find(({ id }) => Object.values(styles).find(by(id))?.temp)
  }

  return newRoot;
};

export const clearStyles = (root, styles) => {
  let res = {};

  iterate(root, ({ id, type, data }) => {
    res[id] = styles[id];

    if (type === 'array') {
      res = { ...res, ...clearStyles(data.nodes, styles) };
    }
  })

  return res;
};

export const insertLast = (root, child) => {
  if (!(root.children || []).filter(notEnd).length) {
    return {
      ...root,
      children: [child],
    };
  }

  return {
    ...root,
    children: (root.children || []).map(ch => insertLast(ch, child)),
  };
};

export const createArrayFrom = (root, id, translate = identity) => {
  return {
    ...root,
    children: (root.children || []).map(child => {
      if (child.id === id) {
        return createNode({ nodeType: WorkflowNodeTypes.ARRAY, type: 'array', actionType: 'array', data: { nodes: child }, translate })[0];
      }

      return child;
    }).map(child => createArrayFrom(child, id, translate))
  };
};

export const findArray = (root) => {
  if (!root) {
    return null;
  }

  if (root?.type === 'array') {
    return root.id;
  }

  let res = null;

  for (const child of (root?.children || [])) {
    if (child?.type === 'array') {
      res = findArray(child);
    }
  }

  return res;
}

export const openArrayAt = (root, id) => {
  return {
    ...root,
    children: (root.children || []).map(child => {
      if (child.id === id) {
        if (child.data.nodes.data.renderId) {
          return {
            ...child.data.nodes,
            id: child.data.nodes.data.renderId,
            data: {
              ...child.data.nodes.data,
              serverId: child.data.nodes.id,
              id: child.data.nodes.data.renderId,
              arrayStart: false,
            }
          };
        }

        return {
          ...child.data.nodes,
          data: {
            ...child.data.nodes.data,
            arrayStart: false,
          }
        };
      }

      return child;
    }).map(child => openArrayAt(child, id))
  };
}

export const wrapArrays = (root, translate = identity) => {
  if (root.multinode || (root && root.id === null)) {
    const child = root.children[0];
    const children = child?.children || [];

    return wrapArrays({
      ...child,
      multinode: false,
      data: {
        ...(child?.data || []),
        children: root.children,
      },
      children,
    });
  }

  if (root.type === WorkflowNodes.QUIET_HOURS) {
    const ch = root.children[0];
    root.children = ch.children || [];
    ch.before = root;

    return wrapArrays(ch);
  }

  let id = root.id;

  if (root.data.renderId) {
    id = root.data.renderId;
  }

  return {
    ...root,
    id,
    data: {
      ...root.data,
      id,
      serverId: root.data.renderId ? root.data.id : undefined,
    },
    children: (root.children || []).map(child => {
      if (child.data.arrayStart) {
        return createNode({ translate, nodeType: WorkflowNodeTypes.ARRAY, type: 'array', actionType: 'array', data: { nodes: wrapArrays(child, translate) } })[0];
      }

      if (child.data.renderId) {
        return { ...child, id: child.data.renderId, data: { ...child.data, serverId: child.data.id, id: child.data.renderId } };
      }

      return child;
    }).map(n => wrapArrays(n, translate)),
  };
};

export const updateSplitters = (root, id, styles, updateStyles, translate = identity, type = 'ab', actionType = WorkflowNodes.AB_TEST) => {
  if (root.id === id) {
    const segments = styles[root.id].data.segments.map((data) =>
      createNode({
        type: data.type || type,
        data,
        actionType: data.actionType || actionType,
        nodeType: WorkflowNodeTypes.SPLITTER,
        translate
      }));

    const segmentsStyles = segments.reduce((acc, [{ id }, style]) => ({ ...acc, [id]: style }), {});

    (root.children || []).filter((childrenData) => childrenData.type === type).forEach((child, index) => {
      if (segments[index]?.[0]) {
        segments[index][0].children = (child.children || []);
      }
    });

    if(actionType === WorkflowNodes.AB_TEST) {
      segments[0][0].children = [...(segments[0][0].children || []), ...(root.children || [])].filter(notEnd).filter(notSplitter);
    }

    updateStyles({ ...styles, ...segmentsStyles });
    return {
      ...root,
      children: segments.map(([node]) => node),
    }
  }

  return {
    ...root,
    children: (root.children || []).map(child => updateSplitters(child, id, styles, updateStyles, undefined, type, actionType))
  }
};

const modifyIdForChannels = (bestChannelnodeData) => {
  const updatedChannelsId = bestChannelnodeData?.data?.channels?.map((channel) => {
    const newId = uuidv4();

    return {
      ...channel,
      id: newId,
      data: {
        ...channel.data,
        id: newId
      },
      cloneOf: channel.id,
    }
  });

  return updatedChannelsId;
};

const cloneChildren = (children) => {
  return (children || []).map(child => {
    const newId = uuidv4();
    const isBestChannel = child.type === WorkflowNodes.BEST_CHANNEL_TO_SEND;
    const updatedChannels = isBestChannel ? modifyIdForChannels(child) : {};

    const additionalData = isBestChannel ? {
      channels: updatedChannels
    } : {};

    return {
      ...child,
      id: newId,
      data: {
        ...child.data,
        id: newId,
        cloneOf: child.id,
        ...additionalData,
      },
      children: cloneChildren(child.children),
    };
  })
}

export const updateBestchannelIds = (root) => {
  if (!root) {
    return null;
  }
  const isBestChannel = root.type === WorkflowNodes.BEST_CHANNEL_TO_SEND;
  const updatedChannels = isBestChannel ? modifyIdForChannels(root) : {};
  const additionalData = isBestChannel ? {
    channels: updatedChannels
  } : {};

  return {
    ...root,
    data: root.data === null ? null : {
      ...root.data,
      ...additionalData
    },
    children: root?.children.map((data) => updateBestchannelIds(data)),
  }
};

export const unwrapArrays = (root, skipClone) => {
  if (!root) {
    return null;
  }

  if (root.data?.children) {
    return unwrapArrays({
      ...root,
      type: null,
      data: null,
      id: null,
      children: root.data.children.map((child, index) => ({
        ...child,
        id: index === 0 ? root.id : child.id,
        data: {
          ...child.data,
          id: index === 0 ? root.id : child.id,
          renderId: index === 0 ? root.renderId : child.renderId,
          serverId: index === 0 ? root.serverId : child.serverId,
          cloneOf: index === 0 ? void 0 : root.id,
          originId: index === 0 ? root.originId : child.originId,
        },
        children: (index === 0 || skipClone) ? root.children : cloneChildren(root.children),
      })),
    })
  }

  if (root.before) {
    const bf = root.before;
    bf.children = root;
    root.before = void 0;

    return unwrapArrays(root.before);
  }

  return {
    ...root,
    children: (root.children || []).map(child => {
      if (child.data?.serverId) {
        return { ...child, id: child.data.serverId, data: { ...child.data, renderId: child.data.id, id: child.data.serverId } };
      }

      return child;
    }),
  };
};

const fieldMap = {
  [WorkflowNodes.EMAIL]: 'email_id',
  [WorkflowNodes.SEND_EMAIL]: 'email_id',
  [WorkflowNodes.SEND_SMS]: 'sms_id',
  [WorkflowNodes.WEBPUSH]: 'webpush_id',
  [WorkflowNodes.MOBILE_PUSH]: 'mobile_push_id',
  [WorkflowNodes.API_REQUEST]: 'api_request_id',
  [WorkflowNodes.VIBER]: 'template_id',
}

export const removeDataFields = (root, idMap, onUpdate = identity) => {
  if (root.type in idMap) {
    const updateAt = fieldMap[root.type];

    if (!~idMap[root.type].indexOf(root.data?.[updateAt])) {
      return {
        ...root,
        children: (root.children || []).map(child => removeDataFields(child, idMap, onUpdate)),
      };
    }

    onUpdate(root.id);

    return {
      ...root,
      data: {
        ...(root.data || {}),
        [fieldMap[root.type]]: undefined,
      },
      children: (root.children || []).map(child => removeDataFields(child, idMap, onUpdate)),
    };
  }

  return {
    ...root,
    children: (root.children || []).map(child => removeDataFields(child, idMap, onUpdate)),
  };
};
