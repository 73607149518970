import styled, { css } from 'styled-components';

import { extract, styledProp } from '@utils';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  //max-height: 17px;
  border-radius: 13px;
`;

export const SearchContainer = styled.div`
  padding: 15px 15px 0 15px;
`;

export const FilteredContainer = styled.div`
  padding: 0 20px 20px 20px;
  min-height: 210px;
  overflow-y: scroll;
  max-width: ${extract('$mw')}px;
  ${styledProp('$isDefault', css`
    max-height: 280px;
    max-width: ${extract('$mw')}px;
  `, css`
    min-width: 576px;
  `)}
  ${styledProp('empty', `
    display: flex;
    justify-content: center;
  `)}
`;

export const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const Group = styled.div`
  margin-top: 20px;
`;

export const GroupTitle = styled.div`
  font-size: 13px;
  line-height: 120%;
  color: #909399;
`;

export const GroupItem = styled.div`
  margin-top: 8px;
  cursor: pointer;
`;

export const Header = styled.div`
  background: #F8FAFE;
  //padding-top: 7px;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
`;

export const Content = styled.div`
  display: flex;
  max-width: ${extract('$mw')}px;
  min-height: 200px;
  flex: 1;
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
  overflow: hidden;
  transition: height 330ms ease;
  
  ${styledProp('$isDefault', css`
    max-height: 280px;
    max-width: ${extract('$mw')}px;
  `, css`
    min-width: 576px;
  `)}
  
  ${styledProp('hidePreview', css`
    width: auto;
  `)}
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  font-size: 13px;
  line-height: 120%;
  text-align: left;
  border-left: 1px solid #DCDFE6;
   flex: 1;

  ${styledProp('$minWidth', css`
    min-width: ${extract('$minWidth')}px;
  `)}
`;

export const Description = styled.div`
  padding: 0 16px;
  font-size: 13px;
  line-height: 120%;
  color: #909399;
  text-align: center;
`;

export const CreateAggregateButton = styled.div`
  cursor: pointer;
  background: #D3DBE7;
  border-radius: 7px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #303133;
  padding: 6px 17px;
`;

export const Tab = styled.div`
  cursor: pointer;
  transition: 330ms ease all;
  font-family: Manrope-SemiBold;

  ${styledProp('selected', css`
    color: #31C447;
  `)}
    
    ${styledProp('disabled', css`
        color: var(--tabs-tabs-diasbled, #A1B3C8);
    `)}
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: relative;
  border-bottom: 1px solid #DCDFE6;
  padding: 0 18px 14px 18px;
  
  ${styledProp('disabled', `
    opacity: .5;
    pointer-events: none;
  `)}

  ${styledProp('$js', css`
    justify-content: flex-start;
  `)}
`;
export const TabIndicator = styled.div`
  position: absolute;
  transition: 330ms ease all;
  background: #31C447;
  height: 3px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 0;

  ${({ tab }) => {
    return css`
      width: ${tab?.getBoundingClientRect?.()?.width}px;
      left: ${tab?.offsetLeft}px;
    `;
  }}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: ${extract('$mw')}px;
  max-width: ${extract('$mw')}px;
  transition: all 330ms ease;

  ${styledProp('hidePreview', css`
    //min-width: auto;
  `)}

  box-sizing: border-box;
  border-radius: 13px;
  max-height: 460px;
  
  ${styledProp('$isDefault', css`
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
    border-radius: 13px;
    max-height: 300px;
    margin: 20px;
  `, css`
    height: 460px;
  `)}
`;
export const Type = styled.div`
  padding: 9px 9px 9px 13px;
  cursor: pointer;
  
  &:hover {
    background: #F0F2F6;
  }
`;
