import { by, extract } from '@utils';

export const SEGMENT_FILTER_TYPE_LINEAR = 'linear';
export const SEGMENT_FILTER_TYPE_ARRAY = 'array';
export const SEGMENT_FILTER_TYPE_EVENT = 'event';
export const SEGMENT_FILTER_TYPE_AGGREGATE = 'aggregate';
export const SEGMENT_FILTER_TYPE_EXPRESSION = 'expression';

export const SEGMENT_FILTER_TYPE_RFM_SEGMENT = 'rfm-segment';
export const SEGMENT_FILTER_TYPE_UNSUBSCRIBE_GROUP = 'unsubscribe-group';

export const SEGMENT_FILTER_TYPE_MAP = [
  SEGMENT_FILTER_TYPE_LINEAR,
  SEGMENT_FILTER_TYPE_ARRAY,
  SEGMENT_FILTER_TYPE_UNSUBSCRIBE_GROUP,
  SEGMENT_FILTER_TYPE_EVENT,
  SEGMENT_FILTER_TYPE_AGGREGATE,
  SEGMENT_FILTER_TYPE_RFM_SEGMENT,
  // SEGMENT_FILTER_TYPE_EXPRESSION,
]

export const GET_SEGMENT_FILTER_LABEL_MAP = (p) => ({
  [SEGMENT_FILTER_TYPE_LINEAR]: p('linear'),
  [SEGMENT_FILTER_TYPE_ARRAY]: p('array'),
  [SEGMENT_FILTER_TYPE_EVENT]: p('event'),
  [SEGMENT_FILTER_TYPE_AGGREGATE]: p('aggregate'),
  [SEGMENT_FILTER_TYPE_EXPRESSION]: p('expression'),
  [SEGMENT_FILTER_TYPE_RFM_SEGMENT]: p('rfm-segments'),
  [SEGMENT_FILTER_TYPE_UNSUBSCRIBE_GROUP]: p('unsubscribe-group'),
  'custom_event': p('event'),
  'custom_array': p('array'),
  'custom-events': p('custom_events'),
  'system-events': p('system_events'),
})

export const SegmentsFilterOperators = {
  AND: 'and',
  OR: 'or',
}

export const AggregateOperatorOptions = (p) => [
  {
    value: 'and',
    label: p('matches_all'),
  },
  {
    value: 'or',
    label: p('matches_any'),
  },
]

export const SegmentEventOperatorOptions = (p) => [
  {
    value: 'and',
    label: p('matches_all'),
  },
  {
    value: 'or',
    label: p('matches_any'),
  },
  {
    value: 'was_performed',
    label: p('has_been_performed'),
  },
];

export const FunnelOperatorOptions = (p) => [
  {
    value: 'and',
    label: p('matches_all'),
  },
  {
    value: 'or',
    label: p('matches_any'),
  },
]

export const TYPE_NUMERIC = 'numeric';
export const TYPE_TEXT = 'text';
export const TYPE_BOOLEAN = 'boolean';
export const TYPE_ARRAY = 'array';
export const TYPE_DATETIME = 'datetime';
export const TYPE_DATE = 'date';
export const TYPE_COMMON = 'common';

export const TYPES_MAP = [TYPE_NUMERIC, TYPE_TEXT, TYPE_BOOLEAN, TYPE_DATETIME, TYPE_COMMON];

export const OPERATOR_EQUALS = 'equals';
export const OPERATOR_DOES_NOT_EQUAL = 'does_not_equal';
export const OPERATOR_EXISTS = 'exists';
export const OPERATOR_NOT_EXISTS = 'not_exists';
export const OPERATOR_NULL = 'null';
export const OPERATOR_NOT_NULL = 'not_null';
export const OPERATOR_GREATER_THAN = 'greater_than';
export const OPERATOR_LESS_THAN = 'less_than';
export const OPERATOR_BETWEEN = 'between';
export const OPERATOR_NOT_BETWEEN = 'not_between';
export const OPERATOR_ONE_OF = 'one_of';
export const OPERATOR_NOT_ONE_OF = 'not_one_of';
export const OPERATOR_CONTAINS = 'contains';
export const OPERATOR_NOT_CONTAINS = 'not_contains';
export const OPERATOR_STARTS_WITH = 'starts_with';
export const OPERATOR_NOT_STARTS_WITH = 'not_starts_with';
export const OPERATOR_ENDS_WITH = 'ends_with';
export const OPERATOR_NOT_ENDS_WITH = 'not_ends_with';
export const OPERATOR_MATCHES_REGEXP = 'matches_regexp';
export const OPERATOR_NOT_MATCHES_REGEXP = 'not_matches_regexp';
export const OPERATOR_TRUE = 'true';
export const OPERATOR_FALSE = 'false';
export const OPERATOR_MORE_THAN = 'more_than';
export const OPERATOR_LESS_THAN_OR_EQUAL = 'less_than_or_equal';
export const OPERATOR_MATCHES_RANGE = 'matches_range';
export const OPERATOR_MATCHES_CURRENT = 'matches_current';
export const CONDITION_TYPE = 'condition_type';
export const ABSOLUTE_RANGE = 'absolute';
export const RELATIVE_RANGE = 'relative';
export const FILTER_DATE = 'filter';
export const RANGE = 'range';
export const GAP = 'gap';
export const UNIT = 'unit';
export const QUANTITY = 'quantity';
export const TYPE = 'type';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const WEEKS = 'weeks';
export const WEEK_DAYS = 'week_days';
export const MONTHS = 'months';
export const YEARS = 'years';
export const LAST = 'last';
export const NEXT = 'next';
export const BEFORE = 'before';
export const AFTER = 'after';
export const MONDAY = 1;
export const SUNDAY = 0;
export const COMMON_OPERATORS = [OPERATOR_NULL, OPERATOR_NOT_NULL, OPERATOR_EXISTS, OPERATOR_NOT_EXISTS];
export const TEXT_OPERATORS = [OPERATOR_EQUALS, OPERATOR_DOES_NOT_EQUAL, OPERATOR_CONTAINS, OPERATOR_NOT_CONTAINS, OPERATOR_STARTS_WITH, OPERATOR_NOT_STARTS_WITH, OPERATOR_ENDS_WITH, OPERATOR_NOT_ENDS_WITH, OPERATOR_MATCHES_REGEXP, OPERATOR_NOT_MATCHES_REGEXP, OPERATOR_ONE_OF, OPERATOR_NOT_ONE_OF];
export const NUMERIC_OPERATORS = [OPERATOR_EQUALS, OPERATOR_DOES_NOT_EQUAL, OPERATOR_GREATER_THAN, OPERATOR_LESS_THAN, OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN, OPERATOR_ONE_OF, OPERATOR_NOT_ONE_OF];
export const BOOLEAN_OPERATORS = [OPERATOR_TRUE, OPERATOR_FALSE];

export const DATETIME_OPERATORS = [OPERATOR_MATCHES_RANGE, OPERATOR_MATCHES_CURRENT, OPERATOR_MORE_THAN, OPERATOR_LESS_THAN_OR_EQUAL];
export const DATETIME_DATE_PICKER_OPERATORS = [OPERATOR_MATCHES_RANGE, OPERATOR_MORE_THAN, OPERATOR_LESS_THAN_OR_EQUAL];
export const DATETIME_SELECT_UNIT_OPERATORS = [OPERATOR_MATCHES_CURRENT];

export const WITHOUT_VALUE_OPERATORS = [OPERATOR_NULL, OPERATOR_NOT_NULL, OPERATOR_FALSE, OPERATOR_TRUE, OPERATOR_EXISTS, OPERATOR_NOT_EXISTS];
export const MULTIPLE_VALUE_OPERATORS = [OPERATOR_ONE_OF, OPERATOR_NOT_ONE_OF];
export const TWO_VALUE_OPERATORS = [OPERATOR_BETWEEN, OPERATOR_NOT_BETWEEN];
export const SINGLE_VALUE_OPERATORS = [OPERATOR_EQUALS, OPERATOR_DOES_NOT_EQUAL, OPERATOR_GREATER_THAN, OPERATOR_LESS_THAN, OPERATOR_CONTAINS, OPERATOR_NOT_CONTAINS, OPERATOR_STARTS_WITH, OPERATOR_NOT_STARTS_WITH, OPERATOR_ENDS_WITH, OPERATOR_NOT_ENDS_WITH, OPERATOR_MATCHES_REGEXP, OPERATOR_NOT_MATCHES_REGEXP];
export const SELECT_GROUPS = (p) => [
  {
    name: TYPE_NUMERIC,
    types: [TYPE_NUMERIC],
    title: p('numeric'),
    options: [
      {
        value: OPERATOR_EQUALS,
        label: p('equals'),
      },
      {
        value: OPERATOR_DOES_NOT_EQUAL,
        label: p('does_not_equal'),
      },
      {
        value: OPERATOR_GREATER_THAN,
        label: p('greater_than'),
      },
      {
        value: OPERATOR_LESS_THAN,
        label: p('less_than'),
      },
      {
        value: OPERATOR_BETWEEN,
        label: p('between'),
      },
      {
        value: OPERATOR_NOT_BETWEEN,
        label: p('is_not_between'),
      },
      {
        value: OPERATOR_ONE_OF,
        label: p('one_of'),
      },
      {
        value: OPERATOR_NOT_ONE_OF,
        label: p('not_one_of'),
      },
      {
        value: OPERATOR_CONTAINS,
        label: p('contains'),
      },
      {
        value: OPERATOR_NOT_CONTAINS,
        label: p('not_contains'),
      },
      {
        value: OPERATOR_STARTS_WITH,
        label: p('starts_with'),
      },
      {
        value: OPERATOR_NOT_STARTS_WITH,
        label: p('not_starts_with'),
      },
      {
        value: OPERATOR_ENDS_WITH,
        label: p('ends_with'),
      },
      {
        value: OPERATOR_NOT_ENDS_WITH,
        label: p('not_ends_with'),
      },
      {
        value: OPERATOR_MATCHES_REGEXP,
        label: p('matches_regexp'),
      },
      {
        value: OPERATOR_NOT_MATCHES_REGEXP,
        label: p('not_matches_regexp'),
      },
    ],
  },
  {
    name: TYPE_DATETIME,
    types: [TYPE_DATETIME, TYPE_DATE],
    title: p('datetime'),
    options: [
      {
        value: OPERATOR_MATCHES_RANGE,
        label: p('matches_range'),
      },
      {
        value: OPERATOR_MATCHES_CURRENT,
        label: p('matches_current'),
      },
      {
        value: OPERATOR_MORE_THAN,
        label: p('more_than'),
      },
      {
        value: OPERATOR_LESS_THAN_OR_EQUAL,
        label: p('less_than_or_equal'),
      },
    ],
  },
  {
    name: TYPE_COMMON,
    types: [TYPE_COMMON],
    hide: true,
    title: p('common'),
    options: [
      {
        value: OPERATOR_EXISTS,
        label: p('exists'),
        hide: true,
      },
      {
        value: OPERATOR_NOT_EXISTS,
        label: p('not_exists'),
        hide: true,
      },
      {
        value: OPERATOR_NULL,
        label: p('null'),
        hide: true,
      },
      {
        value: OPERATOR_NOT_NULL,
        label: p('not_null'),
        hide: true,
      },
    ],
  },
  {
    name: TYPE_TEXT,
    types: [TYPE_TEXT],
    title: p('text'),
    options: [
      {
        value: OPERATOR_EQUALS,
        label: p('equals'),
      },
      {
        value: OPERATOR_DOES_NOT_EQUAL,
        label: p('does_not_equal'),
      },
      {
        value: OPERATOR_ONE_OF,
        label: p('one_of'),
      },
      {
        value: OPERATOR_NOT_ONE_OF,
        label: p('not_one_of'),
      },
      {
        value: OPERATOR_CONTAINS,
        label: p('contains'),
      },
      {
        value: OPERATOR_NOT_CONTAINS,
        label: p('not_contains'),
      },
      {
        value: OPERATOR_STARTS_WITH,
        label: p('starts_with'),
      },
      {
        value: OPERATOR_NOT_STARTS_WITH,
        label: p('not_starts_with'),
      },
      {
        value: OPERATOR_ENDS_WITH,
        label: p('ends_with'),
      },
      {
        value: OPERATOR_NOT_ENDS_WITH,
        label: p('not_ends_with'),
      },
      {
        value: OPERATOR_MATCHES_REGEXP,
        label: p('matches_regexp'),
      },
      {
        value: OPERATOR_NOT_MATCHES_REGEXP,
        label: p('not_matches_regexp'),
      },
    ],
  },
  {
    name: TYPE_BOOLEAN,
    types: [TYPE_BOOLEAN],
    title: p('boolean'),
    options: [
      {
        value: OPERATOR_TRUE,
        label: p('true'),
      },
      {
        value: OPERATOR_FALSE,
        label: p('false'),
      },
    ],
  },
  {
    name: TYPE_ARRAY,
    types: [TYPE_ARRAY],
    title: p('array'),
    options: [
      {
        value: OPERATOR_ONE_OF,
        label: p('one_of'),
      },
      {
        value: OPERATOR_NOT_ONE_OF,
        label: p('not_one_of'),
      },
    ],
  },
];
export const COMMON_OPTIONS = [
  {
    value: OPERATOR_NULL,
    label: 'Null',
    name: TYPE_COMMON,
  },
  {
    value: OPERATOR_EXISTS,
    label: 'Exists',
    name: TYPE_COMMON,
  },
  {
    value: OPERATOR_NOT_NULL,
    label: 'Not null',
    name: TYPE_COMMON
  },
  {
    value: OPERATOR_NOT_EXISTS,
    label: 'Not exists',
    name: TYPE_COMMON,
  }
];
export const negationSelector = [
  { value: false, label: 'included' },
  { value: true, label: 'excluded' },
]

export const AGGREGATE_TYPE_ARRAY = 2;
export const AGGREGATE_TYPE_EVENT = 1;
export const AGGREGATE_TYPE_EVENT_NESTED = 3;
export const AGGREGATE_TYPE_ARRAY_NESTED = 4;

export const FUNNEL_TYPE_ARRAY = 2;
export const FUNNEL_TYPE_EVENT = 1;

export const AGGREGATE_TYPE_MAP = [AGGREGATE_TYPE_ARRAY, AGGREGATE_TYPE_EVENT]

export const AGGREGATE_TYPE_LABEL_MAP = {
  [AGGREGATE_TYPE_ARRAY]: 'Array',
  [AGGREGATE_TYPE_EVENT]: 'Event',
}

export const AGGREGATE_FUNCTION_DISTINCT_VALUES = 'distinct_values';
export const AGGREGATE_FUNCTION_COUNT_DISTINCT = 'count_distinct';
// export const AGGREGATE_FUNCTION_LEAST_COMMON = 'least_common';
export const AGGREGATE_FUNCTION_MOST_COMMON = 'most_common';
export const AGGREGATE_FUNCTION_LAST = 'last';
export const AGGREGATE_FUNCTION_FIRST = 'first';
export const AGGREGATE_FUNCTION_MEDIAN = 'median';
export const AGGREGATE_FUNCTION_AVG = 'avg';
export const AGGREGATE_FUNCTION_MAX = 'max';
export const AGGREGATE_FUNCTION_MIN = 'min';
export const AGGREGATE_FUNCTION_COUNT = 'count';
export const AGGREGATE_FUNCTION_SUM = 'sum';
export const AGGREGATE_FUNCTION_GGR = 'ggr';

export const AGGREGATE_FUNCTION_MAP = [
  // AGGREGATE_FUNCTION_DISTINCT_VALUES,
  // AGGREGATE_FUNCTION_COUNT_DISTINCT,
  // AGGREGATE_FUNCTION_LEAST_COMMON,
  AGGREGATE_FUNCTION_MOST_COMMON,
  AGGREGATE_FUNCTION_LAST,
  AGGREGATE_FUNCTION_FIRST,
  // AGGREGATE_FUNCTION_MEDIAN,
  AGGREGATE_FUNCTION_AVG,
  AGGREGATE_FUNCTION_MAX,
  AGGREGATE_FUNCTION_MIN,
  AGGREGATE_FUNCTION_COUNT,
  AGGREGATE_FUNCTION_SUM,
  AGGREGATE_FUNCTION_GGR,
]

export const AGGREGATE_FUNCTION_LABEL_MAP = (p) => ({
  // [AGGREGATE_FUNCTION_DISTINCT_VALUES]:  p('distinct_values_function'),
  [AGGREGATE_FUNCTION_COUNT_DISTINCT]: p('count_distinct_function'),
  // [AGGREGATE_FUNCTION_LEAST_COMMON]: p('least_common_function'),
  [AGGREGATE_FUNCTION_MOST_COMMON]: p('most_common_function'),
  [AGGREGATE_FUNCTION_LAST]: p('last_Function'),
  [AGGREGATE_FUNCTION_FIRST]: p('first_function'),
  [AGGREGATE_FUNCTION_MEDIAN]: p('median_function'),
  [AGGREGATE_FUNCTION_AVG]: p('avg_function'),
  [AGGREGATE_FUNCTION_MAX]: p('max_function'),
  [AGGREGATE_FUNCTION_MIN]: p('min_function'),
  [AGGREGATE_FUNCTION_COUNT]: p('count_function'),
  [AGGREGATE_FUNCTION_SUM]: p('sum_function'),
  [AGGREGATE_FUNCTION_GGR]: 'GGR',
})

export const AGGREGATE_FUNCTION_TYPE_MAP = {
  // [AGGREGATE_FUNCTION_DISTINCT_VALUES]: 'Distinct values',
  [AGGREGATE_FUNCTION_COUNT_DISTINCT]: [TYPE_TEXT, TYPE_NUMERIC, TYPE_BOOLEAN, TYPE_DATETIME],
  // [AGGREGATE_FUNCTION_LEAST_COMMON]: [TYPE_TEXT, TYPE_NUMERIC, TYPE_BOOLEAN, TYPE_DATETIME],
  [AGGREGATE_FUNCTION_MOST_COMMON]: [TYPE_TEXT, TYPE_NUMERIC, TYPE_BOOLEAN, TYPE_DATETIME],
  [AGGREGATE_FUNCTION_LAST]: [TYPE_TEXT, TYPE_NUMERIC, TYPE_BOOLEAN, TYPE_DATETIME],
  [AGGREGATE_FUNCTION_FIRST]: [TYPE_TEXT, TYPE_NUMERIC, TYPE_BOOLEAN, TYPE_DATETIME],
  [AGGREGATE_FUNCTION_MEDIAN]: [TYPE_NUMERIC],
  [AGGREGATE_FUNCTION_AVG]: [TYPE_NUMERIC],
  [AGGREGATE_FUNCTION_MAX]: [TYPE_NUMERIC],
  [AGGREGATE_FUNCTION_MIN]: [TYPE_NUMERIC],
  [AGGREGATE_FUNCTION_COUNT]: [TYPE_TEXT, TYPE_NUMERIC, TYPE_BOOLEAN, TYPE_DATETIME],
  [AGGREGATE_FUNCTION_SUM]: [TYPE_NUMERIC],
  [AGGREGATE_FUNCTION_GGR]: [TYPE_NUMERIC],
}

export const resolveAggregateType = ({ aggregate, attribute, nested_field, nestedField, field, filters }, fields = [], events = []) => {
  const id = field === '_events' ? 'name' : 'field';
  const nf = nestedField || nested_field;
  const collection = field === '_events' ? events : fields;
  const filterField = field === '_events' ? (filters || []).find(extract('hide'))?.value?.value : field;

  const getFieldType = () => {
    const e = collection.find(by(id, filterField));
    const f = (e?.payload || []).find(by('field', attribute));
    const fna = (e?.nested_aggregates || []).find(by('name', attribute));

    if (nf) {
      const ff = (e?.payload || []).find(by('field', nf));

      return (ff?.payload || []).find(by('field', attribute))?.type;
    }

    if (fna) {
      return resolveAggregateType(fna, fields, events);
    }

    return f?.type;
  };

  return {
    [AGGREGATE_FUNCTION_AVG]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_SUM]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_COUNT]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_COUNT_DISTINCT]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_MAX]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_MIN]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_MEDIAN]: () => TYPE_NUMERIC,
    [AGGREGATE_FUNCTION_GGR]: () => TYPE_NUMERIC,
    ['ngr_bonus']: () => TYPE_NUMERIC,
    ['bonus_deposit']: () => TYPE_NUMERIC,
    ['deposit_withdrawal']: () => TYPE_NUMERIC,
    ['active_days']: () => TYPE_NUMERIC,
    // [AGGREGATE_FUNCTION_LEAST_COMMON]: getFieldType,
    [AGGREGATE_FUNCTION_MOST_COMMON]: getFieldType,
    [AGGREGATE_FUNCTION_LAST]: getFieldType,
    [AGGREGATE_FUNCTION_FIRST]: getFieldType,
  }[aggregate]?.();
};
