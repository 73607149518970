import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    gap: 5px;
    transition: all 0.3s ease-in-out;
    ${styledProp('$sidebarOpened', css`
        padding: 10px;
    `)}
`;

export const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
    background-color: #1A232E;
`

export const TimerItem = styled.div`
    font-family: Manrope-Medium;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    text-align: left;
    color: #7C94B1;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    ${styledProp('$isTooltip', css`
        color: #fff;
        font-size: 12px;
    `)}
`

export const TooltipWrapper = styled.div`
    overflow: hidden;
    div[class*="tooltip-content"] {
        background-color: #1A232E;
    }
`