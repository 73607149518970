import { ContentCardStatuses } from "@constants/contentCardsStatuses";
import { EmailStatuses } from "@constants/emailStatuses";
import { MobilePushStatuses } from "@constants/mobilePushStatuses";
import { SmsStatuses } from "@constants/smsStatuses";
import { ViberStatuses } from "@constants/viberStatuses";
import { WebhookStatuses } from "@constants/webhookStatuses";
import { WebPopupStatuses } from "@constants/webPopupStatuses";
import { WebpushStatuses } from "@constants/webpushStatuses";

import { NodeName } from "@/constants/flow";

export const EMAIL_TEMPLATE_EXAMPLE_ID_PREFIX = 'example';

export const TemplateTypes = {
  SMS: 'sms',
  EMAIL: 'email',
  WEB_PUSH: 'web_push',
  MOBILE_PUSH: 'mobile_push',
  VIBER: 'viber',
  WEBHOOK: 'webhook',
  MESSAGE_FEED: 'message_feed',
  SLACK: 'slack',
  WEB_POPUP: 'web_popup'
};

export enum TemplateType {
  SMS = 'sms',
  EMAIL = 'email',
  WEB_PUSH = 'web_push',
  MOBILE_PUSH = 'mobile_push',
  VIBER = 'viber',
  WEBHOOK = 'webhook',
  MESSAGE_FEED = 'message_feed',
  WEB_POPUP = 'web_popup'
}

export const NodeNameTemplateTypeMap = {
  [NodeName.SEND_SMS]: TemplateType.SMS,
  [NodeName.SEND_EMAIL]: TemplateType.EMAIL,
  [NodeName.MOBILE_PUSH]: TemplateType.MOBILE_PUSH,
  [NodeName.WEBPUSH]: TemplateType.WEB_PUSH,
  [NodeName.MESSAGE_FEED]: TemplateType.MESSAGE_FEED,
  [NodeName.WEB_POPUP]: TemplateType.WEB_POPUP,
  [NodeName.VIBER]: TemplateType.VIBER,
  [NodeName.API_REQUEST]: TemplateType.WEBHOOK,
};

export const NodeNameTemplateFieldMap = {
  [NodeName.SEND_SMS]: 'sms_id',
  [NodeName.SEND_EMAIL]: 'email_id',
  [NodeName.MOBILE_PUSH]: 'mobile_push_id',
  [NodeName.WEBPUSH]: 'webpush_id',
  [NodeName.MESSAGE_FEED]: 'content_card_id',
  [NodeName.WEB_POPUP]: 'web_popup_id',
  [NodeName.VIBER]: 'viber_id',
  [NodeName.API_REQUEST]: 'api_request_id',
};

export const NodeNameAnalyticsTypeMap = {
  [NodeName.SEND_SMS]: TemplateType.SMS,
  [NodeName.SEND_EMAIL]: TemplateType.EMAIL,
  [NodeName.MOBILE_PUSH]: TemplateType.MOBILE_PUSH,
  [NodeName.WEBPUSH]: TemplateType.WEB_PUSH,
  [NodeName.MESSAGE_FEED]: TemplateType.MESSAGE_FEED,
  [NodeName.WEB_POPUP]: TemplateType.WEB_POPUP,
  [NodeName.VIBER]: TemplateType.VIBER,
  [NodeName.API_REQUEST]: TemplateType.WEBHOOK,
  [NodeName.TEAM_MESSAGE]: TemplateType.EMAIL,
};

export const TemplateTypeStatusesMap = {
  [TemplateType.SMS]: SmsStatuses,
  [TemplateType.EMAIL]: EmailStatuses,
  [TemplateType.MOBILE_PUSH]: MobilePushStatuses,
  [TemplateType.WEB_PUSH]: WebpushStatuses,
  [TemplateType.MESSAGE_FEED]: ContentCardStatuses,
  [TemplateType.WEB_POPUP]: WebPopupStatuses,
  [TemplateType.VIBER]: ViberStatuses,
  [TemplateType.WEBHOOK]: WebhookStatuses,
}

export enum EmailTemplateDriver {
  VCE = 'vce',
  CODE = 'code'
}

export type DynamicVariable = {
  id: number;
  name: string;
  label: string;
  default_value: string;
};

export type BaseTemplateOptionData = {
  app_integration_id: number;
  name: string;
  id: number;
  dynamicVariables: Array<DynamicVariable>
}

export type MultiLanguageEntity<T, L> = T & {
  languages: Array<{
      is_default: boolean;
      language_code: null | string;
    } & L>;
}

export type SmsOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  data: string;
}>;

export type EmailOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  data: string;
  subject: string;
  template_driver: EmailTemplateDriver;
}>;

export type ViberOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  data: string;
  image_url?: string;
}>;

export type WebPushOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  title: string;
  body: string;
  actions?: Array<{
    title: string;
  }>;
  image?: {
    url: string;
  };
}>;

export type MobilePushOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  title: string;
  subtitle?: string;
  message: string;
  actions?: Array<{
    title: string;
  }>;
  image?: {
    url: string;
  };
}>;

export type MessageFeedOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  image?: {
    url: string;
  };
  image_url?: string;
  is_default: boolean;
  link_text?: string;
  link_type?: string;
  link_url?: string;
  message: string;
  pin_to_top: boolean;
  title: string
  type: string;
}>;

export type WebPopupOptionData = MultiLanguageEntity<BaseTemplateOptionData, {
  message?: string;
  title?: string;
  layout?: string;
  type?: string;
  code?: string;
}>;

export type WebhookOptionData = BaseTemplateOptionData & {
  body: string;
  headers: string;
  method: string;
  url: string;
};

export type TemplateOptionData = WebhookOptionData | WebPopupOptionData |
  MessageFeedOptionData | MobilePushOptionData | WebPushOptionData
  | ViberOptionData | EmailOptionData | SmsOptionData;

export type TemplateMappedOption = {
  label: string;
  value: number;
  data: TemplateOptionData;
}
