import { useEffect, useState } from 'react';

import { Dropdown, Tooltip } from 'antd';

import { useTranslation } from '@hooks';
import useAutocomplete from '@hooks/useAutocomplete';
import useWorkflowOptionsAutocomplete from '@hooks/useWorkflowOptionsAutocomplete';

import { by, clamp } from '@utils';

import { Icon } from '@components';
import { Error } from '@components/inputs/AutoComplete/styled';
import { IconContainer } from '@components/inputs/Select/styled';

import { Tag } from './components';
import {
  InputContainer,
  StyledSelect,
  Title,
  TitleRow,
  Overlay,
  OverlayTitle,
  OverlayValues,
  OverlayTag
} from './styled';

const TagAutoComplete = ({ testId, className = '', options, value, smaller, arrowStyles = {}, onChange, title, containerStyle, suggestionsOptions, error, getPopupContainer, eventSubFilter, isStepper, ...props }) => {
  const [focused, setFocused] = useState(false);
  const { t, e } = useTranslation();
  const [search, setSearch] = useState('');
  const autocomplete = useAutocomplete({ ...(suggestionsOptions || {}), query: search });
  const workflowOptionsAutocomplete = useWorkflowOptionsAutocomplete({ ...(suggestionsOptions || {}), query: value });

  const suggestions = workflowOptionsAutocomplete.suggestions?.length ? workflowOptionsAutocomplete.suggestions : autocomplete.suggestions;
  const loadMore = workflowOptionsAutocomplete.suggestions?.length ? workflowOptionsAutocomplete.loadMore : autocomplete.loadMore;

  useEffect(() => {
    if (!focused || !suggestionsOptions) {
      return;
    }

    autocomplete.dispatcher();
  }, [(value || []).join('.'), focused, !!suggestionsOptions]);

  const handleSearch = (s) => {
    setSearch(s);
  }

  const processTags = (name) => {
    return name.split(/[,;\n]/g).filter(tag => !!tag?.trim?.()).map(tag => tag.trim ? tag.trim() : tag);
  };

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');

    onChange(value.concat(processTags(pastedData.replace(/\r?\n|\r/g, ','))));
  };

  const handleFocus = (e) => {
    props.onFocus?.(e);
    setFocused(true);
  };

  const handleBlur = (e) => {
    props.onBlur?.(e);
    setFocused(false);
  }

  const getSuggestions = () => {
    const opt = options || (suggestions || []).map(o => ({ ...o, label: t(o.label) }));
    return opt.map(({ value, label }) => ({ value, label: t(String(label)) })).filter(({ value }) => value !== '');
  };

  const getAllOptions = () => {
    const opt = options || suggestions;

    return opt.map(({ value, label }) => ({ value, label: t(String(label)) }));
  }

  const tagRender = ({ value: v, closable, label }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleClose = () => {
      onChange(value.filter(vv => vv !== v));
    };

    return (
      <Tag
        key={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onDelete={handleClose}
        style={{ marginRight: 3, borderRadius: 6 }}
      >
        {label}
      </Tag>
    );
  };

  const popup = document.querySelector('.acp:not(.ant-select-dropdown-hidden) .rc-virtual-list-holder');
  const sortOption = (a, b) => String(a.value).toLowerCase().localeCompare(String(b.value).toLowerCase());

  useEffect(() => {
    const p = popup || document.querySelector('.acp:not(.ant-select-dropdown-hidden) .rc-virtual-list-holder');
    if (!popup) {
      return;
    }

    const listener = (e) => {
      if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
        loadMore();
      }
    };

    p?.addEventListener?.('scroll', listener);

    return () => {
      p?.removeEventListener?.('scroll', listener);
    }
  }, [popup === null, suggestions.length])

  const getMaxTagsCount = () => {
    return 0;

    // eslint-disable-next-line no-unreachable
    if (value[0]?.length > 5) {
      return 0;
    }

    const { count } = value.reduce(({ len, count }, next) => len + String(next).length >= 4 ? { len, count } : { len: String(next).length + len, count: count + 1 }, { len: 0, count: 0 });

    return clamp(count - 1, 1, 10);
  };

  const renderInput = () => {
    return (
      <InputContainer className={`tag-input-container ${className}`} style={props.style} $smaller={smaller} error={!!error}>
        <StyledSelect
          {...props}
          $smaller={smaller}
          tagRender={tagRender}
          onChange={onChange}
          value={value}
          mode="tags"
          showArrow
          showSearch
          placement={!eventSubFilter ? 'bottomRight' : ''}
          onFocus={handleFocus}
          onBlur={handleBlur}
          popupClassName={'acp'}
          onSearch={handleSearch}
          onPaste={handlePaste}
          getPopupContainer={getPopupContainer}
          maxTagPlaceholder={vs => vs.length === 1 ? vs[0]?.label : t('labels.chosen', { n: vs.length })}
          suffixIcon={(
            <IconContainer style={{ position: 'absolute', pointerEvents: 'none', top: 2, right: 6, ...arrowStyles }} visible>
              <Icon style={{ height: '6px', lineHeight: '6px', pointerEvents: 'none', marginRight: 6 }} name="Dropdown-arrow" size={10} />
            </IconContainer>
          )}
          maxTagCount={getMaxTagsCount()}
          options={(getSuggestions() || []).sort(sortOption)}
        />
        {error && (
          <Tooltip title={e(error)} placement="bottom">
            <Error $smaller={smaller} $ll>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="#C8402F"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.78412 7.79622C7.78412 8.24339 7.4203 8.60721 6.97313 8.60721C6.52596 8.60721 6.16214 8.24339 6.16214 7.79622V4.00002C6.16214 3.55284 6.52596 3.18903 6.97313 3.18903C7.4203 3.18903 7.78412 3.55284 7.78412 4.00002V7.79622ZM7.78213 9.959C7.78213 10.4062 7.41832 10.77 6.97115 10.77C6.52398 10.77 6.16016 10.4062 6.16016 9.959C6.16016 9.51183 6.52398 9.14801 6.97115 9.14801C7.41832 9.14801 7.78213 9.51183 7.78213 9.959Z" fill="white"/>
              </svg>
            </Error>
          </Tooltip>
        )}
      </InputContainer>
    );
  };

  return (
    <div style={containerStyle} data-testid={`${testId}-container`}>
      {title && (
        <TitleRow>
          <Title>
            {title}
          </Title>
        </TitleRow>
      )}
      <Dropdown
        placement="bottomCenter"
        style={{ marginLeft: (eventSubFilter || isStepper) ? '30px' : '' }}
        trigger={['hover', 'click']}
        open={((focused || !value.length) ? false : void 0)}
        getPopupContainer={getPopupContainer}
        overlay={(
          <Overlay>
            <OverlayTitle>{t('labels.chosen_values')}</OverlayTitle>
            <OverlayValues>
              {value.map((next) => (
                <OverlayTag key={next}>
                  {tagRender({ value: next, closable: true, label: (getAllOptions() || []).find(by('value', next))?.label || next })}
                </OverlayTag>
              ))}
            </OverlayValues>
          </Overlay>
        )}
      >
        {renderInput()}
      </Dropdown>
    </div>
  );
};

export default TagAutoComplete;
