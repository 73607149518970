import styled from 'styled-components';

export const StatusTitle = styled.div`
  color: ${({ color }) => color};
`;

export const TextOverlay = styled.div`
  //margin-top: 5px;
  background-color: #fff;
  padding: 16px 14px 14px 14px;
  max-width: 280px;

  word-break: break-word;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
`;

export const Container = styled.div`
  border-radius: 6px;
  background-color: #F0F2F6;
`;

export const ActionsContainer = styled.div`
  display: flex;
  height: 22px;

  & > * {
    margin-left: 6px;
  }

  & > *:first-child {
    margin-left: 0;
  }
`;

export const Info = styled.img`
  cursor: pointer;
`;
