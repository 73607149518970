import { asCreator, withCallbacks, withOrdering, withPagination } from '@store/lib';

import { compose } from '@utils';

import { IntegrationsActionTypes } from '../types';

export const listIntegrations = () => ({
  type: IntegrationsActionTypes.LIST,
});

export const myListIntegrations = () => ({
  type: IntegrationsActionTypes.MY_LIST,
});

export const removeConnectionFromStore = compose(
  asCreator(({ id }) => ({
    type: IntegrationsActionTypes.REMOVE_CONNECTION_FROM_STORE,
    payload: { id }
  }))
);

export const updateDatabaseIntegrationConnectionsMeta = () => ({
  type: IntegrationsActionTypes.UPDATE_DB_INTEGRATION_CONNECTIONS_META,
})

export const saveIntegrationConnection = compose(
  withCallbacks,
  asCreator(({ app_integration_id, config, connectionType, name, enabled, integration_key }) => ({
    type: IntegrationsActionTypes.SAVE_CONNECTION,
    meta: { app_integration_id },
    payload: { config, type: connectionType, name, enabled, integration_key }
  }))
);

export const saveOtp = compose(
  withCallbacks,
  asCreator(({ data, type }) => ({
    type: IntegrationsActionTypes.SAVE_OTP,
    payload: {
      data,
      type
    }
  }))
);

export const getJSIntegrationMeta = compose(
  withCallbacks,
  asCreator(() => ({
    type: IntegrationsActionTypes.GET_JS_INTEGRATION_META,
  }))
);

export const updateJsIntegration = compose(
  withCallbacks,
  asCreator(({ enabled, domains, name, app_integration_id, connectionType, integration_key }) => ({
    type: IntegrationsActionTypes.UPDATE_JS_INTEGRATION,
    payload: { enabled, domains, name, app_integration_id, connectionType, integration_key },
  }))
);

export const checkPostgresConnection = compose(
  withCallbacks,
  asCreator(({ port, host, database, username, password }) => ({
    type: IntegrationsActionTypes.CHECK_POSTGRES_CONNECTION,
    payload: { port, host, database, username, password },
  }))
);

export const checkMySQLConnection = compose(
  withCallbacks,
  asCreator(({ port, host, database, username, password }) => ({
    type: IntegrationsActionTypes.CHECK_MYSQL_CONNECTION,
    payload: { port, host, database, username, password },
  }))
);

export const previewPostgresConnection = compose(
  withCallbacks,
  asCreator(({ port, host, database, username, password, sql }) => ({
    type: IntegrationsActionTypes.PREVIEW_POSTGRES_CONNECTION,
    payload: { port, host, database, username, password, sql },
  }))
);

export const previewMySQLConnection = compose(
  withCallbacks,
  asCreator(({ port, host, database, username, password, sql }) => ({
    type: IntegrationsActionTypes.PREVIEW_MYSQL_CONNECTION,
    payload: { port, host, database, username, password, sql },
  }))
);

export const updatePostgresIntegration = compose(
  withCallbacks,
  asCreator(({ config_id, port, host, database, username, password, sql, app_integration_name, app_integration_id, enabled, postgresql_integration_id, schedule_type, schedule_value }) => ({
    type: IntegrationsActionTypes.UPDATE_POSTGRES_INTEGRATION,
    payload: { port, host, database, username, password, sql, app_integration_name, app_integration_id, enabled, postgresql_integration_id, schedule_type, schedule_value, config_id },
  }))
);

export const updateMysqlIntegration = compose(
  withCallbacks,
  asCreator(({ config_id, port, host, database, username, password, sql, app_integration_name, app_integration_id, enabled, mysql_integration_id, schedule_type, schedule_value }) => ({
    type: IntegrationsActionTypes.UPDATE_MYSQL_INTEGRATION,
    payload: { port, host, database, username, password, sql, app_integration_name, app_integration_id, enabled, mysql_integration_id, schedule_type, schedule_value, config_id },
  }))
);

export const clearDBRequestPreview = () => ({
  type: IntegrationsActionTypes.CLEAR_DB_REQUEST_PREVIEW,
});

export const getPostgresDatasyncLog = compose(
  withCallbacks,
  asCreator(({ entityId, live }) => ({
    type: IntegrationsActionTypes.GET_POSTGRES_DATASYNC_LOG,
    payload: { entityId },
    meta: { live }
  })),
);

export const getMysqlDatasyncLog = compose(
  withCallbacks,
  withOrdering,
  withPagination,
  asCreator(({ entityId, live }) => ({
    type: IntegrationsActionTypes.GET_MYSQL_DATASYNC_LOG,
    payload: { entityId },
    meta: { live }
  })),
);

export const generateFacebookLoginUrl = compose(
  withCallbacks,
  asCreator(({ redirect_url, state, granted_scopes, denied_scopes }) => ({
    type: IntegrationsActionTypes.GENERATE_FACEBOOK_LOGIN_URL,
    payload: { redirect_url, state, granted_scopes, denied_scopes },
  }))
);

export const generateFacebookToken = compose(
  withCallbacks,
  asCreator(({ redirect_url, code, app_integration_id, denied_scopes, granted_scopes, entity_id }) => ({
    type: IntegrationsActionTypes.GENERATE_FACEBOOK_TOKEN,
    payload: { redirect_url, code, app_integration_id, entity_id, denied_scopes, granted_scopes },
  }))
);

export const getFacebookAdAccount = compose(
  withCallbacks,
  asCreator(({ entity_id, limit, offset }) => ({
    type: IntegrationsActionTypes.GET_FACEBOOK_AD_ACCOUNT,
    payload: { entity_id, limit, offset },
  }))
);

export const saveFacebookIntegrationConnection = compose(
  withCallbacks,
  asCreator(({ enabled, app_integration_name, app_integration_id, entity_id, ad_account_id, fields_mapping }) => ({
    type: IntegrationsActionTypes.SAVE_FACEBOOK_INTEGRATION_CONNECTION,
    payload: { enabled, app_integration_name, entity_id, ad_account_id, app_integration_id, fields_mapping }
  }))
);

export const changeIntegrationConnectionStatus = compose(
  withCallbacks,
  asCreator(({ enabled, app_integration_id }) => ({
    payload: { app_integration_id, enabled },
    type: IntegrationsActionTypes.CHANGE_INTEGRATION_CONNECTION_STATUS,
  }))
);

export const deleteIntegrationConnection = compose(
  withCallbacks,
  asCreator(({ app_integration_id }) => ({
    payload: { app_integration_id },
    type: IntegrationsActionTypes.DELETE_INTEGRATION_CONNECTION,
  }))
);

export const requestFacebookLoginUrl = compose(
  withCallbacks,
  asCreator(({ redirect_url, entity_id, state }) => ({
    type: IntegrationsActionTypes.REQUEST_FACEBOOK_LOGIN_URL,
    payload: { redirect_url, entity_id, state },
  })),
);

export const logoutFromFacebook = compose(
  withCallbacks,
  asCreator(({ app_integration_id, entity_id }) => ({
    type: IntegrationsActionTypes.LOGOUT_FROM_FACEBOOK,
    payload: { app_integration_id, entity_id },
    meta: { entity_id }
  })),
);

export const getFacebookUserInfo = compose(
  withCallbacks,
  asCreator(({ entity_id }) => ({
    type: IntegrationsActionTypes.GET_FACEBOOK_USER_INFO,
    payload: { entity_id },
    meta: { entity_id }
  })),
);

export const getShortenerDomains = compose(
  withCallbacks,
  asCreator(() => ({
    type: IntegrationsActionTypes.GET_SHORTENER_DOMAINS,
  })),
);

export const saveShortenerDomains = compose(
  withCallbacks,
  asCreator(({ id, name, domain }) => ({
    type: IntegrationsActionTypes.SAVE_SHORTENER_DOMAINS,
    payload: { id, name, domain },
  }))
);

export const validateShortenerDomain = compose(
  withCallbacks,
  asCreator(({ domain }) => ({
    type: IntegrationsActionTypes.VALIDATE_SHORTENER_DOMAIN,
    payload: { domain },
  }))
)

export const getOtpIntegrations = compose(
  withCallbacks,
  asCreator(({ type }) => ({
    type: IntegrationsActionTypes.GET_OTP_INTEGRATIONS,
    meta: { type },
  }))
);
