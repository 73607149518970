import { useEffect, useState } from "react";

import { DeprecatedIcon as Icon, ICON_SIZES, DEPRECATED_ICONS_NAMES as ICONS_NAMES, PALETTES, Tooltip } from "@velitech/ui";
import moment from 'moment';

import Timezones from "@constants/timezones";

import { Container, TimerItem, TooltipContainer, TooltipWrapper } from './styled';

import { useCurrentWorkspace } from "@/hooks";

const TimerDisplay = ({ time, timezone, showIcon, isTooltip }) => (
  <TimerItem $isTooltip={isTooltip}>
    {time} {" "}
    {timezone && (
      <>
        {timezone} {" "}
        {showIcon && (
          <Icon
            name={ICONS_NAMES.Location}
            size={ICON_SIZES.S16}
            color={PALETTES.PRIMARY_500}
          />
        )}
      </>
    )}
  </TimerItem>
);
export const Timer = ({ sidebarOpened }) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const workspace = useCurrentWorkspace();
  const DEFAULT_TIMEZONE = 'UTC';
  const [currentTime, setCurrentTime] = useState(moment().tz(timeZone).format('HH:mm'));
  const [workspaceTime, setWorkspaceTime] = useState(moment().tz(workspace?.timezone || DEFAULT_TIMEZONE)?.format('HH:mm'));

  useEffect(() => {
    const interval = setInterval(() => {
      setWorkspaceTime(moment().tz(workspace?.timezone || DEFAULT_TIMEZONE).format('HH:mm'));
      setCurrentTime(moment().tz(timeZone).format('HH:mm'));
    }, 1000);

    return () => clearInterval(interval);
  }, [workspace?.timezone]);

  return (
    <TooltipWrapper>
      <Tooltip
        withArrow={false}
        className={'tooltip-content'}
        offset={15}
        placement="right"
        content={
          !sidebarOpened && (
            <TooltipContainer>
              <TimerDisplay isTooltip time={workspaceTime} timezone={Timezones[workspace?.timezone]} />
              <TimerDisplay isTooltip time={currentTime} timezone={`(${timeZone})`} showIcon />
            </TooltipContainer>
          )
        }
      >
        <Container $sidebarOpened={!sidebarOpened}>
          <TimerDisplay time={workspaceTime} timezone={sidebarOpened ? Timezones[workspace?.timezone] : null} />
          <TimerDisplay time={currentTime} timezone={sidebarOpened ? `(${timeZone})` : null} showIcon={sidebarOpened} />
        </Container>
      </Tooltip>
    </TooltipWrapper>
  )
}
