import styled, { css } from 'styled-components';

import { styledProp } from '@utils';

export const Container = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 900;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  background: transparent;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  transition: opacity 290ms ease;
  
  ${styledProp('$a', css`
    opacity: 1;
  `, css`
    opacity: 0;
  `)}
`;
