import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ draft }) => draft && css`
    color: #909399;
  `}
`;

export const WorkflowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 14px;
  border-radius: 10px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
`;

export const Workflow = styled.div`
  flex: 0;
`;

export const StatusDraftContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 5px;
    
    font-family: Manrope-SemiBold;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
`
