import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import createIndexedDBStorage from 'redux-persist-indexeddb-storage';

import storage from 'redux-persist/lib/storage';

import { ReducerKeys } from '@constants';

import accountReducer from './account';
import appReducer from './app';
import campaignsReducer from './campaigns';
import contactsReducer from './contacts';
import dashboardReducer from './dashboard';
import eventsReducer from './events';
import fieldsReducer from './fields';
import funnelsReducer from './funnels';
import historyNotificationReducer from './historyNotifications';
import integrationsReducer from './integrations';
import notificationReducer from './notifications'
import optionsReducer from './options';
import promoCodesReducer from './promoCodes';
import rfmAnalyticsReducer from './rfmAnalytics';
import rolesReducer from './roles';
import segmentsReducer from './segments';
import sqlQueryReducer from './sqlQuery';
import subscriptionGroupsReducer from './subscriptionGroups';
import templatesReducer, { initialState as initialStateTemplates } from './templates';
import userReducer from './user';
import workflowReducer from './workflow';
import workflowEditorReducer from './workflowEditor';
import workflowOptionsReducer from './workflowOptions';
import workspacesReducer from './workspaces';

const workflowOptionsStorage = createIndexedDBStorage('edgeIndexedDB', 'edgeWorkflowOptions');

const migrations = {
  1: (state) => {
    if (state[ReducerKeys.TEMPLATES]) {
      state[ReducerKeys.TEMPLATES] = {
        ...initialStateTemplates,
      };
    }
    return state;
  },
};

const rootReducer = combineReducers({
  [ReducerKeys.APP]: persistReducer(
    {
      key: ReducerKeys.APP,
      storage,
      blacklist: ['trialBarShown'],
    },
    appReducer
  ),
  [ReducerKeys.CONTACTS]: persistReducer(
    {
      key: ReducerKeys.CONTACTS,
      storage,
      blacklist: ['contacts', 'fieldSettings', 'count'],
    },
    contactsReducer
  ),
  [ReducerKeys.USER]: persistReducer(
    {
      key: ReducerKeys.USER,
      storage,
      blacklist: ['twoFA', 'refreshing'],
    },
    userReducer
  ),
  [ReducerKeys.PROMO_CODES]: persistReducer(
    {
      key: ReducerKeys.PROMO_CODES,
      storage,
      blacklist: ['loading'],
    },
    promoCodesReducer
  ),
  [ReducerKeys.SEGMENTS]: persistReducer(
    {
      key: ReducerKeys.SEGMENTS,
      storage,
      blacklist: ['loading', 'isHardQuery'],
    },
    segmentsReducer
  ),
  [ReducerKeys.WORKFLOW]: persistReducer(
    {
      key: ReducerKeys.WORKFLOW,
      storage,
      version: 85,
      migrate: createMigrate({
        85: (state) => ({
          ...state,
          workers: {
            ...state.workers,
            ['templates']: {
              status: -1,
              flow: { nodes: [], edges: [] },
              tree_map: { children: [] },
              id: 'templates',
            },
          }
        })
      }, { debug: false }),
      blacklist: ['workflows', 'analytics', 'programAnalytics', 'nodeAnalytics', 'workflowLogs', 'smsNodeAnalytics', 'emailNodeAnalytics', 'editMetadata', 'options'],
    },
    workflowReducer
  ),
  [ReducerKeys.TEMPLATES]: persistReducer(
    {
      key: ReducerKeys.TEMPLATES,
      storage,
      version: 1,
      migrate: createMigrate(migrations),
      blacklist: ['analytics'],
    },
    templatesReducer
  ),
  [ReducerKeys.INTEGRATIONS]: persistReducer(
    {
      key: ReducerKeys.INTEGRATIONS,
      storage,
      blacklist: ['integrations', 'categories', 'sub_categories', 'loading', 'dbRequestPreview'],
    },
    integrationsReducer
  ),
  [ReducerKeys.ROLES]: persistReducer(
    {
      key: ReducerKeys.ROLES,
      storage,
      blacklist: ['roles', 'permissions'],
    },
    rolesReducer
  ),
  [ReducerKeys.WORKSPACES]: persistReducer(
    {
      key: ReducerKeys.WORKSPACES,
      storage,
      blacklist: ['workspaces', 'viewDemoModalOpened'],
    },
    workspacesReducer
  ),
  [ReducerKeys.EVENTS]: persistReducer(
    {
      key: ReducerKeys.EVENTS,
      storage,
      blacklist: ['events'],
    },
    eventsReducer,
  ),
  [ReducerKeys.HISTORY_NOTIFICATIONS]: persistReducer(
    {
      key: ReducerKeys.HISTORY_NOTIFICATIONS,
      storage,
      blacklist: [],
    },
    historyNotificationReducer
  ),
  [ReducerKeys.NOTIFICATIONS]: persistReducer(
    {
      key: ReducerKeys.NOTIFICATIONS,
      storage,
      blacklist: ['notifications'],
    },
    notificationReducer
  ),
  [ReducerKeys.DASHBOARD]: persistReducer(
    {
      key: ReducerKeys.DASHBOARD,
      storage,
      blacklist: [],
    },
    dashboardReducer
  ),
  [ReducerKeys.WORKFLOW_EDITOR]: persistReducer(
    {
      key: ReducerKeys.WORKFLOW_EDITOR,
      storage,
    },
    workflowEditorReducer,
  ),
  [ReducerKeys.ACCOUNT]: persistReducer(
    {
      key: ReducerKeys.ACCOUNT,
      storage,
    },
    accountReducer,
  ),
  [ReducerKeys.CAMPAIGNS]: persistReducer(
    {
      key: ReducerKeys.CAMPAIGNS,
      storage,
      blacklist: ['editMetadata']
    },
    campaignsReducer,
  ),
  [ReducerKeys.FUNNELS]: persistReducer(
    {
      key: ReducerKeys.FUNNELS,
      storage,
      blacklist: ['funnel']
    },
    funnelsReducer,
  ),
  [ReducerKeys.FIELDS]: persistReducer(
    {
      key: ReducerKeys.FIELDS,
      storage,
      blacklist: [],
    },
    fieldsReducer,
  ),
  [ReducerKeys.SUBSCRIPTION_GROUPS]: persistReducer(
    {
      key: ReducerKeys.SUBSCRIPTION_GROUPS,
      storage,
      blacklist: ['customGroups', 'defaultGroups'],
    },
    subscriptionGroupsReducer,
  ),
  [ReducerKeys.RFM_ANALYTICS]: persistReducer({
    key: ReducerKeys.RFM_ANALYTICS,
    storage,
    blacklist: [],
  },
  rfmAnalyticsReducer
  ),
  [ReducerKeys.SQL_QUERY]: persistReducer({
    key: ReducerKeys.SQL_QUERY,
    storage,
    blacklist: [],
  },
  sqlQueryReducer,
  ),
  [ReducerKeys.OPTIONS]: persistReducer({
    key: ReducerKeys.OPTIONS,
    storage,
    blacklist: ['lastUpdate', 'options', 'workflow'],
  },
  optionsReducer,
  ),
  [ReducerKeys.WORKFLOW_OPTIONS]: persistReducer(
    {
      key: ReducerKeys.WORKFLOW_OPTIONS,
      storage: workflowOptionsStorage,
    },
    workflowOptionsReducer,
  ),
});

export default rootReducer;
