const OptionsActionTypes = {  
  GET_OPTIONS: 'options/GET_OPTIONS',
  GET_OPTIONS_SUCCESS: 'options/GET_OPTIONS_SUCCESS',
  GET_OPTIONS_ERROR: 'options/GET_OPTIONS_ERROR',

  GET_WORKFLOW_OPTIONS: 'options/GET_WORKFLOW_OPTIONS',
  GET_WORKFLOW_OPTIONS_SUCCESS: 'options/GET_WORKFLOW_OPTIONS_SUCCESS',
  GET_WORKFLOW_OPTIONS_ERROR: 'options/GET_WORKFLOW_OPTIONS_ERROR',

  SET_LAST_UPDATE: 'options/SET_LAST_UPDATE',
};
  
export default OptionsActionTypes;
  