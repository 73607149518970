import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 8px;
    transition: all 0.3s ease-in-out;
    align-items: center;
`;

export const TimerItem = styled.div`
    border-radius: 4px;
    border: 1px solid var(--Colors-Neutral-700, #415062);
    background: #14171B;
    display: flex;
    padding: 2px 4px;
    flex-direction: column;
    align-items: center;
    height: 42px;
    
    & > .time {
        color: var(--text-tertiary-body, #FFF);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;

        font-family: Manrope-Medium;
        font-size: 13px;
        line-height: 18px;
    }

    & > .timezone {
        color: var(--text-secondary-body, #7C94B1);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;

        font-family: Manrope-Medium;
        font-size: 11px;
        line-height: 16px;
        display: flex;
        align-items: center;
    }

`;
