import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { setFolder } from '@store/actions/creators';
import { TEMPLATES_WITHOUT_SEARCH } from '@store/reducers/templates';
import { foldersListSelector } from '@store/selectors';

import { Menu, Tooltip } from 'antd';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { useTranslation } from '@hooks';

import { UserPermissions } from '@constants';

import { moment , by, notify, sequence } from '@utils';

import { MoreButton, WithPermissions } from '@components';
import { TemplateItemText } from '@components/ui/TemplateItemText';
import { TemplateItemWorkers } from '@components/ui/TemplateItemWorkers';

import { DraggableTemplateItem } from './components';
import { Container, Content, Info, Date, MenuItem, Actions } from './styled';

import useTemplatePermissions from '../../../pages/HomeScene/pages/TemplatesScene/hooks/useTemplatePermissions';

const handleClick = fn => sequence(e => e?.stopPropagation?.(), e => e?.domEvent?.stopPropagation?.(), fn);

const SmallTemplateItem = React.forwardRef(({
  id,
  used,
  testId,
  data,
  onViewClick,
  fromDashboard = false,
  onEditClick,
  onDuplicateClick,
  folders,
  search,
  onStatisticsClick,
  onArchive,
  onUnarchive,
  type,
  dragging,
  onRefresh,
  refreshFolders,
  onDeleteClick,
  style,
  small,
}, ref) => {
  const { t, p } = useTranslation('templates');
  const { folder } = useParams();
  const dispatch = useDispatch();
  const foldersList = useSelector(foldersListSelector);
  const [fs] = useQueryParam('fq', withDefault(StringParam, ''));

  const handleMoveToFolder = (folder_id) => () => {
    const name = foldersList?.[type]?.[fs || TEMPLATES_WITHOUT_SEARCH]?.data?.find(by(+folder_id))?.name;

    dispatch(setFolder({
      id,
      type,
      folder_id,
      onSuccess: () => {
        notify('success', p('template_moved'), name || p('default'));
        refreshFolders?.();
        onRefresh?.();
      }
    }))
  };

  const archiveDisabled = !!used;
  const deleteDisabled = data.workers?.[0] !== undefined;

  const stopPropagation = e => e.stopPropagation();

  const templatePermissions = useTemplatePermissions(type);

  const isShiftDown = (event) => {
    return event.shiftKey;
  }

  const isCtrlDown = (event) => {
    return navigator.platform.includes('Mac') ? event.metaKey : event.ctrlKey;
  }

  const handleContainerClick = (event) => {
    event.stopPropagation();

    if (isCtrlDown(event) || isShiftDown(event)) {
      return folders.toggleSelection(id);
    }

    return data.workers?.length ? onViewClick(id, data) : onEditClick(id, data);
  };

  const handleDraggableClick = () => {
    folders.toggleSelection(id);
  };

  const isSelected = folders?.isSelected?.(id);
  const isDraggingThis = dragging?.snapshot?.isDragging;
  const isDraggingGroup = isSelected && folders?.draggingId;

  const getDraggableStyle = (style, snapshot) => {
    if (!isDraggingThis) {
      return { };
    }

    if (!snapshot.isDropAnimating) {
      return style;
    }

    const { curve } = snapshot.dropAnimation;

    const translate = `translate(0, 0)`;

    return {
      ...style,
      transform: `${translate}`,
      opacity: 0,
      transition: `all ${curve} 420ms`,
    };
  };

  const handleDuplicate = () => {
    onDuplicateClick(id, data);
  };

  const handleStatisticsClick = () => {
    onStatisticsClick(id, data);
  };

  const handleUnarchive = () => {
    onUnarchive([id]);
  };

  const handleArchive = () => {
    onArchive([id]);
  };

  const handleDelete = () => {
    onDeleteClick(id, data);
  };

  return (
    <Container
      $small={small}
      ref={ref}
      {...(dragging?.provided?.dragHandleProps || {})}
      onClick={handleContainerClick}
      onMouseDown={e => e.stopPropagation()}
    >
      {!small && (
        <DraggableTemplateItem
          selected={isSelected}
          ref={dragging.provided.innerRef}
          selectionCount={folders.selection.selectedItems?.length}
          templateName={data.name}
          active={isDraggingThis}
          isDragging={isDraggingGroup}
          onClick={handleDraggableClick}
          {...dragging.provided.draggableProps}
          style={getDraggableStyle(dragging.provided.draggableProps.style, dragging.snapshot)}
        />
      )}
      <Content
        data-testid={testId}
        style={style}
        $dragging={isSelected && folders?.draggingId}
      >
        <Info $small={small}>
          {data.name.length > 30 ? (
            <Tooltip title={data.name}>
              <TemplateItemText search={search} data-testid={`${data.name}`}>
                {data.name?.substring?.(0, 27)}...
              </TemplateItemText>
            </Tooltip>
          ) : (
            <TemplateItemText search={search} data-testid={`${data.name}`}>
              {data.name}
            </TemplateItemText>
          )}

          <Date>Updated: {moment(data.updated_at).fromNow()}</Date>
        </Info>
        <Actions onClick={stopPropagation}>
          <TemplateItemWorkers workers={data.workers} />
          <MoreButton
            iconSize={26}
            color="#7C94B1"
            testId={`${testId}-more`}
            getPopupContainer={t => t.parentElement}
            menu={
              <Menu style={{ borderRadius: '10px', overflow: 'hidden' }}>
                {+data.status !== 3 && (
                  <>
                    <Menu.Item key="2">
                      <WithPermissions name={templatePermissions.CREATE}>
                        <MenuItem data-testid={`${testId}-duplicate`}
                          onClick={sequence(stopPropagation, handleDuplicate)}>{t('actions.duplicate')}</MenuItem>
                      </WithPermissions>
                    </Menu.Item>
                    {!fromDashboard && (
                      <WithPermissions name={UserPermissions.UPDATE_FOLDER}>
                        <div onClick={e => e.stopPropagation()}>
                          <Menu.SubMenu
                            style={{ whiteSpace: 'nowrap' }}
                            disabled={folder === 'default' && foldersList?.[type]?.[fs || TEMPLATES_WITHOUT_SEARCH]?.data?.filter?.(({ id }) => String(id) !== String(folder)).length === 0}
                            title={<span data-testid={`${testId}-move-to-folder`}>{t('actions.move_to_folder')}</span>}
                          >
                            {folder !== 'default' && (
                              <Menu.Item onClick={handleClick(handleMoveToFolder(null))}>
                                <MenuItem data-testid={`${testId}-move-to-folder-default`}>{p('default_folder')}</MenuItem>
                              </Menu.Item>
                            )}
                            {foldersList?.[type]?.[fs || TEMPLATES_WITHOUT_SEARCH]?.data?.filter?.(({ id }) => String(id) !== String(folder))?.map?.(({ name, id }, idx) => (
                              <Menu.Item key={`folder-${idx}`} onClick={handleClick(handleMoveToFolder(id))}>
                                <MenuItem data-testid={`${testId}-move-to-folder-${name}`}>{name}</MenuItem>
                              </Menu.Item>
                            ))}
                          </Menu.SubMenu>
                        </div>
                      </WithPermissions>
                    )}
                    {onStatisticsClick && (
                      <Menu.Item key="4">
                        <MenuItem data-testid={`${testId}-stats`} onClick={handleStatisticsClick}>{t('actions.statistics')}</MenuItem>
                      </Menu.Item>
                    )}
                  </>
                )}
                {(onUnarchive && data.status === 3) && (
                  <Menu.Item key="3">
                    <WithPermissions name={templatePermissions.UNARCHIVE}>
                      <MenuItem data-testid={`${testId}-delete`} onClick={handleUnarchive}>
                        {t('actions.unarchive')}
                      </MenuItem>
                    </WithPermissions>
                  </Menu.Item>
                )}
                {(onDeleteClick) && (
                  <Menu.Item disabled={used} key="5">
                    <WithPermissions name={+data.status === 3 ? templatePermissions.DELETE : templatePermissions.ARCHIVE}>
                      {(+data.status === 3 ? deleteDisabled : archiveDisabled) ? (
                        <Tooltip placement="right" title={+data.status === 3 ? 'Template used' : 'Template has active workers'}>
                          <div>
                            <MenuItem
                              data-testid={`${testId}-${+data.status === 3 ? 'delete' : 'archive'}`}
                              disabled={+data.status === 3 ? deleteDisabled : archiveDisabled}
                              onClick={+data.status === 3 ? handleDelete : handleArchive}
                              danger
                            >
                              {+data.status === 3 ? t('actions.delete') : t('actions.archive')}
                            </MenuItem>
                          </div>
                        </Tooltip>
                      ) : (
                        <MenuItem
                          data-testid={`${testId}-${+data.status === 3 ? 'delete' : 'archive'}`}
                          disabled={+data.status === 3 ? deleteDisabled : archiveDisabled}
                          onClick={+data.status === 3 ? handleDelete : handleArchive}
                          danger
                        >
                          {+data.status === 3 ? t('actions.delete') : t('actions.archive')}
                        </MenuItem>
                      )}

                    </WithPermissions>
                  </Menu.Item>
                )}
              </Menu>
            }
          />
        </Actions>
      </Content>
    </Container>
  )
});

export default SmallTemplateItem;
