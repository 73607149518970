import styled from 'styled-components';

export const DeleteFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Manrope-Medium;
  font-size: 13px;
  line-height: 120%;
  color: #C8402F;
  width: 100%;
  cursor: pointer;
  user-select: none;
  margin-bottom: 14px;
`;